import React from "react";
import { Route, Redirect } from "react-router-dom";
import {HasPermission} from "../../assets/lib/utils";
import routes from "routes.js";

var _ = require('underscore');

export default ({ component: C, props: cProps, ...rest }) =>
  <Route
    {...rest}
    render={props => {

      // on regarde la route souhaitee et on compare avec les routes
      var tmp = props.location.pathname.split('/');
      tmp.shift();
      var max = -1, intersec = 0, routeIndex = 0;
      for(var r=0;r<routes.length;r++) {
        intersec = _.intersection( (routes[r].layout.replace('/','')+routes[r].path).split('/'), tmp );
        if( intersec.length > max ) {
          routeIndex = r;
          max = intersec.length;
        }
      }
      console.log(  );
      // on a la routes la plus proche, on regarde maintenant suivant le role si on a la permission
      if( !HasPermission('currentRole',routes[routeIndex].role) ) {
        return <Redirect to={{pathname: '/home', state: {from: props.location}}}/>
      }

      var authenticated = sessionStorage.getItem('isAuth')==='1';

      if( authenticated ) {
        return <C {...props} {...cProps} />
      }
      else {
        return <Redirect to={{pathname: '/home', state: {from: props.location}}}/>
      }
    }}/>;
