import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Row,
  Col,
  Modal,
} from "reactstrap";
import {Button,Page} from '../UI/UI.jsx';
import i18n from "../../assets/lib/i18n";
import {StrDef,GetPrivateKey} from "../../assets/lib/utils";


class PinCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal:false,
      code:'',
    };

  }

  componentWillUnmount() {

  }

  Open(data) {
    this.encrypted = data;
    this.ToggleModal();
    setTimeout(()=>{
      if( document.querySelector('#inputCode') != null )
        document.querySelector('#inputCode').focus();
    },1000);
  }

  ToggleModal() {
    this.setState({modal:!this.state.modal});
  }

  Validate() {
    var decrypted = GetPrivateKey(this.encrypted,this.state.code);
    if( StrDef(decrypted) ) {
      localStorage.setItem('version.build',this.state.code);
      this.ToggleModal();
      sessionStorage.setItem('version.hash',decrypted);
      window.API.entityKey = decrypted;
    }
    else {
      this.setState({code:''});
      window.notify('Mauvais code','danger');
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.ToggleModal} backdrop='static'>
          <Row>
            <Col md="12" className="text-center" style={{padding:40}}>
              <div className="textOnWhite" style={{marginTop:50,marginBottom:50}}>
                Saisir le code secret à 4 chiffres de l'administrateur
              </div>

              <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',border:'solid 0px #1f367b',borderBottomWidth:'1px',marginBottom:40}}>
                <div className="textOnWhite">Code :</div>
                <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',marginBottom:10}}>
                  <Input
                    id="inputCode"
                    type="password"
                    autoFocus
                    autoComplete="off"
                    value={this.state.code}
                    style={{padding:0,textAlign:'right',backgroundColor:'transparent',fontSize:'2em',border:'none',color:'#000'}}
                    onChange={(c)=>this.setState({code:c.target.value})}/>
                </div>
              </div>

              <Button label={i18n.t('all.save')} onClick={this.Validate.bind(this,false)}/>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default PinCode;
