import React from "react";

import {StrDef,Clone} from '../../assets/lib/utils';
import {
  Input,
} from "reactstrap";
var moment = require('moment');

export default class FormFooterClaro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      border:'solid 1px #1f367b',
      fill:'#1f367b',
      signing:false,
    };

    if( !StrDef(this.state.data.km) ) this.state.data.km = '';
    if( !StrDef(this.state.data.brand) ) this.state.data.brand = '';
    if( !StrDef(this.state.data.model) ) this.state.data.model = '';
    if( !StrDef(this.state.data.release) ) this.state.data.release = '';
    if( !StrDef(this.state.data.stock) ) this.state.data.stock = '';
    if( StrDef(this.props.data.vin) ) this.state.initialVin = this.props.data.vin.length===17?this.props.data.vin:'';

    this.Check = this.Check.bind(this);
    this.Sign = this.Sign.bind(this);
    this.isDateValid = this.isDateValid.bind(this);

    var mode = sessionStorage.getItem('nightMode');
    if( mode === null ) mode = '0';
    this.nightMode = mode;
    if( mode === '0' ) {
      this.state.inputColor = '#1f367b';
    }
    else {
      this.state.inputColor = '#FFF';
    }

  }

  static getDerivedStateFromProps(nextProps, prevState){
    if( nextProps.data.real!==prevState.data.real
        || nextProps.data.guaranty!==prevState.data.guaranty
        || nextProps.data.km!==prevState.data.km
        || nextProps.data.brand!==prevState.data.brand
        || nextProps.data.model!==prevState.data.model
        || nextProps.data.registration!==prevState.data.registration
        || nextProps.data.release!==prevState.data.release
        || nextProps.data.stock!==prevState.data.stock
        || nextProps.data.finished!==prevState.data.finished ) {
      var data = Clone(nextProps.data);
      if( !StrDef(data.km) ) data.km = '';
      return { data: data };
    }
    else return null;
  }

  Check(mode) {
    var data = Clone(this.state.data);

    if( mode === 'r' ) {
      data.real = !data.real;
      if( data.real ) data.guaranty = false;
      else data.guaranty = true;
    }
    if( mode === 'g' ) {
      data.guaranty = !data.guaranty;
      if( data.guaranty ) data.real = false;
      else data.real = true;
    }

    this.props.onChange(data);
  }

  Change(what,t) {
    var d = Clone(this.state.data);
    var t = t.target.value;
    if( what === 'km' ) {
      t = t.replace(/ /g,'');
      if( isNaN(t) ) {
        t = '';
      }
      else {
        var space = t.length-3;
        t = t.substring(0,space)+' '+t.substring(space);
      }
    }

    if( t === ' ' ) t = '';
    d[what] = t;
    this.props.onChange(d);
  }

  isDateValid(){
    let dateString = this.state.data.release
    if (StrDef(dateString)) {
      var parts = dateString.split("/");
      var day = parts[0];
      var month = parts[1];
      var year = parts[2];

      var m = moment(year+'-'+month+'-'+day);
      if( !m.isValid() || m >= moment() ) {
        window.notify('Veuillez renseigner une date valide','danger');
        return false;
      }
    }
    return true;
  }

  Sign() {
    if( !this.isDateValid() ) {
      return;
    }
    if( !StrDef(this.state.data.km) ) {
      window.notify('Veuillez renseigner le kilométrage','danger');
      return;
    }
    if( !StrDef(this.state.data.registration) ) {
      window.notify('Veuillez renseigner l\'immatriculation','danger');
      return;
    }
    this.setState({signing:true});
    this.props.sign((retour)=>{
      if (StrDef(retour.error)) {
        this.setState({signing:false});
      }
    });
  }

  render() {
    var bg = 'transparent', bgG = 'transparent';
    if( this.state.data.real ) bg = '#ffdc00';
    if( this.state.data.guaranty ) bgG = '#ffdc00';

    var error = {}, errorCount = 0;
    var finished = /*this.state.data.finished*/true;
    if( !finished ) {
      error['answer'] = true;
      errorCount++;
    }

    if( !StrDef(this.state.data.km) ) {
      error['km'] = true;
      errorCount++;
    }

    if( errorCount != 0 ) finished = false;

    return (
      <div style={{display:'flex',flex:1,flexDirection:'row',margin:10}}>
        <div className="opelFooterCol">
          <b>Inspection technique étendue</b>
          <div style={{marginTop:5,display:'flex',flexDirection:'column',flex:1,border:'solid 0px #ffdc00',borderBottomWidth:1}}>
            <font style={{fontSize:'0.7em'}}>Marque :</font>
            <Input
              autoComplete="off"
              value={this.state.data.brand}
              style={{padding:0,backgroundColor:'transparent',fontSize:'1em',border:'none',color:this.state.inputColor}}
              onChange={this.Change.bind(this,'brand')}/>
          </div>
          <div style={{marginTop:3,display:'flex',flexDirection:'column',flex:1,border:'solid 0px #ffdc00',borderBottomWidth:1}}>
            <font style={{fontSize:'0.7em'}}>Modèle :</font>
            <Input
              autoComplete="off"
              value={this.state.data.model}
              style={{padding:0,backgroundColor:'transparent',fontSize:'1em',border:'none',color:this.state.inputColor}}
              onChange={this.Change.bind(this,'model')}/>
          </div>
          <div style={{marginTop:3,display:'flex',flexDirection:'column',flex:1,border:'solid 0px #ffdc00',borderBottomWidth:1}}>
            <font style={{fontSize:'0.7em'}}>Kilométrage au compteur :</font>
            <Input
              autoComplete="off"
              value={this.state.data.km}
              style={{padding:0,backgroundColor:'transparent',fontSize:'1em',border:'none',color:this.state.inputColor}}
              onChange={this.Change.bind(this,'km')}/>
          </div>
        </div>

        <div className="opelFooterCol">
          <div style={{display:'flex',flexDirection:'column',flex:1,border:'solid 0px #ffdc00',borderBottomWidth:1}}>
            <font style={{fontSize:'0.7em'}}>Numéro de châssis :</font>
            { StrDef(this.state.initialVin) &&
              this.props.data.vin
            }
            { !StrDef(this.state.initialVin) &&
              <Input
                autoComplete="off"
                value={this.state.data.vin}
                style={{padding:0,backgroundColor:'transparent',fontSize:'1em',border:'none',color:'#1f367b',textTransform:'uppercase'}}
                onChange={this.Change.bind(this,'vin')}/>
            }
          </div>
          <div style={{marginTop:3,display:'flex',flexDirection:'column',flex:1,border:'solid 0px #ffdc00',borderBottomWidth:1}}>
            <font style={{fontSize:'0.7em'}}>Numéro de stock :</font>
            <Input
              autoComplete="off"
              value={this.state.data.stock}
              style={{padding:0,backgroundColor:'transparent',fontSize:'1em',border:'none',color:this.state.inputColor}}
              onChange={this.Change.bind(this,'stock')}/>
          </div>
          <div style={{marginTop:3,display:'flex',flexDirection:'column',flex:1,border:'solid 0px #ffdc00',borderBottomWidth:1}}>
            <font style={{fontSize:'0.7em'}}>Numéro d'immatriculation :</font>
            <Input
              autoComplete="off"
              value={this.state.data.registration}
              style={{padding:0,backgroundColor:'transparent',fontSize:'1em',border:'none',color:this.state.inputColor}}
              onChange={this.Change.bind(this,'registration')}/>
          </div>
          <div style={{marginTop:3,display:'flex',flexDirection:'column',flex:1,border:'solid 0px #ffdc00',borderBottomWidth:1}}>
            <font style={{fontSize:'0.7em'}}>Date de première immatriculation :</font>
            <Input
              autoComplete="off"
              placeholder='jj/mm/AAAA'
              value={this.state.data.release}
              style={{padding:0,backgroundColor:'transparent',fontSize:'1em',border:'none',color:this.state.inputColor}}
              onChange={this.Change.bind(this,'release')}
              onBlur={this.isDateValid}/>
          </div>
        </div>

        <div className="opelFooterCol">
          <div style={{display:'flex',flexDirection:'column',flex:1,border:'solid 0px #ffdc00',borderBottomWidth:1}}>
            <font style={{fontSize:'0.7em'}}>Nom :</font>
            <font style={{fontSize:'0.9em'}}>{window.API.currentUser.name}</font>
          </div>
          <div style={{marginTop:3,display:'flex',flexDirection:'column',flex:3}}>
            <font style={{fontSize:'0.7em'}}>Signature du technicien :</font>
            { !this.state.signing &&
              <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',opacity:/*finished?*/1/*:0.3*/}}>
                <button className="btnRound" style={{width:200,height:60}} onClick={/*finished?*/this.Sign/*:null*/}>
                  Valider et signer
                </button>
              </div>
            }
            { this.state.signing &&
              <img src={window.API.currentUser.signature.src} style={{position:'relative',marginTop:'-60px',marginLeft:'100px',width:'230px',objectFit:'contain'/*,transform:'rotate(-6deg)'*/}}/>
            }
          </div>
        </div>

        <div className="opelFooterCol">
          { false &&
            <div style={{display:'flex',flexDirection:'column',flex:1,justifyContent:'center',alignItems:'center'}}>
              <font style={{fontSize:'0.7em',textAlign:'center'}}>Le véhicule est transmis au service<br/>d'entretien pour la préparation finale</font>
            </div>
          }
          <div style={{marginTop:3,display:'flex',flexDirection:'column',flex:3,position:'relative'}}>
            <div style={{display:'flex',flex:1,border:'solid 1px #ffdc00',justifyContent:'center',alignItems:'flex-end'}}>
              { this.state.signing &&
                <img src={this.props.stamp} style={{position:'absolute',marginTop:-16,width:'210px',objectFit:'contain'}}/>
              }
              <font style={{fontSize:'0.7em',marginBottom:5}}>Cachet de l'établissement vendeur !!!!!!!!</font>
            </div>
          </div>

        </div>

      </div>
    );
  }
}
