import React from "react";
import {StrDef} from "../../assets/lib/utils";
export default class CheckLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      border:'solid 1px #1f367b',
      fill:'#1f367b',
    };

    this.Check = this.Check.bind(this);

    var mode = sessionStorage.getItem('nightMode');
    if( mode === null ) mode = '0';
    this.nightMode = mode==='0'?false:true;

    if( this.nightMode ) {
      this.state.border = 'solid 1px #ffdc00';
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if( nextProps.data.checked!==prevState.data.checked
        || nextProps.data.selected!==prevState.data.selected ){

      if( nextProps.data.checked!==prevState.data.checked
          && [2,-3,-4,-5].indexOf(nextProps.data.checked) != -1
          && nextProps.data.avr === true ) {
        nextProps.onAvr(nextProps.data.id);
      }

      return { data: nextProps.data};
    }
    else return null;
  }

  Check(mode) {
    if( mode === 'c' ) {
      if( this.state.data.checked ) {
        this.props.onChange(this.props.data.id,0);
      }
      else {
        this.props.onChange(this.props.data.id,1);
      }
    }
    else {
      if( this.state.data.checked === 2 ) {
        this.props.onChange(this.props.data.id,1);
      }
      else {
        if( this.state.data.checked == 1 || this.state.data.checked == 0 || !StrDef(this.state.data.checked) ) {
          this.props.onChange(this.props.data.id,2);
        }
        else {
          console.log( 'onChange checkline !!!! ', this.props.data.id, -this.props.data.checked );
          this.props.onChange(this.props.data.id,-this.props.data.checked);
        }
      }
    }
  }

  render() {
    var bg = 'transparent';
    if( [1,2,3,4,5].indexOf(this.state.data.checked) != -1 ) bg = '#ffdc00';

    var prefix = '';
    if( this.nightMode ) prefix = 'dark_';

    var checked = '';
    if( this.props.data.checked == 3 ) checked = 'AV ';
    if( this.props.data.checked == 4 ) checked = 'AR ';
    if( this.props.data.checked == 5 ) checked = 'AV-AR ';

    return (
      <div key={"line"+this.state.data.id} className={prefix+'checkLine'+(this.state.data.selected?' '+prefix+'selected':'')}>
        <div className="checkLineLabel" onClick={this.Check.bind(this,'c')}>
          <label htmlFor={"query"+this.state.data.id} style={{fontFamily:'Condense',margin:0,width:20,cursor:'pointer'}}>{this.state.data.num}</label>
          <label htmlFor={"query"+this.state.data.id} style={{margin:0,cursor:'pointer',width:'100%'}}>{this.state.data.label}</label>
        </div>
        <div style={{display:'flex',flexDirection:'row',margin:0,padding:0}}>
          <div style={{display:'flex',alignItems:'center',marginRight:5,fontSize:'0.8em'}}>{checked}</div>
          <div style={{width:18,height:18,margin:0,padding:0,border:this.state.border,cursor:'pointer',backgroundColor:bg}} onClick={this.Check.bind(this,'c')}>
            { this.state.data.checked > 0 &&
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="-40 110 512 512" style={{width:'15px',height:'15px'}}>
                <g fill={this.state.fill}>
                  <path d=" M 453.886 138.696 L 421.194 105.074 C 419.893 103.588 417.85 102.845 415.993 102.845 C 413.949 102.845 412.092 103.588 410.791 105.074 L 184.171 333.367 L 101.696 250.892 C 100.21 249.406 98.352 248.663 96.495 248.663 C 94.637 248.663 92.779 249.406 91.293 250.892 L 58.229 283.956 C 55.257 286.928 55.257 291.572 58.229 294.544 L 162.252 398.567 C 168.939 405.254 177.112 409.155 183.985 409.155 C 193.83 409.155 202.375 401.91 205.532 398.938 L 205.718 398.938 L 454.072 149.284 C 456.673 146.126 456.673 141.482 453.886 138.696 L 453.886 138.696 Z "/>
                </g>
              </svg>
            }
          </div>
          <div style={{marginLeft:5,width:18,height:18,cursor:'pointer',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={this.Check.bind(this,'r')}>
            { [2,3,4,5].indexOf(this.state.data.checked) == -1 &&
              <>...</>
            }
            { [2,3,4,5].indexOf(this.state.data.checked) != -1 &&
              <>R</>
            }
          </div>
        </div>
      </div>
    );
  }
}
