import {sharedAPI} from './api';
import XLSX from 'xlsx';
import Fuse from 'fuse.js';
import moment from 'moment';
const cc        = require('five-bells-condition');
var _           = require('underscore');
var fbc         = require('five-bells-condition')
var jwtDecode   = require('jwt-decode');
var strictjson  = require('json-stable-stringify')
var bs58        = require('bs58')
var CryptoJS    = require("crypto-js");
var bigInt      = require("big-integer");
var mark = [5,7,15,38,44,60,82,105,118,119,122];
var markAbsent = [56,70,121];
var blackWhiteTableau = [50,51,64,76,75,62];
var positionsMatriceLigne   = [3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0,3,2,1,0];
var positionsMatriceColonne = [0,0,0,0,1,1,1,1,2,2,2,2,3,3,3,3,0,0,0,0,1,1,1,1,2,2,2,2,3,3,3,3,0,0,0,0,1,1,1,1,2,2,2,2,3,3,3,3,0,0,0,0,1,1,1,1,2,2,2,2,3,3,3,3];
var bit = [39,40,52,65,77,88,87,86,74,61,49,27,28,29,30,41,53,66,78,89,99,98,97,96,85,73,48,37,17,18,19,20,21,31,42,54,67,79,90,100,109,108,107,106,95,84,72,59,47,36,26,8,9,10,11,12,13,22,32,43,55,68,80];
var cheeses = [1,2,2,3,3,4,4,5,5,6,6,1,1,1,2,2,2,3,3,3,4,4,4,5,5,5,6,6,1,1,1,1,2,2,2,2,3,3,3,3,4,4,4,4,5,5,5,6,6,6,6,1,1,1,1,1,2,2,2,2,2,3,3];
var bitParite = [91,101,110,117,116,115,114,113,104,94,83,71,58,46,35,25,16,2,3,4,23,33,92,102,111,124,123,112,103,93,34,24];
var cheeseBitParite = [3,3,3,4,4,4,4,5,5,5,5,5,6,6,6,6,6,1,1,1,2,2,3,3,3,4,4,5,5,5,6,6];
var cheese1Tableau = [39,27,28,29,17,18,19,20,8,9,10,11,12];
var cheese2Tableau = [40,52,30,41,53,21,31,42,54,13,22,32,43,55];
var cheese3Tableau = [65,77,66,78,89,67,79,90,100,68,80];
var cheese4Tableau = [87,88,97,98,99,106,107,108,109];
var cheese5Tableau = [74,86,73,85,96,72,84,95];
var cheese6Tableau = [49,61,37,48,26,36,47,59];
var cheese1PariteTableau = [2,3,4];
var cheese2PariteTableau = [23,33];
var cheese3PariteTableau = [91,101,110,92,102,111];
var cheese4PariteTableau = [114,115,116,117,123,124];
var cheese5PariteTableau = [71,83,94,104,113,93,103,112];
var cheese6PariteTableau = [58,46,35,25,16,34,24];
var nbPresentCheese1 = 2;
var nbPresentCheese2 = 1;
var nbPresentCheese3 = 1;
var nbPresentCheese4 = 2;
var nbPresentCheese5 = 2;
var nbPresentCheese6 = 3;
var checkSumValueTableau = new Array(5);
var checkSumTableau = [1,14,81,125,45];
const Promise   = require('bluebird'),
      sha3_256  = require('js-sha3').sha3_256,
      multihash = require('multihashes')

moment.locale('fr', {
    months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact : true,
    weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // Used to determine first week of the year.
    }
});

export function ObjectHash(o) {
  var key = _.keys(o);
  key.sort();
  var res = '';
  for(var i=0;i<key.length;i++) {
    res += o[key[i]];
  }
  return res;
}

export function Capitalize( str ) {
  var pieces = str.toLowerCase().split(" ");
  for ( var i = 0; i < pieces.length; i++ )
  {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
  }
  pieces = pieces.join(" ");

  pieces = pieces.split("-");
  for ( i = 0; i < pieces.length; i++ )
  {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
  }
  pieces = pieces.join("-");

  pieces = pieces.split("'");
  for ( i = 0; i < pieces.length; i++ )
  {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
  }
  return pieces.join("'");
}

export function SortBy(tab,prop) {
  var indexed = _.indexBy( tab, prop );
  var tmp = _.keys(indexed);
  tmp.sort(Intl.Collator().compare);  // sert a trier les string en prenant en compte les accents
  var res = [];
  for(var t=0;t<tmp.length;t++) {
    res.push(indexed[tmp[t]]);
  }
  return res;
}

export function Clone(o) {
  return JSON.parse(JSON.stringify(o));
}

export function GetJSON(url,callback) {
  var request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      var data = JSON.parse(request.responseText);
      callback(data);
    } else {
      callback({error:'unknown'});
    }
  };
  request.onerror = function() {
    callback({error:'network error'});
  };
  request.send();
}

export function GetMD(url,callback) {
  var request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      callback(request.responseText);
    } else {
      callback({error:'unknown'});
    }
  };
  request.onerror = function() {
    callback({error:'network error'});
  };
  request.send();
}


export function StrDef(s) {
  return ( typeof(s) != 'undefined' && s !== null && s !== '' );
}

export function Phone(s) {
  var phoneNumber = s;

  if( StrDef(phoneNumber) ) {
    var formatted = phoneNumber.replace(/(\d{1})(\d{1,3})?(\d{1,3})?(\d{1,3})?/, function(_, p1, p2, p3, p4){
      let output = ""
      if (p1) output = `(${p1})`;
      if (p2) output += ` ${p2}`;
      if (p3) output += ` ${p3}`;
      if (p4) output += ` ${p4}`;
      return output;
    });
  }
  return formatted;
}

export function exportXLSX(arrayOfArrays,titleSheet,titleFile) {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);
  XLSX.utils.book_append_sheet(wb, ws, titleSheet);
  XLSX.writeFile(wb, titleFile);
}

export function removeAccents(str) {
  let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  let accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  str = str.split('');
  str.forEach((letter, index) => {
    let i = accents.indexOf(letter);
    if (i !== -1) {
      str[index] = accentsOut[i];
    }
  })
  return str.join('');
}

export function ValidateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function generatePassword() {
 var length = 5,
     charset = "0123456789",
     retVal = "";
 for (var i = 0, n = charset.length; i < length; ++i) {
     retVal += charset.charAt(Math.floor(Math.random() * n));
 }
 return retVal;
}

export function preimageSha256Condition(password) {
 var preimage = generatePassword();
 if( typeof(password) != 'undefined' ) preimage = password;
 var result = {};
 try {
   var condition = new cc.PreimageSha256()
   condition.setPreimage(new Buffer(preimage));
   result = {
     uri: condition.getConditionUri(),
     name: 'password',
     detail: {
       type: 'preimage-sha-256'
     }
   };
 }
 catch (err) {
     console.log(err);
 }
 return result
}

// ======================================================== KeyValueStore ======
export class KVStore {
  constructor(props) {
    console.log( 'KVStore constructor !!!!!', props );

    this.group = props.group;
    this.tab = [];                // c'est le tableau qui contient les values

    this.synchro();
  }

  synchro(callback) {
    var self = this;
    var lastLocal = moment().subtract(100,'years').format('YYYY-MM-DD HH:mm:ss');
    // on load depuis le localstorage
    var data = localStorage.getItem(this.group);
    if( data !== null ) {
      this.tab = JSON.parse(data);
      for(var i=0;i<this.tab.length;i++) {
        if( this.tab[i].modify_date > lastLocal ) lastLocal = this.tab[i].modify_date;
      }
    }
    else {
      this.tab = [];
    }
    // on va voir si on est synchro
    var backend = sharedAPI();
    var lastRemote = moment().format('YYYY-MM-DD HH:mm:ss').replace(' ','%20');

    var fetchRemote = ()=>{
      backend.kv_group_get(this.group,lastRemote,(d)=>{
        if( d.length > 0 ) {
          for(var i=0;i<d.length;i++) {
            self.tab.push(d[i]);
            lastRemote = d[i].modify_date;
          }
          if( lastRemote > lastLocal && d.length==20 ) {
            fetchRemote();
          }
          else {
            localStorage.setItem(this.group,JSON.stringify(this.tab));
          }
        }
      })
    };
    fetchRemote();
  }

  setItem(obj) {
    // on met a jour le cache
    var pos = _.findIndex(this.tab,{id:obj.id});
    this.tab[pos] = JSON.parse(JSON.stringify(obj));
    // on met a jour l'api
    var backend = sharedAPI();
    backend.kv_group_put(this.group,obj,(r)=>{});
  }

  findFuzzItem(keys,search,threshold) {       // threshold : 0 == perfect match, 0.6 : default for fuzzySearch
    var th = 0.6;
    if( typeof(threshold) != 'undefined' ) th = threshold;
    var fuse = new Fuse(this.tab,{keys:keys,threshold:th});
    return fuse.search(search).slice(0,10);
  }
}

//========================================================= Currency ===========
export function price(n) {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })

  return formatter.format(n);
}

export function getLocale(length) {
  var locale = '';
  if (window.navigator.languages) {
      locale = window.navigator.languages[0];
  } else {
      locale = window.navigator.userLanguage || window.navigator.language;
  }
  var tiret = locale.indexOf('-');
  if( tiret != -1 ) {
    var tmp = locale.split('-');
    locale = tmp[0].toLowerCase()+'-'+tmp[1].toUpperCase();
  }
  else {
    locale = locale.toLowerCase()+'-'+locale.toUpperCase();
  }
  if( length === 2 ) locale = locale.substr(0,2).toUpperCase();
  return locale;
}

// ======================================================== PERMISSION =========
export function HasPermission(scope,need) {
  var tmp = sessionStorage.getItem(scope);
  try {
    if( StrDef(tmp) ) tmp = JSON.parse(tmp);
  }
  catch(e) {
    if( StrDef(tmp) ) tmp = [tmp];
  }
  var res = _.intersection(tmp,need);
  if( res.length > 0 ) return true;
  else return false;
}

// ======================================================== MOMENT =========
export function DateDisplay(date) {
  return moment(date).format('DD MMMM YYYY');
}
export function DateDisplayS(date) {
  if( StrDef(date) )
    return moment(date).format('DD/MM/YY');
  else
    return '';
}

export function file_hash(filename) {
  return new Promise(function(resolve, reject){
    let hash = sha3_256.create()
    var reader = new FileReader();
    reader.addEventListener("loadend", function() {
      console.log("reader.result",reader.result);
      hash.update(reader.result)

      let buf = Buffer.from(hash.hex(), 'hex')
      resolve(multihash.toB58String(multihash.encode(buf, 'sha3-256')))
      return;
    });
    reader.readAsArrayBuffer(filename);
    return;
  })
}

export function PreimageSha256Condition(preimage) {
  if ( !_.isString(preimage) ) {
    return null
  }

  try {
    let condition = new fbc.PreimageSha256()
    condition.setPreimage(new Buffer(preimage))
    return condition.getConditionUri()
  } catch (err) {
      return null
  }
}

export function getS3Region(accessToken) {
  let decoded = jwtDecode(accessToken);
  return decoded.region;
}

export function getUserId(accessToken) {
  let decoded = jwtDecode(accessToken);
  return decoded.user_id;
}

export function getEntityId(accessToken) {
  let decoded = jwtDecode(accessToken);
  return decoded.entity_id;
}

export function getEntityName(accessToken) {
  let decoded = jwtDecode(accessToken);
  return decoded.entity_name;
}

export function json_hash(json_data) {
  //Avant de générer le hash d’un document JSON, il faut ordonner ces éléments de la même manière que coté serveur
  //console.log(json_data)
  json_data = strictjson(json_data, (a, b) => (a.key > b.key ? 1 : -1))
  //console.log(json_data)
  return sha3_256.create().update(json_data).hex()
}

export function Ed25519Sha256Fulfillment(message, privateKey) {
  let message_buffer = Buffer.from(message, 'utf8')
  let privateKey_buffer = Buffer.from(bs58.decode(privateKey))
  let fulfillment = new fbc.Ed25519Sha256()
  fulfillment.sign(message_buffer, privateKey_buffer)
  return fulfillment.serializeUri()
}

var estPaire = function (valBit1,valBit2,valBit3,valBit4) {
  var nbPaire = 0;
	if(valBit1)
		nbPaire++;
  if(valBit2)
		nbPaire++;
	if(valBit3)
		nbPaire++;
	if(valBit4)
		nbPaire++;

	return(nbPaire == 0 || nbPaire == 2 || nbPaire == 4);
}

export function DrawOCode(chaine,ctx,x,y) {
  ctx.beginPath();
  //ctx.moveTo(2800,150);
  var colorSafecode = "#000000";// noir
  const diameterChoosen = 200;
  const diameter = diameterChoosen;
  const diamSpot = diameter/19;
  // hexagone
  const rows = 13;
  let column = 7;
  // ratio stepsafecode
  const ratio = 0.5;
  const step = diamSpot + (ratio * diamSpot);
  const radius = diamSpot /2;

  var safecodeX = x;
  var safecodeY = y;
  var centerX = 0;
  var centerY = 0;

  //context.beginPath();
  column = 7; // reinit column
  centerX = safecodeX;
  centerY = safecodeY;
  var index = 0;
  for (var j = 0; j < rows; j++) {
    for (var i = 0; i < column; i++) {
      centerX += step;
      if(index != 0 && index != 6 && index != 57 && index != 69 && index != 120 && index != 126){
        var isSpot = chaine.substring(index, index + 1) == 'X' ? true : false;
        if(isSpot) {
          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
          ctx.moveTo(centerX,centerY);
        }
      }
      index++;
    }

    if(j < 6){
      column++;
      centerX = safecodeX - (step / 2);
    } else {
      column--;
      centerX = safecodeX + (step / 2);
    }

    // on stocke la valeur du centerX à l'extrême gauche de l'hexagone afin de savoir d'où on repart pour la ligne suivante
    safecodeX = centerX;
    centerY += (step * (Math.sqrt(3) / 2));
  }
  ctx.fillStyle = colorSafecode;
  ctx.fill();
  ctx.closePath();
  return ctx;
}

export function StringifyOCode(moonCode) {
  var val = bigInt(moonCode).toString(2);
  //console.log("val ",val);
  var tableau = new Array(127);

  for (var i = 0; i < mark.length; i++)
        tableau[mark[i]] = true;

  for (var i = 0; i < markAbsent.length; i++)
    tableau[markAbsent[i]] = false;

  // point central à true par défaut
  tableau[63] = true;

  // par défaut les black&white sont à blanc
  for (var i = 0; i < blackWhiteTableau.length; i++)
    tableau[blackWhiteTableau[i]] = false;

  // répartition des bits dans la matrice
  var nbBitTrueCheese1 = 0;
  var nbBitTrueCheese2 = 0;
  var nbBitTrueCheese3 = 0;
  var nbBitTrueCheese4 = 0;
  var nbBitTrueCheese5 = 0;
  var nbBitTrueCheese6 = 0;

  // on complète par des 0 les bits manquants
  var binaryVal = new Array(63);
  for (var i = 0; i < (63 - val.length); i++)
    binaryVal[i] = 0;

  // puis on affecte
  var indice = 0;
  for (var i = (63 - val.length); i < 63; i++)
    binaryVal[i] = val[indice++];

  // inversion des bits pour les parcourir correctement différent de java où un mask est appliqué
  // ici le mask ne fonctionne que sur des entiers 32 bits
  // en java on part du bit de poids faible alors qu'ici on part du bit de poids fort
  // pour conserver l'ordre java, on inverse la valeur binaire
  var binaryValInverse = new Array(63);
  indice = 0;
  for (var i = 62; i >= 0; i--)
    binaryValInverse[indice++] = binaryVal[i];

  // matrices liées au checksum
  var matriceA = new Array(new Array(4),new Array(4));
  var matriceB = new Array(new Array(4),new Array(4));
  var matriceC = new Array(new Array(4),new Array(4));
  var matriceD = new Array(new Array(4),new Array(4));
  matriceA[positionsMatriceColonne[i]] = [];
  matriceB[positionsMatriceColonne[i]] = [];
  matriceC[positionsMatriceColonne[i]] = [];
  matriceD[positionsMatriceColonne[i]] = [];
  for (var i = 0; i < 16; i++) {
    matriceA[positionsMatriceColonne[i]] = [];
    matriceA[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = [];
  }
  for (var i = 16; i < 32; i++) {
    matriceB[positionsMatriceColonne[i]] = [];
    matriceB[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = [];
  }
  for (var i = 32; i < 48; i++) {
    matriceC[positionsMatriceColonne[i]] = [];
    matriceC[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = [];
  }
  for (var i = 48; i < 63; i++) {
    matriceD[positionsMatriceColonne[i]] = [];
    matriceD[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = [];
  }
  for (var i = 0; i < 63; i++) {
    var valBit = binaryValInverse[i] != 0 ? true : false;
    if (i < 16)
      matriceA[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = valBit;
    else if (i < 32)
      matriceB[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = valBit;
    else if (i < 48)
      matriceC[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = valBit;
    else
      matriceD[positionsMatriceColonne[i]][positionsMatriceLigne[i]] = valBit;

    tableau[bit[i]] = valBit;
    // gestion des checksum
    if(valBit) {
      var cheese = cheeses[i];
      switch (cheese) {
      case 1:
        nbBitTrueCheese1++;
        break;
      case 2:
        nbBitTrueCheese2++;
        break;
      case 3:
        nbBitTrueCheese3++;
        break;
      case 4:
        nbBitTrueCheese4++;
        break;
      case 5:
        nbBitTrueCheese5++;
        break;
      case 6:
        nbBitTrueCheese6++;
        break;
      }
    }
  }

  /*console.log("nbBitTrueCheese1 ",nbBitTrueCheese1);
  console.log("nbBitTrueCheese2 ",nbBitTrueCheese2);
  console.log("nbBitTrueCheese3 ",nbBitTrueCheese3);
  console.log("nbBitTrueCheese4 ",nbBitTrueCheese4);
  console.log("nbBitTrueCheese5 ",nbBitTrueCheese5);
  console.log("nbBitTrueCheese6 ",nbBitTrueCheese6);*/
  var indice = 0;
  // parcours des lignes et colonnes matriceA
  for (var j = 0; j < matriceA.length; j++) {
    var valBit1 = matriceA[j][0];
    var valBit2 = matriceA[j][1];
    var valBit3 = matriceA[j][2];
    var valBit4 = matriceA[j][3];
    var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
    tableau[bitParite[indice]] = paire;
    if(paire){
      var cheese = cheeseBitParite[indice];
      switch (cheese) {
      case 1:
        nbBitTrueCheese1++;
        break;
      case 2:
        nbBitTrueCheese2++;
        break;
      case 3:
        nbBitTrueCheese3++;
        break;
      case 4:
        nbBitTrueCheese4++;
        break;
      case 5:
        nbBitTrueCheese5++;
        break;
      case 6:
        nbBitTrueCheese6++;
        break;
      }
    }
    indice++;
  }

  for (var j = 0; j < matriceA.length; j++) {
    var valBit1 = matriceA[0][j];
    var valBit2 = matriceA[1][j];
    var valBit3 = matriceA[2][j];
    var valBit4 = matriceA[3][j];
    var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
    tableau[bitParite[indice]] = paire;
    if(paire){
      var cheese = cheeseBitParite[indice];
      switch (cheese) {
      case 1:
        nbBitTrueCheese1++;
        break;
      case 2:
        nbBitTrueCheese2++;
        break;
      case 3:
        nbBitTrueCheese3++;
        break;
      case 4:
        nbBitTrueCheese4++;
        break;
      case 5:
        nbBitTrueCheese5++;
        break;
      case 6:
        nbBitTrueCheese6++;
        break;
      }
    }
    indice++;
  }

  // matriceB
  for (var j = 0; j < matriceB.length; j++) {
    var valBit1 = matriceB[j][0];
    var valBit2 = matriceB[j][1];
    var valBit3 = matriceB[j][2];
    var valBit4 = matriceB[j][3];
    var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
    tableau[bitParite[indice]] = paire;
    if(paire) {
      var cheese = cheeseBitParite[indice];
      switch (cheese) {
      case 1:
        nbBitTrueCheese1++;
        break;
      case 2:
        nbBitTrueCheese2++;
        break;
      case 3:
        nbBitTrueCheese3++;
        break;
      case 4:
        nbBitTrueCheese4++;
        break;
      case 5:
        nbBitTrueCheese5++;
        break;
      case 6:
        nbBitTrueCheese6++;
        break;
      }
    }
    indice++;
  }

  for (var j = 0; j < matriceB.length; j++) {
    var valBit1 = matriceB[0][j];
    var valBit2 = matriceB[1][j];
    var valBit3 = matriceB[2][j];
    var valBit4 = matriceB[3][j];
    var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
    tableau[bitParite[indice]] = paire;
    if(paire){
      var cheese = cheeseBitParite[indice];
      switch (cheese) {
      case 1:
        nbBitTrueCheese1++;
        break;
      case 2:
        nbBitTrueCheese2++;
        break;
      case 3:
        nbBitTrueCheese3++;
        break;
      case 4:
        nbBitTrueCheese4++;
        break;
      case 5:
        nbBitTrueCheese5++;
        break;
      case 6:
        nbBitTrueCheese6++;
        break;
      }
    }
    indice++;
  }

  // matriceC
  for (var j = 0; j < matriceC.length; j++) {
    var valBit1 = matriceC[j][0];
    var valBit2 = matriceC[j][1];
    var valBit3 = matriceC[j][2];
    var valBit4 = matriceC[j][3];
    var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
    tableau[bitParite[indice]] = paire;
    if(paire){
      var cheese = cheeseBitParite[indice];
      switch (cheese) {
      case 1:
        nbBitTrueCheese1++;
        break;
      case 2:
        nbBitTrueCheese2++;
        break;
      case 3:
        nbBitTrueCheese3++;
        break;
      case 4:
        nbBitTrueCheese4++;
        break;
      case 5:
        nbBitTrueCheese5++;
        break;
      case 6:
        nbBitTrueCheese6++;
        break;
      }
    }
    indice++;
  }

  for (var j = 0; j < matriceC.length; j++) {
    var valBit1 = matriceC[0][j];
    var valBit2 = matriceC[1][j];
    var valBit3 = matriceC[2][j];
    var valBit4 = matriceC[3][j];
    var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
    tableau[bitParite[indice]] = paire;
    if(paire){
      var cheese = cheeseBitParite[indice];
      switch (cheese) {
      case 1:
        nbBitTrueCheese1++;
        break;
      case 2:
        nbBitTrueCheese2++;
        break;
      case 3:
        nbBitTrueCheese3++;
        break;
      case 4:
        nbBitTrueCheese4++;
        break;
      case 5:
        nbBitTrueCheese5++;
        break;
      case 6:
        nbBitTrueCheese6++;
        break;
      }
    }
    indice++;
  }

  // matriceD
  for (var j = 0; j < matriceD.length; j++) {
    var valBit1 = matriceD[j][0];
    var valBit2 = matriceD[j][1];
    var valBit3 = matriceD[j][2];
    var valBit4 = matriceD[j][3];
    var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
    tableau[bitParite[indice]] = paire;
    if(paire){
      var cheese = cheeseBitParite[indice];
      switch (cheese) {
      case 1:
        nbBitTrueCheese1++;
        break;
      case 2:
        nbBitTrueCheese2++;
        break;
      case 3:
        nbBitTrueCheese3++;
        break;
      case 4:
        nbBitTrueCheese4++;
        break;
      case 5:
        nbBitTrueCheese5++;
        break;
      case 6:
        nbBitTrueCheese6++;
        break;
      }
    }
    indice++;
  }

  for (var j = 0; j < matriceD.length; j++) {
    var valBit1 = matriceD[0][j];
    var valBit2 = matriceD[1][j];
    var valBit3 = matriceD[2][j];
    var valBit4 = matriceD[3][j];
    var paire = estPaire(valBit1,valBit2,valBit3,valBit4);
    tableau[bitParite[indice]] = paire;
    if(paire){
      var cheese = cheeseBitParite[indice];
      switch (cheese) {
      case 1:
        nbBitTrueCheese1++;
        break;
      case 2:
        nbBitTrueCheese2++;
        break;
      case 3:
        nbBitTrueCheese3++;
        break;
      case 4:
        nbBitTrueCheese4++;
        break;
      case 5:
        nbBitTrueCheese5++;
        break;
      case 6:
        nbBitTrueCheese6++;
        break;
      }
    }
    indice++;
  }

  // répartition des checksum dans le tableau en fonction des bits dans les différentes parts de fromage
  for (var i = 0; i < 6; i++) {
    switch (i) {
    case 0:
      // si on a plus d'absents que de présents
      if(((cheese1Tableau.length + cheese1PariteTableau.length) - nbBitTrueCheese1) > nbBitTrueCheese1){
        // on inverse B&W
        tableau[blackWhiteTableau[i]] = !tableau[blackWhiteTableau[i]];
        nbBitTrueCheese1 = (cheese1Tableau.length  + cheese1PariteTableau.length) - nbBitTrueCheese1;
        // on inverse les bits de la part de fromage
        for (var j = 0; j < cheese1Tableau.length; j++)
          tableau[cheese1Tableau[j]] = !tableau[cheese1Tableau[j]];
        for (var j = 0; j < cheese1PariteTableau.length; j++)
          tableau[cheese1PariteTableau[j]] = !tableau[cheese1PariteTableau[j]];

        nbBitTrueCheese1++; // on ajoute le noir & blanc au nombre de bit de la part 1
      }
      break;
    case 1:
      if(((cheese2Tableau.length + cheese2PariteTableau.length) - nbBitTrueCheese2) > nbBitTrueCheese2){
        tableau[blackWhiteTableau[i]] = !tableau[blackWhiteTableau[i]];
        nbBitTrueCheese2 = (cheese2Tableau.length  + cheese2PariteTableau.length) - nbBitTrueCheese2;
        for (var j = 0; j < cheese2Tableau.length; j++)
          tableau[cheese2Tableau[j]] = !tableau[cheese2Tableau[j]]
        for (var j = 0; j < cheese2PariteTableau.length; j++)
          tableau[cheese2PariteTableau[j]] = !tableau[cheese2PariteTableau[j]];

        nbBitTrueCheese2++;
      }
      break;
    case 2:
      if(((cheese3Tableau.length + cheese3PariteTableau.length) - nbBitTrueCheese3) > nbBitTrueCheese3){
        tableau[blackWhiteTableau[i]] = !tableau[blackWhiteTableau[i]];
        nbBitTrueCheese3 = (cheese3Tableau.length  + cheese3PariteTableau.length) - nbBitTrueCheese3;
        for (var j = 0; j < cheese3Tableau.length; j++)
          tableau[cheese3Tableau[j]] = !tableau[cheese3Tableau[j]];
        for (var j = 0; j < cheese3PariteTableau.length; j++)
          tableau[cheese3PariteTableau[j]] = !tableau[cheese3PariteTableau[j]];

        nbBitTrueCheese3++;
      }
      break;
    case 3:
      if(((cheese4Tableau.length + cheese4PariteTableau.length) - nbBitTrueCheese4) > nbBitTrueCheese4){
        tableau[blackWhiteTableau[i]] = !tableau[blackWhiteTableau[i]];
        nbBitTrueCheese4 = (cheese4Tableau.length  + cheese4PariteTableau.length) - nbBitTrueCheese4;
        for (var j = 0; j < cheese4Tableau.length; j++)
          tableau[cheese4Tableau[j]] = !tableau[cheese4Tableau[j]];
        for (var j = 0; j < cheese4PariteTableau.length; j++)
          tableau[cheese4PariteTableau[j]] = !tableau[cheese4PariteTableau[j]];

        nbBitTrueCheese4++;
      }
      break;
    case 4:
      if(((cheese5Tableau.length + cheese5PariteTableau.length)- nbBitTrueCheese5) > nbBitTrueCheese5){
        tableau[blackWhiteTableau[i]] = !tableau[blackWhiteTableau[i]];
        nbBitTrueCheese5 = (cheese5Tableau.length  + cheese5PariteTableau.length) - nbBitTrueCheese5;
        for (var j = 0; j < cheese5Tableau.length; j++)
          tableau[cheese5Tableau[j]] = !tableau[cheese5Tableau[j]];
        for (var j = 0; j < cheese5PariteTableau.length; j++)
          tableau[cheese5PariteTableau[j]] = !tableau[cheese5PariteTableau[j]];

        nbBitTrueCheese5++;
      }
      break;
    case 5:
      if(((cheese6Tableau.length + cheese6PariteTableau.length) - nbBitTrueCheese6) > nbBitTrueCheese6){
        tableau[blackWhiteTableau[i]] = !tableau[blackWhiteTableau[i]];
        nbBitTrueCheese6 = (cheese6Tableau.length  + cheese6PariteTableau.length) - nbBitTrueCheese6;
        for (var j = 0; j < cheese6Tableau.length; j++)
          tableau[cheese6Tableau[j]] = !tableau[cheese6Tableau[j]];
        for (var j = 0; j < cheese6PariteTableau.length; j++)
          tableau[cheese6PariteTableau[j]] = !tableau[cheese6PariteTableau[j]];

        nbBitTrueCheese6++;
      }
      break;
    }
  }

  // on ajoute les présents
  nbBitTrueCheese1+=nbPresentCheese1;
  nbBitTrueCheese2+=nbPresentCheese2;
  nbBitTrueCheese3+=nbPresentCheese3;
  nbBitTrueCheese4+=nbPresentCheese4;
  nbBitTrueCheese5+=nbPresentCheese5;
  nbBitTrueCheese6+=nbPresentCheese6;

  // on compte le nombre de bit à true
  var totalBitTrue = nbBitTrueCheese1 + nbBitTrueCheese2 + nbBitTrueCheese3 + nbBitTrueCheese4 + nbBitTrueCheese5 +nbBitTrueCheese6;
  var mask = 1;
  for (var i = 0; i < checkSumValueTableau.length - 1; i++, mask <<= 1)
    checkSumValueTableau[i] = (totalBitTrue & mask) != 0 ? true : false;

  // le dernier checksum est l'inverse du premier
  checkSumValueTableau[4] = !checkSumValueTableau[0];

  for (var i = 0; i < checkSumTableau.length; i++)// on ne se serre pas du dernier checksum
    tableau[checkSumTableau[i]] = checkSumValueTableau[i];

  var chaine = "";
  for (var i = 0; i < tableau.length; i++)
    chaine += tableau[i] ? 'X' : '.';

  //console.log(chaine);
  return chaine;
}

export function GetPrivateKey(encrypted_data_key,pin) {
  try {
    var bytes  = CryptoJS.AES.decrypt(encrypted_data_key, pin);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  catch(e) {
    return '';
  }
}
