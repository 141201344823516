import React from "react";

import {StrDef,Clone} from '../../assets/lib/utils';
import {
  Input,
} from "reactstrap";

var moment = require('moment');

export default class FormFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      border:'solid 1px #1f367b',
      fill:'#1f367b',
    };

    if( StrDef(this.props.data.vin) ) this.state.initialVin = this.props.data.vin;

    if( !StrDef(this.state.data.km) ) this.state.data.km = '';
    if( !StrDef(this.state.data.registration) ) this.state.data.registration = '';

    this.Check = this.Check.bind(this);
    this.Sign = this.Sign.bind(this);

    var user = sessionStorage.getItem('account');
    if( StrDef(user) ) {
      try {
        this.user = JSON.parse(user);
      }
      catch(e){}
    }

  }

  static getDerivedStateFromProps(nextProps, prevState){
    if( nextProps.data.real!==prevState.data.real
        || nextProps.data.guaranty!==prevState.data.guaranty
        || nextProps.data.km!==prevState.data.km
        || nextProps.data.registration!==prevState.data.registration
        || nextProps.data.vin!==prevState.data.vin
        || nextProps.data.finished!==prevState.data.finished ) {
      var data = Clone(nextProps.data);
      if( !StrDef(data.km) ) data.km = '';
      return { data: data };
    }
    else return null;
  }

  Check(mode) {
    var data = Clone(this.state.data);

    if( mode === 'r' ) {
      data.real = !data.real;
      if( data.real ) data.guaranty = false;
      else data.guaranty = true;
    }
    if( mode === 'g' ) {
      data.guaranty = !data.guaranty;
      if( data.guaranty ) data.real = false;
      else data.real = true;
    }

    this.props.onChange(data);
  }

  Change(what,t) {
    var d = Clone(this.state.data);
    var t = t.target.value.replace(/ /g,'');

    if( what == 'km' ) {
      if( isNaN(t) ) {
        t = '';
      }
      else {
        var space = t.length-3;
        t = t.substring(0,space)+' '+t.substring(space);
      }
    }
    if( what == 'vin' ) {
      t = t.substring(0,17);
    }

    if( t == ' ' ) t = '';
    d[what] = t;
    this.props.onChange(d);
  }

  Sign() {
    if( !StrDef(this.state.data.km) ) {
      window.notify('Veuillez renseigner le kilométrage','danger');
      return;
    }
    if( !StrDef(this.state.data.registration) ) {
      window.notify('Veuillez renseigner l\'immatriculation','danger');
      return;
    }
    this.setState({signing:true});
    this.props.sign((retour)=>{
      if (StrDef(retour.error)) {
        this.setState({signing:false});
      }
    });

  }

  render() {
    var bg = 'transparent', bgG = 'transparent';
    if( this.state.data.real ) bg = '#ffdc00';
    if( this.state.data.guaranty ) bgG = '#ffdc00';

    var error = {}, errorCount = 0;
    var finished = /*this.state.data.finished*/true;

    if( !finished ) {
      error['answer'] = true;
      errorCount++;
    }
    if( !StrDef(this.state.data.km) ) {
      error['km'] = true;
      errorCount++;
    }
    if( !(this.state.data.real===true||this.state.data.guaranty===true) ) {
      error['kmType'] = true;
      errorCount++;
    }

    if( errorCount != 0 ) finished = false;

    return (
      <div style={{display:'flex',flex:1,flexDirection:'row',margin:10}}>

        <div className="footerPeugeotInfo">
          <div style={{display:'flex',flex:1,border:'solid 0px #1f367b',borderBottomWidth:1,alignItems:'center',paddingLeft:10}}>
            <div style={{display:'flex',marginRight:10}}>
              Immatriculation / N°Chrono :
            </div>
            <div style={{display:'flex',flex:1,flexDirection:'row',border:'dotted 0px #1f367b',borderBottomWidth:1}}>
              <Input
                autoComplete="off"
                value={this.state.data.registration}
                style={{padding:0,backgroundColor:'transparent',fontSize:'1em',border:'none',color:'#1f367b'}}
                onChange={this.Change.bind(this,'registration')}/>
            </div>
          </div>
          <div style={{display:'flex',flex:1,flexDirection:'row',justifyContent:'center',alignItems:'center',border:'solid 0px #1f367b',borderBottomWidth:1,paddingLeft:10}}>
            <div style={{display:'flex',marginRight:10}}>
              Kilométrage :
            </div>
            <div style={{display:'flex',flex:1,flexDirection:'row',border:'dotted 0px #1f367b',borderBottomWidth:1}}>
              <Input
                autoComplete="off"
                value={this.state.data.km}
                style={{padding:0,backgroundColor:'transparent',fontSize:'1em',border:'none',color:'#1f367b'}}
                onChange={this.Change.bind(this,'km')}/>
            </div>
            <div style={{display:'flex',marginLeft:10,marginRight:20,flexDirection:'row',cursor:'pointer'}} onClick={this.Check.bind(this,'r')}>
              <div style={{width:18,height:18,margin:0,marginRight:5,padding:0,border:this.state.border,backgroundColor:bg}}>
                { this.state.data.real &&
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-40 110 512 512" style={{width:'15px',height:'15px'}}>
                    <g fill={this.state.fill}>
                      <path d=" M 453.886 138.696 L 421.194 105.074 C 419.893 103.588 417.85 102.845 415.993 102.845 C 413.949 102.845 412.092 103.588 410.791 105.074 L 184.171 333.367 L 101.696 250.892 C 100.21 249.406 98.352 248.663 96.495 248.663 C 94.637 248.663 92.779 249.406 91.293 250.892 L 58.229 283.956 C 55.257 286.928 55.257 291.572 58.229 294.544 L 162.252 398.567 C 168.939 405.254 177.112 409.155 183.985 409.155 C 193.83 409.155 202.375 401.91 205.532 398.938 L 205.718 398.938 L 454.072 149.284 C 456.673 146.126 456.673 141.482 453.886 138.696 L 453.886 138.696 Z "/>
                    </g>
                  </svg>
                }
              </div>
              Réel
            </div>
            <div style={{display:'flex',flexDirection:'row',cursor:'pointer',paddingRight:10}} onClick={this.Check.bind(this,'g')}>
              <div style={{width:18,height:18,margin:0,marginRight:5,padding:0,border:this.state.border,backgroundColor:bgG}}>
                { this.state.data.guaranty &&
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-40 110 512 512" style={{width:'15px',height:'15px'}}>
                    <g fill={this.state.fill}>
                      <path d=" M 453.886 138.696 L 421.194 105.074 C 419.893 103.588 417.85 102.845 415.993 102.845 C 413.949 102.845 412.092 103.588 410.791 105.074 L 184.171 333.367 L 101.696 250.892 C 100.21 249.406 98.352 248.663 96.495 248.663 C 94.637 248.663 92.779 249.406 91.293 250.892 L 58.229 283.956 C 55.257 286.928 55.257 291.572 58.229 294.544 L 162.252 398.567 C 168.939 405.254 177.112 409.155 183.985 409.155 C 193.83 409.155 202.375 401.91 205.532 398.938 L 205.718 398.938 L 454.072 149.284 C 456.673 146.126 456.673 141.482 453.886 138.696 L 453.886 138.696 Z "/>
                    </g>
                  </svg>
                }
              </div>
              Non garanti
            </div>
          </div>
          <div style={{display:'flex',flex:1,alignItems:'center',paddingLeft:10}}>
            <div style={{display:'flex',marginRight:10}}>
              N°Série ou V.I.N. :
            </div>
            <div style={{display:'flex',flex:1,flexDirection:'row',border:'dotted 0px #1f367b',borderBottomWidth:1}}>
              { StrDef(this.state.initialVin) &&
                this.props.data.vin
              }
              { !StrDef(this.state.initialVin) &&
                <Input
                  autoComplete="off"
                  value={this.state.data.vin}
                  style={{padding:0,backgroundColor:'transparent',fontSize:'1em',border:'none',color:'#1f367b',textTransform:'uppercase'}}
                  onChange={this.Change.bind(this,'vin')}/>
              }
            </div>
          </div>
        </div>
        <div style={{display:'flex',flex:7,flexDirection:'row',padding:10,fontSize:'1.1em',justifyContent:'center',alignItems:'center'}}>
          <div style={{display:'flex',flex:0.8,flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start'}}>
            <div style={{display:'flex',flexDirection:'row',width:300}}>
              <div style={{display:'flex',paddingTop:2}}>Fait le : </div>
              <div style={{display:'flex',flex:1,height:30}}>
                <div style={{width:200,paddingLeft:11}}>{moment().format('DD/MM/YYYY')}</div>
                <div style={{position:'relative',marginLeft:-190,marginTop:20,width:240,height:1,backgroundColor:'#1f367b'}}></div>
              </div>
            </div>
            <div style={{marginTop:5}}>
              Nom et signature du technicien :
            </div>
            {window.API.currentUser.name}
            <div style={{position:'relative',width:300,height:1,backgroundColor:'#1f367b'}}></div>
            { this.state.signing &&
              <img src={window.API.currentUser.signature.src} style={{position:'relative',marginTop:'-80px',marginLeft:'100px',width:'230px',objectFit:'contain',transform:'rotate(-6deg)'}}/>
            }
          </div>
          { !this.state.signing &&
            <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',opacity:/*finished?*/1/*:0.3*/}}>
              <button className="btnRound" style={{width:200,height:60}} onClick={/*finished?*/this.Sign/*:null*/}>
                Valider et signer
              </button>
            </div>
          }
        </div>
        <div style={{display:'flex',flex:7,height:130,flexDirection:'column',border:'solid 2px #1f367b',justifyContent:'flex-start',alignItems:'center',paddingTop:10}}>
          Cachet
          { this.state.signing &&
            <img src={this.props.stamp} style={{position:'relative',marginTop:-29,width:'210px',objectFit:'contain'}}/>
          }
        </div>
      </div>
    );
  }
}
