import React from "react";
// react plugin used to create charts
import i18n from "../assets/lib/i18n";
import {StrDef,Clone} from '../assets/lib/utils';
import Icon from '../components/Icons/Icon.jsx';
import {Button,Page,AvatarRender} from '../components/UI/UI.jsx';
// reactstrap components
import {
  Container,
  Row,
  Col,
  Modal,
  Input,
} from "reactstrap";

import avatarM from "../assets/img/avatar_m.png";
import logo from "../assets/img/logo.png";
import PubNubReact from 'pubnub-react';
import Select from "react-select";

var QRCode = require('qrcode.react');
var uuid = require('uuid/v4');



class AdminPanel extends Page {

  constructor(props) {
    super(props);

    this.UserAdd = this.UserAdd.bind(this);
    this.Backward = this.Backward.bind(this);
    this.AvatarClick = this.AvatarClick.bind(this);
    this.UserAddSave = this.UserAddSave.bind(this);
    this.UserDelete = this.UserDelete.bind(this);
    this.ToggleModalDelete = this.ToggleModalDelete.bind(this);

    this.state = {
      userList:[],
      adminList:[],
      step:'list',
      user_name:'',
      password:'',
      password2:'',
      permission:1,
      initial_user_name:'',
      local_data:{name:'',signature:''},
      qrcode:'hello',
    };

    this.PubNubInit();
  }

  PubNubInit() {
    this.pubnub = new PubNubReact({ subscribeKey: 'sub-c-54f76aa6-cd1d-11e8-80d1-72aadab1d7f1' });
    this.pubnub.init(this);
  }

  componentDidMount() {
    // on teste si le pinCode a ete saisi
    var p = localStorage.getItem('version.build');
    if( p == null ) {
      var event = new CustomEvent('needCode', {detail:window.API.encryptedDataKey});
      window.dispatchEvent(event);
    }

    this.pubnubChannel = uuid();
    this.pubnub.subscribe({channels:[this.pubnubChannel]});

    this.pubnub.getMessage(this.pubnubChannel, (msg) => {
      if( this.state.step != 'list' ) {
        var tmp = Clone(this.state);
        tmp.local_data.signature = msg.message.sign;
        this.setState(tmp,()=>{
          document.querySelector('#svgSign').innerHTML = msg.message.sign;
        });
      }
      // pour afficher le svg dans une image
      // var data = "data:image/svg+xml;base64," + btoa(SVG-TEXT-DE-LA-BASE),
      // img  = new Image()
      // img.setAttribute('src', data)
      // document.body.appendChild(img);
    });

    var user = sessionStorage.getItem('user');
    if( user != null ) user = JSON.parse(user);
    this.isAdminUser = user.userId == window.API.entityId;
    this.Me = user.userId;

    this.LoadList();

    var token = window.API.refreshToken;
    var string = document.location.protocol+'//'+document.location.hostname+(document.location.port ? ':'+document.location.port: '')+'/signature.html?token='+token+'&channel='+this.pubnubChannel;
    this.setState({qrcode:string});
    console.log( string );
  }

  componentWillUnmount() {
  }

  LoadList() {
    // liste des users
    window.API.userGetLocal((res)=>{
      this.setState({userList:res});
      if( StrDef(window.LayoutChangeTitle) ) window.LayoutChangeTitle(<div className="layoutBarTitle">{i18n.t('AdminPanel.layoutBarTitle')}</div>);
    });
    //if( this.isAdminUser ) {
      // liste des admins
      window.API.userGetLocal((res)=>{
        // on enleve le user admin qui a le meme user_id que l'entity
        var tmp = [];
        for(var i=0;i<res.length;i++) {
          tmp.push(res[i]);
        }
        this.setState({adminList:tmp});
        if( StrDef(window.LayoutChangeTitle) ) window.LayoutChangeTitle(<div className="layoutBarTitle">{i18n.t('AdminPanel.layoutBarTitle')}</div>);
      },0);
    //}
  }

  Change(what,t) {
    var tmp = Clone(this.state);
    if( what == 'login' ) {
      tmp.user_name = t.target.value;
    }
    else if( what == 'name' ) {
      var name = t.target.value;
      tmp.local_data.name = name;
      var str = name.split(' ');
      name = str[0]+str[str.length-1].charAt(0);
      while( name.length < 5 ) name += 'a';
      if( !StrDef(this.state._id) ) {
        tmp.user_name = name;
      }
    }
    else {
      tmp[what] = t.target.value;
    }
    this.setState(tmp);
  }

  UserAdd() {
    this.setState({ step:'userAdd',
                    user_name:'',
                    password:'',
                    password2:'',
                    initial_user_name:'',
                    permission:1,
                    local_data:{name:'',signature:''},
                    _id:'',
                    status:undefined});
  }

  UserAddSave() {
    if( !StrDef(this.state.local_data.signature) && parseInt(this.state.status)!=9 && this.state.permission==1 ) {
      window.notify(i18n.t('AdminPanel.userSignError'),'danger');
      return;
    }
    if( StrDef(this.state._id) ) {  // en modification
      if( StrDef(this.state.password) ) {
        if( this.state.password != this.state.password2 ) {
          window.notify(i18n.t('AdminPanel.adminPassSameError'),'danger');
          return;
        }
        if( parseInt(this.state.status)!=9 && this.state.permission==0
            && this.state.password.length<8 ) {
          window.notify(i18n.t('AdminPanel.adminPassError'),'danger');
          return;
        }
      }
    }
    else {                          // en creation
      if( this.state.permission==0 && !(StrDef(this.state.password) && this.state.password==this.state.password2) ) {
        window.notify(i18n.t('AdminPanel.adminPassSameError'),'danger');
        return;
      }
      if( parseInt(this.state.status)!=9 && this.state.permission==0
          && !(StrDef(this.state.password) && this.state.password.length==8) ) {
        window.notify(i18n.t('AdminPanel.adminPassError'),'danger');
        return;
      }
    }


    this.Submitting(true);
    window.API.CreateUpdateUser(this.state,
      (res)=>{
        if( StrDef(res.error) ) {
          if( res.error.code == 'UserNameNotAvailableException' ) {
            window.notify(i18n.t('AdminPanel.loginExists'),'danger')
          }
          else {
            window.notify(i18n.t('all.recordImpossible'),'danger');
          }
          this.Submitting(false);
        }
        else {
          var text = parseInt(this.state.status)==9?'userDeleted':'userAdded';
          if( StrDef(this.state._id) ) {
            text = parseInt(this.state.status)==9?'userDeleted':'userUpdated';
          }
          window.notify(i18n.t('AdminPanel.'+text),'success');
          this.LoadList();
          this.Backward();
          this.Submitting(false);
        }
    });
  }

  UserDelete() {
    this.ToggleModalDelete();
  }

  ToggleModalDelete() {
    this.setState({modalDelete:!this.state.modalDelete});
  }

  Backward() {
    if( this.state.step == 'userAdd' ) {
      this.setState({step:'list'});
    }
  }

  AvatarClick(data) {

    this.isMe = false;
    if( this.Me == data.user_id ) this.isMe = true;

    if( !StrDef(data.local_data) ) data.local_data = {name:data.user_name,signature:''};
    data.password = '';
    data.password2 = '';
    var tmp = {...data,
                initial_user_name:data.user_name,
                step:'userAdd'};
    this.setState(tmp,()=>{
      if( document.querySelector('#svgSign') != null )
        document.querySelector('#svgSign').innerHTML = this.state.local_data.signature;
    });
  }

  UserDeleteConfirm(bool) {
    if( bool ) {
      this.setState({status:9},()=>{
        this.UserAddSave();
      });
    }
    this.ToggleModalDelete();
  }

  SignatureReset() {
    var local_data = Clone(this.state.local_data);
    local_data.signature = '';
    this.setState({local_data:local_data});
  }

  render() {

    return (
      <>
        <Container className="loginWindow" fluid={true}>
          { this.state.step == 'userAdd' &&
            <>
              <Row style={{height:80}}>
                <Col md="2">
                  <Icon name="back" size={44} color='white' onClick={this.Backward}/>
                </Col>
                <Col md="8" className="text-center">
                  <h2>{StrDef(this.state._id)?i18n.t('AdminPanel.userUpdateTitle'):i18n.t('AdminPanel.userAddTitle')}</h2>
                </Col>
                <Col md="2" className="text-right">
                  { false &&
                    <img src={logo} style={{width:'130px',objectFit:'contain'}}/>
                  }
                </Col>
              </Row>
              { !this.isMe &&
                <Row>
                  <Col md="2"></Col>
                  <Col md="8">
                    <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',border:'solid 0px #FFF',borderBottomWidth:'1px'}}>
                      <div className="loginLabel">Rôle :</div>
                      <div style={{display:'flex',flex:1,justifyContent:'flex-end',alignItems:'center',marginBottom:10}}>
                        <Select
                          className="react-select primary selectRole"
                          classNamePrefix="react-select"
                          isSearchable={false}
                          value={{value:this.state.permission,label:i18n.t('AdminPanel.userRole'+this.state.permission)}}
                          onChange={value => {
                            this.setState({permission:value.value},()=>{
                              if( value.value == 1 ) {
                                if( document.querySelector('#svgSign') != null )
                                  document.querySelector('#svgSign').innerHTML = this.state.local_data.signature;
                              }
                            });
                          }}
                          options={[{value:1,label:i18n.t('AdminPanel.userRole1')},{value:0,label:i18n.t('AdminPanel.userRole0')}]}
                          placeholder="Choisir un type"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="2"></Col>
                </Row>
              }
              <Row style={{marginTop:20}}>
                <Col md="2"></Col>
                <Col md="8">
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',border:'solid 0px #FFF',borderBottomWidth:'1px'}}>
                    <div className="loginLabel">Prénom & Nom :</div>
                    <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',marginBottom:10}}>
                      <Input
                        type="text"
                        autoFocus
                        autoComplete="off"
                        value={this.state.local_data.name}
                        style={{padding:0,textAlign:'right',backgroundColor:'transparent',fontSize:'2em',border:'none',color:'#FFF'}}
                        onKeyPress={this.KeyPressed}
                        onChange={this.Change.bind(this,'name')}/>
                    </div>
                  </div>
                </Col>
                <Col md="2"></Col>
              </Row>
              <Row style={{marginTop:20}}>
                <Col md="2"></Col>
                <Col md="8">
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',border:'solid 0px #FFF',borderBottomWidth:'1px'}}>
                    <div className="loginLabel">Identifiant :</div>
                    <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',marginBottom:10}}>
                      <Input
                        type="text"
                        autoComplete="off"
                        value={this.state.user_name}
                        style={{padding:0,textAlign:'right',backgroundColor:'transparent',fontSize:'2em',border:'none',color:'#FFF'}}
                        onKeyPress={this.KeyPressed}
                        onChange={this.Change.bind(this,'login')}/>
                    </div>
                  </div>
                </Col>
                <Col md="2"></Col>
              </Row>
              { this.state.permission == 0 &&
                <>
                  <Row style={{marginTop:20}}>
                    <Col md="2"></Col>
                    <Col md="8">
                      <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',border:'solid 0px #FFF',borderBottomWidth:'1px'}}>
                        <div className="loginLabel">Mot de passe :</div>
                        <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',marginBottom:10}}>
                          <Input
                            type="password"
                            autoComplete="off"
                            value={this.state.password}
                            style={{padding:0,textAlign:'right',backgroundColor:'transparent',fontSize:'2em',border:'none',color:'#FFF'}}
                            onChange={this.Change.bind(this,'password')}/>
                        </div>
                      </div>
                    </Col>
                    <Col md="2"></Col>
                  </Row>
                  <Row style={{marginTop:20}}>
                    <Col md="2"></Col>
                    <Col md="8">
                      <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',border:'solid 0px #FFF',borderBottomWidth:'1px'}}>
                        <div className="loginLabel">Re-saisir le mot de passe :</div>
                        <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',marginBottom:10}}>
                          <Input
                            type="password"
                            autoComplete="off"
                            value={this.state.password2}
                            style={{padding:0,textAlign:'right',backgroundColor:'transparent',fontSize:'2em',border:'none',color:'#FFF'}}
                            onChange={this.Change.bind(this,'password2')}/>
                        </div>
                      </div>
                    </Col>
                    <Col md="2"></Col>
                  </Row>
                </>
              }
              { (this.state.permission==1 && !StrDef(this.state.local_data.signature)) &&
                <Row style={{marginTop:30}}>
                  <Col md="2"></Col>
                  <Col md="4">
                    <p className="loginLabel">
                      Pour enregistrer la signature veuillez scanner ce qrcode et allez à l'adresse indiquée.
                    </p>
                  </Col>
                  <Col md="4" className="text-right">
                    <QRCode value={this.state.qrcode} size={320} includeMargin={true}/>
                  </Col>
                  <Col md="2"></Col>
                </Row>
              }

              { (this.state.permission==1 && StrDef(this.state.local_data.signature)) &&
                <Row style={{marginTop:30}}>
                  <Col md="2"></Col>
                  <Col md="2">
                    <p className="loginLabel">
                      Signature :
                    </p>
                    <div onClick={this.SignatureReset.bind(this)}>
                      <p className="loginLabel" style={{fontSize:'1em',textDecoration:'underline',cursor:'pointer'}}>Refaire la signature</p>
                    </div>
                  </Col>
                  <Col md="6" className="text-right">
                    <div style={{display:'flex',flex:1,alignItems:'flex-end',justifyContent:'flex-end'}}>
                      <div id='svgSign' style={{display:'flex',backgroundColor:'white',textAlign:'right',width:360,borderRadius:16}}></div>
                    </div>
                  </Col>
                  <Col md="2"></Col>
                </Row>
              }

              <Row style={{marginTop:60}}>
                <Col md="12" className="text-center">
                  <Button
                    label={(StrDef(this.state._id)?i18n.t('AdminPanel.userUpdate'):i18n.t('AdminPanel.userAdd'))}
                    icon={StrDef(this.state._id)?'ok':'add'}
                    disabled={this.state._submitting}
                    onClick={this.UserAddSave}/>
                  { StrDef(this.state._id) && !this.isMe &&
                    <Button label={i18n.t('AdminPanel.userDelete')}
                            icon="delete"
                            iconColor={'#FF0000'}
                            onClick={this.UserDelete}
                            style={{marginLeft:20,color:'#FF0000'}}/>
                  }
                </Col>
              </Row>

              <Modal isOpen={this.state.modalDelete} toggle={this.ToggleModalDelete} backdrop='static'>
                <Row>
                  <Col md="12" className="text-center" style={{padding:40}}>
                    <Icon name="delete" size={80} color={'#FF0000'}/>
                    <div className="textOnWhite" style={{marginTop:50,marginBottom:50}}>
                      Etes vous sûr de vouloir supprimer le technicien {this.state.name} ?
                    </div>
                    <Button label={i18n.t('all.cancel')} onClick={this.UserDeleteConfirm.bind(this,false)}/>
                    <Button label={i18n.t('AdminPanel.userDelete')} icon="delete" iconColor={'#FF0000'} onClick={this.UserDeleteConfirm.bind(this,true)} style={{marginLeft:20,color:'#FF0000'}}/>
                  </Col>
                </Row>
              </Modal>

            </>
          }
          { this.state.step == 'list' &&
            <>
              <Row style={{height:120}}>
                <Col md="6">
                  <h2>{i18n.t('AdminPanel.userTitle')}</h2>
                  <Button label={i18n.t('AdminPanel.userAdd')} icon="add" onClick={this.UserAdd}/>
                </Col>
                <Col md="6" className="text-right">
                  <img src={logo} style={{width:'130px',objectFit:'contain'}}/>
                </Col>
              </Row>

              <div style={{display:'flex',flexDirection:'column',overflow:'auto',maxHeight:'calc(100vh - 200px)'}}>
                <Row style={{marginTop:30}}>
                  <Col md="12">
                    <h3>{i18n.t('AdminPanel.userList')}</h3>
                  </Col>
                </Row>

                <Row style={{marginTop:this.isAdminUser?0:30,alignItems:'center'}}>
                  <Col md="12">
                    <div style={{display:'flex',flexWrap:'wrap',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                    { this.state.userList.map((data,i)=>{
                        return AvatarRender(data,i,this.AvatarClick);
                      })
                    }
                    </div>
                  </Col>
                </Row>
                { this.state.adminList.length > 0 &&
                <>
                  <Row style={{marginTop:30}}>
                    <Col md="12">
                      <h3>{i18n.t('AdminPanel.adminList')}</h3>
                    </Col>
                  </Row>
                  <Row style={{alignItems:'center'}}>
                    <Col md="12">
                      <div style={{display:'flex',flexWrap:'wrap',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                      { this.state.adminList.map((data,i)=>{
                          return AvatarRender(data,i,this.AvatarClick);
                        })
                      }
                      </div>
                    </Col>
                  </Row>
                </>
              }
              </div>
            </>
          }
        </Container>
      </>
    );
  }
}

export default AdminPanel;
