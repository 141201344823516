import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth/Login.jsx";
import CertifLayout from "layouts/Certif/Certif.jsx";
import AuthRoute from "components/AuthRoute/AuthRoute.jsx";
import PinCode from "components/Notification/PinCode.jsx";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";
import "assets/scss/restyle.scss";

import Api from './assets/lib/api';

// on instancie la classe API qui gère les appels aux serveurs
var api = new Api();
window.API = api;
window.API.SyncLocal();

const hist = createBrowserHistory();
window.routerHistory = hist;

const logout = () => {
  sessionStorage.clear();
  window.routerHistory.push('/home');
};
window.addEventListener('logout',logout,false);

var RefPinCode = null;
const needCode = (e) => {
  if( RefPinCode == null ) {  // selon le chargement de la page
    setTimeout(()=>{ needCode(e) },100);
  }
  else {
    RefPinCode.Open(e.detail);
  }
};
window.addEventListener('needCode',needCode,false);

console.log( '>>>> ', process.env.PUBLIC_URL );

ReactDOM.render(
  <>
    <PinCode ref={(c)=>RefPinCode=c}/>
    <Router history={hist}>
      <Switch>
        <Route path={`${process.env.PUBLIC_URL}/home`} render={props => <AuthLayout {...props} />} />
        <AuthRoute path={`${process.env.PUBLIC_URL}/labo`} component={CertifLayout}/>
        <Redirect to={`${process.env.PUBLIC_URL}/home`} />
      </Switch>
    </Router>
  </>,
  document.getElementById("root")
);
