import React from "react";
import i18n from "../assets/lib/i18n";
import {StrDef,file_hash,getS3Region,getUserId,getEntityId,getEntityName,StringifyOCode,DrawOCode} from '../assets/lib/utils';
import CheckLine from '../components/Input/CheckLine.jsx';
import FormFooterClaro from '../components/Footer/FormFooterClaro.jsx';
import jsPDF from 'jspdf'
import {sharedAPI} from '../assets/lib/api';
var moment = require('moment');
var uuid = require('uuid/v4');

function getPointA(x,y) {
  let point = {}
  point.x = x-30
  point.y = y-100
  return point;
}
function getPointB(x,y) {
  let point = {}
  point.x = x+25-30
  point.y = y+25-100
  return point;
}
function getPointC(x,y) {
  let point = {}
  point.x = x+75-30
  point.y = y-25-100
  return point;
}

function getPointAPrime(x,y) {
  let point = {}
  point.x = x-30
  point.y = y-100
  return point;
}
function getPointBPrime(x,y) {
  let point = {}
  point.x = x+15-30
  point.y = y+15-100
  return point;
}
function getPointCPrime(x,y) {
  let point = {}
  point.x = x+45-30
  point.y = y-15-100
  return point;
}

export default class Opel {
  constructor() {
    this.data = [
      {"type":"title","label":"Compartiment moteur",category:'compartiment'},
      {"type":"query","label":"Vérifier l'absence de fuites d'huile",category:'compartiment',coords:{x:1160,y:1055}},// 1080,995 -- 80/65
      {"type":"subtitle","label":"Vérifier : "},
      {"type":"query","label":"les bougies ",category:'compartiment',coords:{x:1160,y:1165}},
      {"type":"query","label":"les compressions de chaque cylindre ",category:'compartiment',coords:{x:1160,y:1220}},
      {"type":"query","label":"le niveau d'huile ",category:'compartiment',coords:{x:1160,y:1272}},
      {"type":"query","label":"les courroies ",category:'compartiment',coords:{x:1160,y:1324}},
      {"type":"query","label":"le filtre à air ",category:'compartiment',coords:{x:1160,y:1376}},
      {"type":"query","label":"le filtre à gas-oil",category:'compartiment',coords:{x:1160,y:1430}},
      {"type":"query","label":"Changer (si nécessaire) : ",category:'compartiment',coords:{x:1160,y:1485}},
      {"type":"query","label":"L'huile moteur ",category:'compartiment',coords:{x:1160,y:1538}},
      {"type":"query","label":"le filtre à huile ",category:'compartiment',coords:{x:1160,y:1590}},
      {"type":"query","label":"le filtre à air ",category:'compartiment',coords:{x:1160,y:1645}},
      {"type":"query","label":"Vérifier l'état et le niveau du liquide de batterie ",category:'compartiment',coords:{x:1160,y:1755}},
      {"type":"title","label":"Boite de vitesses ",category:'boite'},
      {"type":"query","label":"Vérifier l'absence de fuites d'huile ",category:'boite',coords:{x:1160,y:1930}},
      {"type":"query","label":"Vérifier le niveau d'huile ",category:'boite',coords:{x:1160,y:1982}},
      {"type":"title","label":"Différentiels ",category:'differentiel'},
      {"type":"query","label":"Vérifier l'absence de fuites d'huile ",category:'differentiel',coords:{x:1160,y:2150}},
      {"type":"query","label":"Vérifier le niveau d'huile ",category:'differentiel',coords:{x:1160,y:2203}},
      {"type":"query","label":"Sur les véhicules 4x4 vérifier les différentiels avant/arrière/central ",category:'differentiel',coords:{x:1160,y:2312}},
      {"type":"title","label":"Composants auxiliaires moteur et boite ",category:'composant'},
      {"type":"subtitle","label":"Vérifier l'étanchéité des différents éléments : "},
      {"type":"query","label":"la boîte de vitesses",category:'composant',coords:{x:1160,y:2648}},
      {"type":"query","label":"les ponts (sur les 4x4)",category:'composant',coords:{x:1160,y:2700}},
      {"type":"query","label":"les mécanismes de direction assistée ",category:'composant',coords:{x:1160,y:2752}},
      {"type":"query","label":"la climatisation ",category:'composant',coords:{x:1160,y:2804}},
      {"type":"title","label":"Durits et tuyaux ",category:'durit'},
      {"type":"subtitle","label":"Sur toutes les Durits et sur tous les tuyaux, vérifier l'absence de traces : "},
      {"type":"query","label":"de fuites ",category:'durit',coords:{x:1160,y:3080}},
      {"type":"query","label":"de corrosion ",category:'durit',coords:{x:1160,y:3132}},
      {"type":"query","label":"d'usure ",category:'durit',coords:{x:1160,y:3184}},
      {"type":"query","label":"Vérifier le serrage des colliers ",category:'durit',coords:{x:2000,y:1005}},
      {"type":"title","label":"Embrayage ",category:'embrayage'},
      {"type":"query","label":"Vérifier le bon fonctionnement ",category:'embrayage',coords:{x:2000,y:1172}},
      {"type":"query","label":"Contrôler la garde d'embrayage ",category:'embrayage',coords:{x:2000,y:1224}},
      {"type":"title","label":"Transmission ",category:'transmission'},
      {"type":"subtitle","label":"Rechercher les fuites et jeux dans : "},
      {"type":"query","label":"les arbres de transmission ",category:'transmission',coords:{x:2000,y:1443}},
      {"type":"query","label":"les arbres moteur ",category:'transmission',coords:{x:2000,y:1495}},
      {"type":"query","label":"les joints et soufflets de cardans ",category:'transmission',coords:{x:2000,y:1547}},
      {"type":"title","label":"Levier de vitesses "},
      {"type":"query","label":"Vérifier le bon fonctionnement ",coords:{x:2000,y:1720}},
      {"type":"title","label":"Supports et Silent blocs "},
      {"type":"query","label":"Vérifier toutes les fixations ",coords:{x:2000,y:1890}},
      {"type":"title","label":"Suspension ",category:'suspension'},
      {"type":"subtitle","label":"Vérifier le fonctionnement correct : "},
      {"type":"query","label":"des ressorts",category:'suspension',coords:{x:2000,y:2112},avr:true},
      {"type":"query","label":"des amortisseurs ",category:'suspension',coords:{x:2000,y:2165},avr:true},
      {"type":"title","label":"Roues et pneumatiques",category:'pneu'},
      {"type":"subtitle","label":"Vérifier (y compris la roue de secours) : "},
      {"type":"query","label":"la pression des pneus ",category:'pneu',coords:{x:2000,y:2390}},
      {"type":"query","label":"l'équilibrage des roues ",category:'pneu',coords:{x:2000,y:2443}},
      {"type":"query","label":"la géométrie du train avant",category:'pneu',coords:{x:2000,y:2496}},
      {"type":"subtitle","label":"Vérifier l'absence de traces : "},
      {"type":"query","label":"d'usure",category:'pneu',coords:{x:2000,y:2605},avr:true},
      {"type":"query","label":"de détérioration",category:'pneu',coords:{x:2000,y:2657},avr:true},
      {"type":"title","label":"Moyeux "},
      {"type":"query","label":"Vérifier l'absence de jeu ",coords:{x:2000,y:2820}},
      {"type":"title","label":"Direction "},
      {"type":"query","label":"Vérifier le bon fonctionnement ",coords:{x:2000,y:2990}},
      {"type":"title","label":"Freins",category:'frein'},
      {"type":"subtitle","label":"Vérifier : "},
      {"type":"query","label":"le frein à main ",category:'frein',coords:{x:2000,y:3206}},
      {"type":"query","label":"la course de la pédale de freins ",category:'frein',coords:{x:2840,y:1003}},
      {"type":"query","label":"l'ABS ",category:'frein',coords:{x:2840,y:1055}},
      {"type":"query","label":"les plaquettes ",category:'frein',coords:{x:2840,y:1107},avr:true},
      {"type":"query","label":"l'état des garnitures ",category:'frein',coords:{x:2840,y:1159}},
      {"type":"query","label":"l'étanchéité du circuit ",category:'frein',coords:{x:2840,y:1215}},
      {"type":"query","label":"le niveau du liquide ",category:'frein',coords:{x:2840,y:1268}},
      {"type":"query","label":"les disques et tambours ",category:'frein',coords:{x:2840,y:1320},avr:true},
      {"type":"title","label":"Échappement"},
      {"type":"query","label":"Vérifier la ligne d'échappement",coords:{x:2840,y:1490},avr:true},
      {"type":"title","label":"Circuit de carburant",category:'circuit'},
      {"type":"subtitle","label":"Vérifier :"},
      {"type":"query","label":"la pompe à carburant ",category:'circuit',coords:{x:2840,y:1710}},
      {"type":"query","label":"les tuyauteries ",category:'circuit',coords:{x:2840,y:1762}},
      {"type":"query","label":"le réservoir ",category:'circuit',coords:{x:2840,y:1814}},
      {"type":"query","label":"le système d'injection ",category:'circuit',coords:{x:2840,y:1870}},
      {"type":"query","label":"la sonde lambda ",category:'circuit',coords:{x:2840,y:1925}},
      {"type":"query","label":"Contrôler les émissions de CO/CO2",category:'circuit',coords:{x:2840,y:1976}},
      {"type":"title","label":"Électronique",category:'elec'},
      {"type":"subtitle","label":"Vérifier toute l'électronique : "},
      {"type":"query","label":"le système de gestion du moteur ",category:'elec',coords:{x:2840,y:2200}},
      {"type":"query","label":"le système de gestion de la boîte de vitesses",category:'elec',coords:{x:2840,y:2252}},
      {"type":"query","label":"le dispositif alarme antivol",category:'elec',coords:{x:2840,y:2362}},
      {"type":"query","label":"l'immobilisateur",category:'elec',coords:{x:2840,y:2414}},
      {"type":"title","label":"Installations électriques",category:'installation'},
      {"type":"subtitle","label":"Vérifier les équipements et les systèmes électriques : "},
      {"type":"query","label":"les rétroviseurs",category:'installation',coords:{x:2840,y:2692}},
      {"type":"query","label":"les verrouillages des portes",category:'installation',coords:{x:2840,y:2744}},
      {"type":"query","label":"les sièges ",category:'installation',coords:{x:2840,y:2796}},
      {"type":"query","label":"les essuie-glaces ",category:'installation',coords:{x:2840,y:2855},avr:true},
      {"type":"query","label":"les lave-glaces ",category:'installation',coords:{x:2840,y:2905}},
      {"type":"query","label":"la batterie ",category:'installation',coords:{x:2840,y:2960}},
      {"type":"query","label":"l'alternateur ",category:'installation',coords:{x:2840,y:3010}},
      {"type":"query","label":"le démarreur ",category:'installation',coords:{x:2840,y:3070}},
      {"type":"title","label":"Système audio ",category:'audio'},
      {"type":"subtitle","label":"Vérifier : ",coords:{x:3670,y:1000}},
      {"type":"query","label":"son bon fonctionnement ",category:'audio',coords:{x:3670,y:1052}},
      {"type":"query","label":"l'antenne ",category:'audio',coords:{x:3670,y:1104}},
      {"type":"title","label":"Éclairage extérieur "},
      {"type":"query","label":"Vérifier l'ensemble du système d'éclairage extérieur ",coords:{x:3670,y:1325}},
      {"type":"title","label":"Avertisseur"},
      {"type":"query","label":"Vérifier le bon fonctionnement ",coords:{x:3670,y:1495}},
      {"type":"title","label":"Système de refroidissement",category:'refroidissement'},
      {"type":"subtitle","label":"Vérifier :"},
      {"type":"query","label":"le radiateur",category:'refroidissement',coords:{x:3670,y:1698}},
      {"type":"query","label":"les Durits et raccords",category:'refroidissement',coords:{x:3670,y:1755}},
      {"type":"query","label":"la pompe à eau",category:'refroidissement',coords:{x:3670,y:1810}},
      {"type":"query","label":"le ventilateur ",category:'refroidissement',coords:{x:3670,y:1860}},
      {"type":"query","label":"le thermostat ",category:'refroidissement',coords:{x:3670,y:1915}},
      {"type":"query","label":"l'antigel (si nécessaire)",category:'refroidissement',coords:{x:3670,y:1958}},
      {"type":"title","label":"Chauffage",category:'chauffage'},
      {"type":"subtitle","label":"Vérifier :"},
      {"type":"query","label":"le fonctionnement du chauffage ",category:'chauffage',coords:{x:3670,y:2205}},
      {"type":"query","label":"le ventilateur ",category:'chauffage',coords:{x:3670,y:2257}},
      {"type":"query","label":"les Durits ",category:'chauffage',coords:{x:3670,y:2309}},
      {"type":"query","label":"les tuyaux ",category:'chauffage',coords:{x:3670,y:2365}},
      {"type":"query","label":"les raccords ",category:'chauffage',coords:{x:3670,y:2420}},
      {"type":"query","label":"le compresseur ",category:'chauffage',coords:{x:3670,y:2472}},
      {"type":"query","label":"le condensateur de la climatisation ",category:'chauffage',coords:{x:3670,y:2525}},
      {"type":"title","label":"Châssis",category:'chassis'},
      {"type":"subtitle","label":"Vérifier l'absence : "},
      {"type":"query","label":"de détérioration",category:'chassis',coords:{x:3670,y:2750}},
      {"type":"query","label":"de corrosion ",category:'chassis',coords:{x:3670,y:2802}},
      {"type":"query","label":"d'usure ",category:'chassis',coords:{x:3670,y:2854}},
      {"type":"subtitle","label":"Vérifier (le cas échéant) : "},
      {"type":"query","label":"le toit ouvrant ",category:'chassis',coords:{x:3670,y:2964}},
      {"type":"query","label":"la capote ",category:'chassis',coords:{x:3670,y:3016}},
      {"type":"title","label":"Portières",category:'portiere'},
      {"type":"subtitle","label":"Vérifier : "},
      {"type":"query","label":"le bon fonctionnement ",category:'portiere',coords:{x:4525,y:1000}},
      {"type":"query","label":"le maniement aisé des serrures ",category:'portiere',coords:{x:4525,y:1052}},
      {"type":"query","label":"le bon état des charnières ",category:'portiere',coords:{x:4525,y:1104}},
      {"type":"title","label":"Coffre",category:'coffre'},
      {"type":"subtitle","label":"Vérifier : "},
      {"type":"query","label":"le bon fonctionnement ",category:'coffre',coords:{x:4525,y:1320}},
      {"type":"query","label":"le maniement aisé de la serrure ",category:'coffre',coords:{x:4525,y:1372}},
      {"type":"query","label":"le système de retenue d'ouverture du coffre ",category:'coffre',coords:{x:4525,y:1480}},
      {"type":"title","label":"Capot moteur ",category:'capot'},
      {"type":"subtitle","label":"Vérifier : "},
      {"type":"query","label":"le bon fonctionnement ",category:'capot',coords:{x:4525,y:1692}},
      {"type":"query","label":"le maniement aisé du système de déblocage ouvert ",category:'capot',coords:{x:4525,y:1905}},
      {"type":"query","label":"le bon état du système de verrouil-lage ouvert ",category:'capot',coords:{x:4525,y:1796}},
      {"type":"title","label":"Intérieur",category:'interieur'},
      {"type":"subtitle","label":"Vérifier :"},
      {"type":"query","label":"le revêtement intérieur",category:'interieur',coords:{x:4525,y:2123}},
      {"type":"query","label":"les maquettes",category:'interieur',coords:{x:4525,y:2175}},
      {"type":"query","label":"les tapis",category:'interieur',coords:{x:4525,y:2227}},
      {"type":"query","label":"les appuie-tête",category:'interieur',coords:{x:4525,y:2279}},
      {"type":"query","label":"les pare-soleil",category:'interieur',coords:{x:4525,y:2338}},
      {"type":"query","label":"les dispositifs d'ouverture des vitres ",category:'interieur',coords:{x:4525,y:2395}},
      {"type":"query","label":"les ceintures de sécurité ",category:'interieur',coords:{x:4525,y:2442}},
      {"type":"query","label":"les caoutchoucs du pédalier ",category:'interieur',coords:{x:4525,y:2495}},
      {"type":"title","label":"Coffre et trousse à outils",category:'trousse'},
      {"type":"subtitle","label":"Vérifier :"},
      {"type":"query","label":"le revêtement du coffre ",category:'trousse',coords:{x:4525,y:2715}},
      {"type":"query","label":"la trousse à outils (y compris le cric) ",category:'trousse',coords:{x:4525,y:2767}},
      {"type":"title","label":"Contrôle technique"},
      {"type":"query","label":"Pour les véhicules de plus de 4 ans à date de première mise en circulation ",coords:{x:4525,y:2980}},
      {"type":"title","label":"Essai sur route"},
      {"type":"query","label":"Ce véhicule a été préparé conformément au programme ci-dessus",coords:{x:4525,y:3193}}
    ];

    var otherKey = -1;
    var count = 0, num = 0;
    for(var i=0;i<this.data.length;i++){
      if( this.data[i].type === 'query' ) {
        this.data[i].id = count;
        this.data[i].num = num+1;
        if( count === 7 ) {        // cas particulier : question 8 sans numero, donc il faut décaler le numero
          num--;
        }
        num++;
        count++;
      }
      else {
        this.data[i].id = otherKey;
        otherKey--;
      }
    }

    this.data[9].num = '';

    var mode = sessionStorage.getItem('nightMode');
    if( mode === null ) mode = '0';
    this.nightMode = mode;
  }

  getPointsToCheck(lines) {
    let tabPointsToCheck = []
    for (var i = 0; i < lines.length; i++) {
      let oneLine = lines[i];
      if (oneLine.coords !== undefined) {
        if (String(oneLine.checked) === '1' || String(oneLine.checked) === '2' || String(oneLine.checked) === '3' || String(oneLine.checked) === '4' || String(oneLine.checked) === '5') {
          tabPointsToCheck.push(oneLine)
        }
      }
    }
    return tabPointsToCheck;
  }

  getPoints(lines) {
    let points = ''
    for (var i = 0; i < lines.length; i++) {
      let oneLine = lines[i];
      if (oneLine.coords !== undefined) {
        points += (StrDef(oneLine.checked) ? String(oneLine.checked) : '0')
      }
    }
  }

  getImgCQ() {
    return '/opel_cq.jpg';
  }

  getImgSecure() {
    return '/stampyt.png';
  }

  cols() {
    return [
      [0,31],
      [32,63],
      [64,97],
      [98,131],
      [132,162]
    ];
  }

  getNextCol(index,direction) {
    var tab = this.cols();
    var nextCol = 0
    for(var i=0;i<tab.length;i++) {
      for(var c=tab[i][0];c<=tab[i][1]&&c<this.data.length;c++) {
        if( this.data[c].id === index ) {
          nextCol = i;
          break;
        }
      }
    }
    if( direction === 'left' ) {
      nextCol--;
      if( nextCol < 0 ) nextCol = 0;
    }
    else {
      nextCol++;
      if( nextCol>tab.length-1 ) nextCol = tab.length-1;
    }
    // on recherche le premier element de la colonne
    for( i=tab[nextCol][0];i<=tab[nextCol][1]&&i<this.data.length;i++) {
      if( this.data[i].type === 'query' ) {
        return this.data[i].id;
      }
    }
  }

  getColStyle(index) {
    var style = {display:'flex',flex:1,flexDirection:'column',padding:0,paddingLeft:5,paddingRight:5};
    return style;
  }

  getColSeparator(index) {
    return null;
  }

  getColContainerStyle() {
    var mode = sessionStorage.getItem('nightMode');
    if( mode === null ) mode = '0';
    if( mode === '0' ) {
      return {display:'flex',flex:1,flexDirection:'row',padding:5,margin:10,fontSize:'1em'};
    }
    else {
      return {display:'flex',flex:1,flexDirection:'row',padding:5,margin:10,fontSize:'1em',color:'white',backgroundColor:'#222'};
    }
  }

  lines() {
   return this.data;
  }

  renderLine(line,change,avr) {
    switch (line.type) {
      case 'title':
        return(
          <div key={"line"+line.id} style={{}}>
            <b>{line.label}</b>
          </div>
        );
      case 'subtitle':
        return(
          <div key={"line"+line.id} style={{fontSize:'1em'}}>
            {line.label}
          </div>
        );
      case 'query':
        return(
          <CheckLine key={'cl'+line.id} data={line} onChange={change} onAvr={avr} mode='PEUGEOT'/>
        );
      default:
        break;
    }
  }

  renderCanvas() {
    return(
      <div style={{display:'flex'}}>
        <canvas id="canvas" width={0} height={0} style={{backgroundColor:'red'}}></canvas>
      </div>
    )
  }

  generatePdf(lines,footer,callback) {
    console.log("footer",footer);
    var p = localStorage.getItem('version.build');
    if( p == null ) {
      var event = new CustomEvent('logout', {detail:''});
      window.dispatchEvent(event);
      return;
    }
    let backend = sharedAPI();
    let tabPointsToCheck = this.getPointsToCheck(lines);// les points à checker
    console.log("tabPointsToCheck",tabPointsToCheck);
    let points = this.getPoints(lines) // les 100 points
    let srcImgSecure = this.getImgSecure()
    let retour = {}

    let doc_height = 210;  // A4 au format landscap
    let doc_width = 297;
    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");

    var imgSrc = new Image;
    imgSrc.addEventListener('load', async function() {
      canvas.width = imgSrc.width
      canvas.height = imgSrc.height
      ctx.drawImage(imgSrc,0,0,canvas.width,canvas.height);
      ctx.beginPath();

      for (var i = 0; i < tabPointsToCheck.length; i++) {
        let onePointToCHeck = tabPointsToCheck[i];
        console.log('>>> onePointToCHeck.checked',onePointToCHeck.checked);
        if (onePointToCHeck.checked === 1 || onePointToCHeck.checked === '1') { // uniquement coche
          // coche
          let pointA = getPointAPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          let pointB = getPointBPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          let pointC = getPointCPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          ctx.moveTo(pointA.x,pointA.y);
          ctx.lineTo(pointB.x,pointB.y);
          ctx.lineTo(pointC.x,pointC.y);
          ctx.lineWidth = 5;
          ctx.strokeStyle = '#000';
          ctx.stroke();
        } else if (onePointToCHeck.checked == '2') {
          // check
          let pointA = getPointAPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          let pointB = getPointBPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          let pointC = getPointCPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          ctx.moveTo(pointA.x,pointA.y);
          ctx.lineTo(pointB.x,pointB.y);
          ctx.lineTo(pointC.x,pointC.y);
          ctx.lineWidth = 5;
          ctx.strokeStyle = '#000';
          ctx.stroke();
          //R - AV - AR
          //if (onePointToCHeck.coordsReplace !== undefined) {
            ctx.font = "30px Arial";
            ctx.fillText("R", onePointToCHeck.coords.x - 80,onePointToCHeck.coords.y - 65);
          //}

        } else if (onePointToCHeck.checked == '3') {
          // check
          let pointA = getPointAPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          let pointB = getPointBPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          let pointC = getPointCPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          ctx.moveTo(pointA.x,pointA.y);
          ctx.lineTo(pointB.x,pointB.y);
          ctx.lineTo(pointC.x,pointC.y);
          ctx.lineWidth = 5;
          ctx.strokeStyle = '#000';
          ctx.stroke();
          //R - AV - AR
          //if (onePointToCHeck.coordsReplace !== undefined) {
            ctx.font = "30px Arial";
            ctx.fillText("AV", onePointToCHeck.coords.x - 80,onePointToCHeck.coords.y - 65);
          //}

        } else if (onePointToCHeck.checked == '4') {
          // check
          let pointA = getPointAPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          let pointB = getPointBPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          let pointC = getPointCPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          ctx.moveTo(pointA.x,pointA.y);
          ctx.lineTo(pointB.x,pointB.y);
          ctx.lineTo(pointC.x,pointC.y);
          ctx.lineWidth = 5;
          ctx.strokeStyle = '#000';
          ctx.stroke();
          //R - AV - AR
          //if (onePointToCHeck.coordsReplace !== undefined) {
            ctx.font = "30px Arial";
            ctx.fillText("AR", onePointToCHeck.coords.x - 80,onePointToCHeck.coords.y - 65);
          //}

        } else if (onePointToCHeck.checked == '5') {
          // check
          let pointA = getPointAPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          let pointB = getPointBPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          let pointC = getPointCPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          ctx.moveTo(pointA.x,pointA.y);
          ctx.lineTo(pointB.x,pointB.y);
          ctx.lineTo(pointC.x,pointC.y);
          ctx.lineWidth = 5;
          ctx.strokeStyle = '#000';
          ctx.stroke();
          //R - AV - AR
          //if (onePointToCHeck.coordsReplace !== undefined) {
            ctx.font = "30px Arial";
            ctx.fillText("AV-AR", onePointToCHeck.coords.x - 120,onePointToCHeck.coords.y - 65);
          //}

        }
      }

      // on coche le point 97 - first line voir CQ OPEL
      let pointA = getPointAPrime(4535,1755)
      let pointB = getPointBPrime(4535,1755)
      let pointC = getPointCPrime(4535,1755)
      ctx.moveTo(pointA.x,pointA.y);
      ctx.lineTo(pointB.x,pointB.y);
      ctx.lineTo(pointC.x,pointC.y);
      ctx.lineWidth = 5;
      ctx.strokeStyle = '#000';
      ctx.stroke();

      // data form
      let brand = footer.brand
      let model = footer.model
      if (StrDef(footer.model) && footer.model.length > 40) {
        model = footer.model.substring(0,40) + '...';
      }
      let color = footer.color
      let registration = footer.registration
      let stock = StrDef(footer.stock) ? footer.stock : ''
      let release = StrDef(footer.release) ? footer.release : ''
      let km = footer.km
      let vin = footer.vin

      ctx.font = "50px Arial";
      ctx.fillText(brand, 400,450);
      ctx.fillText(model, 400,610);
      ctx.fillText(km, 400,780);
      ctx.fillText(vin, 1465,285);
      ctx.fillText(stock, 1465,460);
      ctx.fillText(registration, 1465,615);
      ctx.fillText(release, 1465,780);
      ctx.fillText(backend.currentUser.name, 2500,285);

      ctx.drawImage(backend.currentUser.signature,2700,400,400,300);
      ctx.drawImage(backend.entityStamp['CLARO'],3590,295,800,400);

      let res_get_safe = await backend.get_safe_data(vin);
      console.log("res_get_safe",res_get_safe);
      if (StrDef(res_get_safe.error) && res_get_safe.error.code === 'VinNotFoundException' ) {
        // on post le coffre fort
        let ocode_data = {}
        ocode_data.operation_code = 'INIT';
        let operation_data = {}
  	    operation_data.vin = vin;
  	    operation_data.registration = registration;
  	    operation_data.brand = brand;
  	    operation_data.model = model;
  	    operation_data.color = color; // TODO
        ocode_data.operation_data = operation_data;
        let res_post_safe = await backend.create_safe(vin,ocode_data);
        console.log("res_post_safe",res_post_safe);
        // on re-GET pour obtenir le row_hash ainsi que le ocode_id (nécessaire à la création du cq)
        res_get_safe = await backend.get_safe_data(vin);
        console.log("res_get_safe",res_get_safe);

      } else if (StrDef(res_get_safe.error)) {
        retour.error = true;
        window.notify(i18n.t('all.recordImpossible'),'danger');
        callback(retour)
      }

      let jsPDFOpts = {
        orientation: 'landscape',
        //putOnlyUsedFonts : true,
        //unit: 'mm',
        //format: [doc_width, doc_height],
        compress: true
      };
      let ocode_id = res_get_safe.ocode_id;

      // on demande un ocode_id pour l'attribuer au CQ
      let ocar_ocode_id = {}
      ocar_ocode_id.ocar_ocode_id = ocode_id
      let cq_ocode_data = await backend.get_ocode_cq(ocar_ocode_id);

      let chaine = StringifyOCode(cq_ocode_data.ocode_id)
      ctx.closePath();
      ctx = DrawOCode(chaine,ctx,3400,100)
      var imgSecure = new Image;
      imgSecure.addEventListener('load', async function() {
        ctx.drawImage(imgSecure,3305,20,320,320);
        const img = canvas.toDataURL('image/jpeg',0.9)
        const pdf = new jsPDF(jsPDFOpts)
        pdf.setFileId(1);
        pdf.setCreationDate(new Date('2010-12-24T00:00:00'));
        pdf.addImage(img, 'JPEG', 0, 0, doc_width+.16, doc_height)
        var blob = pdf.output('blob');//datauristring
        let hash_pdf = await file_hash(blob)
        let name_pdf = uuid()+'.pdf';

        backend.upload_media(name_pdf,blob,(res)=>{
          console.log("res",res);
        });

        let last_row_hash = res_get_safe.row_hash;
        // on ajoute le CQ au coffre fort
        let user = JSON.parse(sessionStorage.getItem('user'));
        let accessToken = user.accessToken;
        let s3_region = getS3Region(accessToken);
        let user_id = getUserId(accessToken);
        let entity_id = getEntityId(accessToken);
        let entity_name = getEntityName(accessToken);
        let media_url = s3_region + '/ocars-documents-'+s3_region + '/' + entity_id + '/' +name_pdf;
        let cq_date = moment().utc().format('YYYY-MM-DD HH:mm:ss')

        let cq_data = {}
        let ocode_data = {}
        let operation_data = {}
        let key100pts = '100pts';
        operation_data.media_url = media_url;
        operation_data.media_hash = hash_pdf;
        operation_data[key100pts]= points;
        operation_data.operation_ocode_id = cq_ocode_data.ocode_id;
        operation_data.operation_date = cq_date;
        operation_data.operation_user_id = user_id;
        operation_data.operation_entity_id =entity_id;
        operation_data.operation_entity_name =entity_name;
        ocode_data.operation_code = 'CQ';
        ocode_data.operation_data = operation_data;
        cq_data.ocode_id = ocode_id
        cq_data.ocode_data = ocode_data
        cq_data.last_row_hash = last_row_hash
        let resCreateCQ = await backend.create_safe_cq(cq_data,cq_date);
        if (resCreateCQ.error != undefined) {
          retour.error = true;
          window.notify(i18n.t('all.recordImpossible'),'danger');
          callback(retour)
        }
        callback(retour)
        //pdf.save('ocode_'+ vin +'.pdf')
      })
      imgSecure.src = srcImgSecure;
      /*var blob = pdf.output('blob');
      console.log('blob',blob);
      var stream = blob.stream();
      console.log("stream",stream);
      var url = URL.createObjectURL(blob);
      console.log("url",url);*/
      //pdf.save('your-filename.pdf')
    });
    imgSrc.src = this.getImgCQ();
  }

  renderFooter(data,change,sign) {
    return(
      <FormFooterClaro data={data} stamp={window.API.entityStamp['CLARO'].src} onChange={change} sign={sign}/>
    );
  }
}
