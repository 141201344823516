import React from "react";
import i18n from "../assets/lib/i18n";
import {StrDef,file_hash,getS3Region,getUserId,getEntityId,getEntityName,StringifyOCode,DrawOCode} from '../assets/lib/utils';
import CheckLine from '../components/Input/CheckLine.jsx';
import FormFooter from '../components/Footer/FormFooter.jsx';
import jsPDF from 'jspdf'
import {sharedAPI} from '../assets/lib/api';
var moment = require('moment');
var uuid = require('uuid/v4');

function getPointA(x,y) {
  let point = {}
  point.x = x-30
  point.y = y-100
  return point;
}
function getPointB(x,y) {
  let point = {}
  point.x = x+25-30
  point.y = y+25-100
  return point;
}
function getPointC(x,y) {
  let point = {}
  point.x = x+75-30
  point.y = y-25-100
  return point;
}
function getPointAPrime(x,y) {
  let point = {}
  point.x = x-30
  point.y = y-100
  return point;
}
function getPointBPrime(x,y) {
  let point = {}
  point.x = x+15-30
  point.y = y+15-100
  return point;
}
function getPointCPrime(x,y) {
  let point = {}
  point.x = x+45-30
  point.y = y-15-100
  return point;
}
export default class Peugeot {
  constructor() {
    this.category = ['dynamique','roue','pneumatique','frein','moteur','echappement','direction','boite','embrayage','filtre','carrosserie','porte','train','chauffage','interieur','batterie','intrumentation','essuie-glace','eclairage','elec','campagne','revision']
    this.entete = [{key:'roue',value:'dynamique'},{key:'pneumatique',value:'dynamique'},{key:'frein',value:'dynamique'},
                   {key:'echappement',value:'moteur'},{key:'direction',value:'moteur'},{key:'boite',value:'moteur'},{key:'embrayage',value:'moteur'},{key:'filtre',value:'moteur'},
                   {key:'carrosserie',value:'tract'},{key:'porte',value:'tract'},{key:'train',value:'tract'},
                   {key:'chauffage',value:'habitacle'},{key:'interieur',value:'habitacle'},
                   {key:'batterie',value:'syst'},{key:'intrumentation',value:'syst'},{key:'essuie-glace',value:'syst'},{key:'eclairage',value:'syst'},{key:'elec',value:'syst'}]
    this.data = [
      {"type":"title","label":"ELEMENTS DYNAMIQUES",category:'dynamique',isToCheck:true,coords:{x:1180,y:656}},
      {"type":"query","label":"Performances moteur/essai routier",category:'dynamique',coords:{x:1250,y:640}},
      {"type":"query","label":"Tenue de route (tenue de cap, braquage, suspension)",category:'dynamique',coords:{x:1250,y:695}},
      {"type":"query","label":"Ralenti (CO-CO2 pour moteurs essence)",category:'dynamique',coords:{x:1250,y:750}},
      {"type":"query","label":"Etat des bougies",category:'dynamique',coords:{x:1250,y:805}},
      {"type":"query","label":"Fonctionnement démarreur",category:'dynamique',coords:{x:1250,y:860}},
      {"type":"subtitle","label":"Roues",category:'roue',isToCheck:true,coords:{x:1190,y:995}},
      {"type":"query","label":"Etat",category:'roue',coords:{x:1250,y:980}},
      {"type":"query","label":"Voile",category:'roue',coords:{x:1250,y:1035}},
      {"type":"query","label":"Serrage",category:'roue',coords:{x:1250,y:1090}},
      {"type":"query","label":"Equitibrage",category:'roue',coords:{x:1250,y:1145}},
      {"type":"query","label":"Etat et fixation de la roue de secours",category:'roue',coords:{x:1250,y:1200}},
      {"type":"query","label":"Cric, manivelle et points de levage de cric",category:'roue',coords:{x:1250,y:1255}},
      {"type":"subtitle","label":"Pneumatiques",category:'pneumatique',isToCheck:true,coords:{x:1190,y:1385}},
      {"type":"query","label":"Etat AV",category:'pneumatique',coords:{x:1250,y:1370}},
      {"type":"query","label":"Etat AR",category:'pneumatique',coords:{x:1250,y:1425}},
      {"type":"query","label":"Pression",category:'pneumatique',coords:{x:1250,y:1480}},
      {"type":"subtitle","label":"Freins",category:'frein',isToCheck:true,coords:{x:1190,y:1620}},
      {"type":"query","label":"Etat canalisations et flexibles de freins",category:'frein',coords:{x:1250,y:1605},avr:true},
      {"type":"query","label":"Maître cendre, freins, amplificateur (dispositif assistance de freinage)",category:'frein',coords:{x:1250,y:1660}},
      {"type":"query","label":"Garnitures (épaisseur) et étanchéité circuit hydraulique",category:'frein',coords:{x:1250,y:1715}},
      {"type":"query","label":"Disques",category:'frein',coords:{x:1250,y:1770},avr:true},
      {"type":"query","label":"Plaquettes",category:'frein',coords:{x:1250,y:1825},avr:true},
      {"type":"query","label":"Tambours",category:'frein',coords:{x:1250,y:1880}},
      {"type":"query","label":"Liquide de frein",category:'frein',coords:{x:1250,y:1935}},
      {"type":"query","label":"Contrôle frein de parking/frein à main",category:'frein',coords:{x:1250,y:1990}},
      {"type":"query","label":"ABS (témoin de fonctionnement)",category:'frein',coords:{x:1250,y:2045}},
      {"type":"query","label":"Efficacité et répartition du freinage",category:'frein',coords:{x:1250,y:2100}},
      {"type":"title","label":"MOTEUR, BOITE DE VITESSES ET ECHAPPEMENT",category:'moteur',isToCheck:true,coords:{x:1190,y:2310}},
      {"type":"query","label":"Etanchéité des organes mécaniques (moteur, BV, pont, amortisseurs)",category:'moteur',coords:{x:1250,y:2305}},
      {"type":"query","label":"Courroie de distribution",category:'moteur',coords:{x:1250,y:2360}},
      {"type":"query","label":"Courroie d'alternateur",category:'moteur',coords:{x:2250,y:580}},
      {"type":"query","label":"OPERATIONS OBLIGATOIRES : Vidange huile moteur, échange de filtre à huile",category:'moteur',coords:{x:2250,y:670}},
      {"type":"query","label":"Etat durits",category:'moteur',coords:{x:2250,y:725}},
      {"type":"query","label":"Contrôle niveau huile pont",category:'moteur',coords:{x:2250,y:780}},
      {"type":"query","label":"Niveau liquide de refroidissement",category:'moteur',coords:{x:2250,y:835}},
      {"type":"subtitle","label":"Echappement",category:'echappement',isToCheck:true,coords:{x:2195,y:965}},
      {"type":"query","label":"Silencieux",category:'echappement',coords:{x:2250,y:950},avr:true},
      {"type":"query","label":"Tuyauterie",category:'echappement',coords:{x:2250,y:1005}},
      {"type":"subtitle","label":"Direction",category:'direction',isToCheck:true,coords:{x:2195,y:1140}},
      {"type":"query","label":"Direction assistée - fonctionnement",category:'direction',coords:{x:2250,y:1125}},
      {"type":"query","label":"Direction assistée - niveau huile",category:'direction',coords:{x:2250,y:1180}},
      {"type":"subtitle","label":"Boîte de vitesses",category:'boite',isToCheck:true,coords:{x:2195,y:1310}},
      {"type":"query","label":"Niveau boite de vitesses",category:'boite',coords:{x:2250,y:1300}},
      {"type":"query","label":"Boîte de vitesses sélecteur de commande",category:'boite',coords:{x:2250,y:1355}},
      {"type":"subtitle","label":"Embrayage",category:'embrayage',isToCheck:true,coords:{x:2195,y:1485}},
      {"type":"query","label":"Embrayage - garde",category:'embrayage',coords:{x:2250,y:1475}},
      {"type":"query","label":"Embrayage - fonctionnement et passage de vitesses",category:'embrayage',coords:{x:2250,y:1530}},
      {"type":"query","label":"Embrayage - commande hydraulique",category:'embrayage',coords:{x:2250,y:1585}},
      {"type":"query","label":"Interdiction démarrage vitesse engagée sur BVA",category:'embrayage',coords:{x:2250,y:1640}},
      {"type":"subtitle","label":"Filtre",category:'filtre',isToCheck:true,coords:{x:2195,y:1765}},
      {"type":"query","label":"Filtre à air",category:'filtre',coords:{x:2250,y:1755}},
      {"type":"query","label":"Filtre a pollen",category:'filtre',coords:{x:2250,y:1810}},
      {"type":"query","label":"Filtre à essence - Filtre à gasoil.",category:'filtre',coords:{x:2250,y:1865}},
      {"type":"title","label":"CARROSSERIE/TRACTION",category:'tract',isToCheck:true,coords:{x:2195,y:2005}},
      {"type":"subtitle","label":"Carrosserie",category:'carrosserie',isToCheck:true,coords:{x:2195,y:2085}},
      {"type":"query","label":"Etat général de la carrosserie",category:'carrosserie',coords:{x:2250,y:2075}},
      {"type":"query","label":"Châssis",category:'carrosserie',coords:{x:2250,y:2130}},
      {"type":"query","label":"Plancher",category:'carrosserie',coords:{x:2250,y:2185}},
      {"type":"query","label":"Berceau",category:'carrosserie',coords:{x:2250,y:2240}},
      {"type":"query","label":"Fixation pare chocs AV/AR",category:'carrosserie',coords:{x:2250,y:2295},avr:true},
      {"type":"query","label":"Liaisons élastiques (articulations, silentblocs)",category:'carrosserie',coords:{x:2250,y:2350}},
      {"type":"query","label":"Etat général des supports de fixation des éléments mécaniques",category:'carrosserie',coords:{x:2250,y:2405}},
      {"type":"query","label":"Attache remorque",category:'carrosserie',coords:{x:3250,y:570}},
      {"type":"query","label":"Etat réservoir et canalisations",category:'carrosserie',coords:{x:3250,y:625}},
      {"type":"subtitle","label":"Portes",category:'porte',isToCheck:true,coords:{x:3200,y:760}},
      {"type":"query","label":"Barillets",category:'porte',coords:{x:3250,y:750}},
      {"type":"query","label":"Serrures",category:'porte',coords:{x:3250,y:805},avr:true},
      {"type":"query","label":"Articulations et gâches des portes",category:'porte',coords:{x:3250,y:860}},
      {"type":"subtitle","label":"Trains AV/AR",category:'train',isToCheck:true,coords:{x:3200,y:990}},
      {"type":"query","label":"Géométrie des trains AV/AR",category:'train',coords:{x:3250,y:980},avr:true},
      {"type":"query","label":"Fixation trains AV/AR",category:'train',coords:{x:3250,y:1035},avr:true},
      {"type":"query","label":"Fixation ressorts et amortisseurs",category:'train',coords:{x:3250,y:1090},avr:true},
      {"type":"query","label":"Transmission trains AV/AR",category:'train',coords:{x:3250,y:1145},avr:true},
      {"type":"query","label":"Soufflets de transmission trains AV/AR",category:'train',coords:{x:3250,y:1200},avr:true},
      {"type":"query","label":"Triangles de suspension trains AV/AR",category:'train',coords:{x:3250,y:1255},avr:true},
      {"type":"query","label":"Etats rotules et biellettes train roulant",category:'train',coords:{x:3250,y:1310},avr:true},
      {"type":"query","label":"Amortisseurs AV/AR.",category:'train',coords:{x:3250,y:1365},avr:true},
      {"type":"title","label":"HABITACLE",category:'habitacle',isToCheck:true,coords:{x:3195,y:1520}},
      {"type":"subtitle","label":"Chauffage (fonctionnement)",category:'chauffage',isToCheck:true,coords:{x:3200,y:1595}},
      {"type":"query","label":"Chauffage ventilateur et commandes",category:'chauffage',coords:{x:3250,y:1585}},
      {"type":"query","label":"Climatisation (suivant équipement)",category:'chauffage',coords:{x:3250,y:1640}},
      {"type":"subtitle","label":"Intérieur",category:'interieur',isToCheck:true,coords:{x:3200,y:1775}},
      {"type":"query","label":"Ceintures",category:'interieur',coords:{x:3250,y:1760}},
      {"type":"query","label":"Tapis AV/AR (suivant équipement)",category:'interieur',coords:{x:3250,y:1815}},
      {"type":"query","label":"Fixation sièges et banquettes",category:'interieur',coords:{x:3250,y:1870}},
      {"type":"query","label":"Fixation et état des ceintures de sécurité",category:'interieur',coords:{x:3250,y:1925}},
      {"type":"query","label":"Présence du triangle de présignalisation (suivant équipement)",category:'interieur',coords:{x:3250,y:1980}},
      {"type":"query","label":"Pare brise",category:'interieur',coords:{x:3250,y:2035}},
      {"type":"query","label":"Lève vitres (fonctionnement)",category:'interieur',coords:{x:3250,y:2090}},
      {"type":"query","label":"Rétroviseur (fonctionnement de la commande)",category:'interieur',coords:{x:3250,y:2145}},
      {"type":"query","label":"Clés (fonctionnement plip), double des clés et carte code",category:'interieur',coords:{x:3250,y:2200}},
      {"type":"query","label":"Identification véhicule (n° châssis, immatriculation, frappe à froid)",category:'interieur',coords:{x:3250,y:2255}},
      {"type":"query","label":"Horloge (fonctionnement)",category:'interieur',coords:{x:3250,y:2310}},
      {"type":"title","label":"SYSTEMES ELECTRIQUES",category:'syst',isToCheck:true,coords:{x:4190,y:640}},
      {"type":"subtitle","label":"Batterie",category:'batterie',isToCheck:true,coords:{x:4200,y:715}},
      {"type":"query","label":"Batterie - état",category:'batterie',coords:{x:4250,y:705}},
      {"type":"query","label":"Batterie - fixation",category:'batterie',coords:{x:4250,y:760}},
      {"type":"subtitle","label":"Instrumentation",category:'intrumentation',isToCheck:true,coords:{x:4200,y:890}},
      {"type":"query","label":"Lunette AR (fonctionnement)",category:'intrumentation',coords:{x:4250,y:880}},
      {"type":"query","label":"Radio (fonctionnement)",category:'intrumentation',coords:{x:4250,y:935}},
      {"type":"query","label":"Indicateurs tableau de bord",category:'intrumentation',coords:{x:4250,y:990}},
      {"type":"query","label":"Aide à la navigation (fonctionnement)",category:'intrumentation',coords:{x:4250,y:1045}},
      {"type":"query","label":"Avertisseurs sonores (fonctionnement)",category:'intrumentation',coords:{x:4250,y:1100}},
      {"type":"query","label":"Allume-cigare (fonctionnement)",category:'intrumentation',coords:{x:4250,y:1155}},
      {"type":"query","label":"Toit ouvrant (fonctionnement, suivant équipement)",category:'intrumentation',coords:{x:4250,y:1210}},
      {"type":"subtitle","label":"Essuies-glaces",category:'essuie-glace',isToCheck:true,coords:{x:4200,y:1335}},
      {"type":"query","label":"Essuie glace AV - fonctionnement et état des raclettes",category:'essuie-glace',coords:{x:4250,y:1330}},
      {"type":"query","label":"Essuie glace AR - fonctionnement et état des raclettes",category:'essuie-glace',coords:{x:4250,y:1385}},
      {"type":"query","label":"Essuie glace - niveau lave-glace",category:'essuie-glace',coords:{x:4250,y:1440}},
      {"type":"subtitle","label":"Eclairage (fonctionnement)",category:'eclairage',isToCheck:true,coords:{x:4200,y:1565}},
      {"type":"query","label":"Veilleuses - Feux de croisement",category:'eclairage',coords:{x:4250,y:1560}},
      {"type":"query","label":"Clignotants - Feux de détresse",category:'eclairage',coords:{x:4250,y:1615}},
      {"type":"query","label":"Feux stop, de recul et AR de brouillard",category:'eclairage',coords:{x:4250,y:1670}},
      {"type":"query","label":"Anti brouillard/longue portée AV",category:'eclairage',coords:{x:4250,y:1725}},
      {"type":"query","label":"Réglage des projecteurs (éclairage)",category:'eclairage',coords:{x:4250,y:1780}},
      {"type":"query","label":"Plaque de police",category:'eclairage',coords:{x:4250,y:1835}},
      {"type":"query","label":"Eclairage intérieur",category:'eclairage',coords:{x:4250,y:1890}},
      {"type":"subtitle","label":"Etectronique embarquée (si véhicule Peugeot)",category:'elec',isToCheck:true,coords:{x:4200,y:2010}},
      {"type":"query","label":"Lecture codes défauts",category:'elec',coords:{x:4250,y:2005}},
      {"type":"query","label":"Test globat/Réinitialisation des calculateurs",category:'elec',coords:{x:4250,y:2060}},
      {"type":"title","label":"CAMPAGNES",category:'campagne',isToCheck:true,coords:{x:4200,y:2215}},
      {"type":"query","label":"Réalisation campagnes de modernisation et sécurité",category:'campagne',coords:{x:4250,y:2215}},
      {"type":"title","label":"REVISIONS",category:'revision',isToCheck:true,coords:{x:4200,y:2380}},
      {"type":"query","label":"Révisions et contrôles (suivant année et kilométrage)",category:'revision',coords:{x:4250,y:2375}},
    ];

    var otherKey = -1;
    var count = 0;
    for(var i=0;i<this.data.length;i++){
      if( this.data[i].type === 'query' ) {
        this.data[i].id = count;
        this.data[i].num = count+1;
        count++;
      }
      else {
        this.data[i].id = otherKey;
        otherKey--;
      }
    }
  }

  getPointsToCheck(lines) {
    let tabPointsToCheck = []
    for (var i = 0; i < this.category.length; i++) {
      let oneCategory = this.category[i]
      let isToCheck = this.isOneChecked(oneCategory,lines)

      // gestion entête
      if (isToCheck) {
        let onePointToCheck = this.getPointToCheck(oneCategory)
        tabPointsToCheck = tabPointsToCheck.concat(onePointToCheck)

        let oneEnteteToCheck = this.getEnteteToCheck(oneCategory,lines)
        tabPointsToCheck = tabPointsToCheck.concat(oneEnteteToCheck)

        // on fait les remplacés AV AR
        let otherPointToCheck = this.getOtherPointToCheck(oneCategory,lines)
        tabPointsToCheck = tabPointsToCheck.concat(otherPointToCheck)
      }
    }
    return tabPointsToCheck;
  }

  isOneChecked(category,lines){
    //console.log("isOneChecked",category);
    let found = lines.filter(oneData=>oneData.category === category && !oneData.isToCheck)
    let isFound = false;
    for (var i = 0; i < found.length; i++) {
      let oneFound = found[i];
      if (oneFound.checked === '1' || oneFound.checked === 1 || oneFound.checked == '2' || oneFound.checked == '3' || oneFound.checked == '4' || oneFound.checked == '5') {
        isFound = true;
        break;
      }
    }
    return isFound;
    //console.log("found");
  }

  getPointToCheck(category) {
    let pointToCheck = this.data.filter(oneData=>oneData.category === category && oneData.isToCheck === true)
    return pointToCheck;
  }

  getEnteteToCheck(category,lines) {
    let enteteToCheckValue = this.entete.filter(oneData=>oneData.key === category)
    let enteteToCheck = []
    if (enteteToCheckValue.length > 0) {
      enteteToCheck = lines.filter(oneData=>oneData.category === enteteToCheckValue[0].value && oneData.isToCheck===true)
    }
    return enteteToCheck;
  }

  getOtherPointToCheck(category,lines) {
    return lines.filter(oneData=>oneData.category === category && (oneData.checked == '2'||oneData.checked == '3' ||oneData.checked == '4' ||oneData.checked == '5'))
  }

  getPoints(lines) {
    let points = ''
    for (var i = 0; i < lines.length; i++) {
      let oneLine = lines[i];
      if (oneLine.type === 'query') {
        points += (StrDef(oneLine.checked) ? String(oneLine.checked) : '0')
      }
    }
  }

  getImgCQ() {
    return '/peugeot_cq.jpg';
  }

  getImgSecure() {
    return '/stampyt.png';
  }

  cols() {
    return [
      [0,30],
      [31,62],
      [63,93],
      [94,128]
    ];
  }

  getNextCol(index,direction) {
    var tab = this.cols();
    var nextCol = 0
    for(var i=0;i<tab.length;i++) {
      for(var c=tab[i][0];c<=tab[i][1]&&c<this.data.length;c++) {
        if( this.data[c].id === index ) {
          nextCol = i;
          break;
        }
      }
    }
    if( direction === 'left' ) {
      nextCol--;
      if( nextCol < 0 ) nextCol = 0;
    }
    else {
      nextCol++;
      if( nextCol>tab.length-1 ) nextCol = tab.length-1;
    }
    // on recherche le premier element de la colonne
    for( i=tab[nextCol][0];i<=tab[nextCol][1]&&i<this.data.length;i++) {
      if( this.data[i].type === 'query' ) {
        return this.data[i].id;
      }
    }
  }

  getColStyle(index) {
    var style = {display:'flex',flex:1,flexDirection:'column',padding:0,paddingLeft:5,paddingRight:5};
    return style;
  }

  getColSeparator(index) {
    if( index < 3 ) {
      return (
        <div style={{display:'flex',width:2,backgroundColor:'#1f367b'}}>
        </div>
      );
    }
    else {
      return null;
    }
  }

  getColContainerStyle() {
    return {display:'flex',flex:1,flexDirection:'row',padding:5,border:'solid 2px #1f367b',borderRadius:'20px',margin:10};
  }

  lines() {
    return this.data;
  }

  renderCanvas() {
    return(
      <div style={{display:'flex'}}>
        <canvas id="canvas" width={0} height={0} style={{backgroundColor:'red'}}></canvas>
      </div>
    )
  }

  generatePdf(lines,footer,callback) {
    console.log("generatePdfgeneratePdfgeneratePdf");
    var p = localStorage.getItem('version.build');
    if( p == null ) {
      var event = new CustomEvent('logout', {detail:''});
      window.dispatchEvent(event);
      return;
    }

    let backend = sharedAPI();
    let tabPointsToCheck = this.getPointsToCheck(lines);
    let points = this.getPoints(lines) // les 100 points
    let retour = {}
    let srcImgSecure = this.getImgSecure()

    let doc_height = 210;  // A4 au format landscap
    let doc_width = 297;
    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    var imgSrc = new Image;
    imgSrc.addEventListener('load',async function() {
      canvas.width = imgSrc.width
      canvas.height = imgSrc.height

      ctx.drawImage(imgSrc,0,0,canvas.width,canvas.height); // Or at whatever offset you like
      ctx.beginPath();

      for (var i = 0; i < tabPointsToCheck.length; i++) {
        let onePointToCHeck = tabPointsToCheck[i];
        if (onePointToCHeck.type === 'title' || onePointToCHeck.type === 'subtitle') {
          // coche
          let pointA = getPointAPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          let pointB = getPointBPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          let pointC = getPointCPrime(onePointToCHeck.coords.x+10,onePointToCHeck.coords.y+10)
          ctx.moveTo(pointA.x,pointA.y);
          ctx.lineTo(pointB.x,pointB.y);
          ctx.lineTo(pointC.x,pointC.y);
          ctx.lineWidth = 5;
          ctx.strokeStyle = '#000';
          ctx.stroke();
        } else {
          // R - AV - AR
          ctx.font = "30px Arial";
          if (onePointToCHeck.checked == 2) {
            ctx.fillText("R", onePointToCHeck.coords.x,onePointToCHeck.coords.y);
          } else if (onePointToCHeck.checked == 3) {
            ctx.fillText("AV", onePointToCHeck.coords.x,onePointToCHeck.coords.y);
          } else if (onePointToCHeck.checked == 4) {
            ctx.fillText("AR", onePointToCHeck.coords.x,onePointToCHeck.coords.y);
          } else if (onePointToCHeck.checked == 5) {
            ctx.fillText("AV-AR", onePointToCHeck.coords.x,onePointToCHeck.coords.y);
          }

        }
      }

      // data form
      let registration = footer.registration
      let km = footer.km
      let brand = footer.brand
      let color = footer.color
      let model = footer.model
      let real = footer.real
      let guaranty = footer.guaranty
      let vin = footer.vin
      let date = moment().format('DD/MM/YYYY');

      let pointA;
      let pointB;
      let pointC;
      if (real) {
        pointA = getPointA(1280,2840)
        pointB = getPointB(1280,2840)
        pointC = getPointC(1280,2840)
        ctx.moveTo(pointA.x,pointA.y);
        ctx.lineTo(pointB.x,pointB.y);
        ctx.lineTo(pointC.x,pointC.y);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#000';
        ctx.stroke();
      }

      if (guaranty) {
        pointA = getPointA(1525,2840)
        pointB = getPointB(1525,2840)
        pointC = getPointC(1525,2840)
        ctx.moveTo(pointA.x,pointA.y);
        ctx.lineTo(pointB.x,pointB.y);
        ctx.lineTo(pointC.x,pointC.y);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#000';
        ctx.stroke();
      }

      ctx.font = "60px Arial";
      ctx.fillText(registration, 1150,2620);
      ctx.fillText(km, 800,2775);
      ctx.fillText(vin, 850,2970);
      ctx.fillText(date, 2300,2700);
      ctx.fillText(backend.currentUser.name, 2120,2920);

      ctx.drawImage(backend.currentUser.signature,2500,2750,400,300);
      ctx.drawImage(backend.entityStamp['CLARA'],3400,2570,800,450);
      let res_get_safe = await backend.get_safe_data(vin);

      console.log("res_get_safe",res_get_safe);
      if (StrDef(res_get_safe.error) && res_get_safe.error.code === 'VinNotFoundException' ) {
        // on post le coffre fort
        let ocode_data = {}
        ocode_data.operation_code = 'INIT';
        let operation_data = {}
  	    operation_data.vin = vin;
  	    operation_data.registration = registration;
  	    operation_data.brand = brand;
  	    operation_data.model = model;
  	    operation_data.color = color;
        ocode_data.operation_data = operation_data;
        console.log("ocode_data",ocode_data);
        let res_post_safe = await backend.create_safe(vin,ocode_data);
        console.log("res_post_safe",res_post_safe);
        res_get_safe = await backend.get_safe_data(vin);
      } else if (StrDef(res_get_safe.error)) {
        retour.error = true;
        window.notify(i18n.t('all.recordImpossible'),'danger');
        callback(retour)
      }

      let jsPDFOpts = {
        orientation: 'landscape',
        //putOnlyUsedFonts : true,
        //unit: 'mm',
        //format: [doc_width, doc_height],
        compress: true
      };
      let ocode_id = res_get_safe.ocode_id;

      // on demande un ocode_id pour l'attribuer au CQ
      let ocar_ocode_id = {}
      ocar_ocode_id.ocar_ocode_id = ocode_id
      let cq_ocode_data = await backend.get_ocode_cq(ocar_ocode_id);

      let chaine = StringifyOCode(cq_ocode_data.ocode_id)
      ctx.closePath();
      ctx = DrawOCode(chaine,ctx,3400,100)
      var imgSecure = new Image;
      imgSecure.addEventListener('load', async function() {
        ctx.drawImage(imgSecure,3305,20,320,320);
        const img = canvas.toDataURL('image/jpeg',0.9)
        const pdf = new jsPDF(jsPDFOpts)
        pdf.setFileId(1);
        pdf.setCreationDate(new Date('2010-12-24T00:00:00'));
        pdf.addImage(img, 'JPEG', 0, 0, doc_width+.16, doc_height)
        var blob = pdf.output('blob');
        let hash_pdf = await file_hash(blob)
        let name_pdf = uuid()+'.pdf';
        backend.upload_media(name_pdf,blob,(res)=>{
          console.log("res",res);
        });



        let last_row_hash = res_get_safe.row_hash;
        // on ajoute le CQ au coffre fort
        let user = JSON.parse(sessionStorage.getItem('user'));
        let accessToken = user.accessToken;
        let s3_region = getS3Region(accessToken);
        let user_id = getUserId(accessToken);
        let entity_id = getEntityId(accessToken);
        let entity_name = getEntityName(accessToken);
        let media_url = s3_region + '/ocars-documents-'+s3_region + '/' + entity_id + '/' +name_pdf;
        let cq_date = moment().utc().format('YYYY-MM-DD HH:mm:ss')

        let cq_data = {}
        let ocode_data = {}
        let operation_data = {}
        let key100pts = '100pts';
        operation_data.media_url = media_url;
        operation_data.media_hash = hash_pdf;
        operation_data[key100pts]= points;
        operation_data.operation_ocode_id = cq_ocode_data.ocode_id;
        operation_data.operation_date = cq_date;
        operation_data.operation_user_id = user_id;
        operation_data.operation_entity_id =entity_id;
        operation_data.operation_entity_name =entity_name;
        ocode_data.operation_code = 'CQ';
        ocode_data.operation_data = operation_data;
        cq_data.ocode_id = ocode_id
        cq_data.ocode_data = ocode_data
        cq_data.last_row_hash = last_row_hash
        let resCreateCQ = await backend.create_safe_cq(cq_data,cq_date);
        if (StrDef(resCreateCQ.error)) {
          retour.error = true;
          window.notify(i18n.t('all.recordImpossible'),'danger');
          callback(retour)
        }
        callback(retour)
        //pdf.save('your-filename.pdf')
        //pdf.save('ocode_'+ vin +'.pdf')
      })
      imgSecure.src = srcImgSecure;
    });
    imgSrc.src = this.getImgCQ();
  }

  renderLine(line,change,avr) {
    switch (line.type) {
      case 'title':
        return(
          <div key={"line"+line.id} style={{textTransform:'uppercase',textDecoration:'underline'}}>
            <b>{line.label}</b>
          </div>
        );
      case 'subtitle':
        return(
          <div key={"line"+line.id} style={{textTransform:'capitalize'}}>
            <b>{line.label}</b>
          </div>
        );
      case 'query':
        return(
          <CheckLine key={'cl'+line.id} data={line} onChange={change} onAvr={avr} mode='PEUGEOT'/>
        );
      default:
          break;
    }
  }

  renderFooter(data,change,sign) {
    return(
      <FormFooter data={data} stamp={window.API.entityStamp['CLARA'].src} onChange={change} sign={sign}/>
    );
  }
}
