import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import {Button,
        ButtonGroup,
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        Card,
        CardHeader,
        CardBody,
        CardFooter,
        CardTitle,
        Label,
        FormGroup,
        Form,
        Input,
        Row,
        Col,
        Table,
        Container,
        InputGroup,
        InputGroupAddon,
        InputGroupText,
        Tooltip,
        } from 'reactstrap';
import routes from "routes.js";
import {StrDef,Clone} from '../../assets/lib/utils';
import i18n from "../../assets/lib/i18n";
import Icon from '../../components/Icons/Icon.jsx';
import {sharedAPI} from "../../assets/lib/api";
import NotificationAlert from "../../components/Notification/Notification.jsx";
import Select from "react-select";
import SignatureCanvas from 'react-signature-canvas';
import {Page,AvatarRender} from '../../components/UI/UI.jsx';
import lottie from 'lottie-web';

import logo from "../../assets/img/logo.png";
import plate from "../../assets/img/plate.png";
import avatarM from "../../assets/img/avatar_m.png";
import avatarF from "../../assets/img/avatar_f.png";

var moment = require('moment');

var ps;

const LOTTIE_FILE = `${process.env.PUBLIC_URL}/lottie/loading_dubreuil.json`;


class Login extends Page {
  constructor(props) {
    super(props);

    this.NotifClose = this.NotifClose.bind(this);
    this.notify = this.notify.bind(this);
    this.AvatarChoose = this.AvatarChoose.bind(this);
    this.StepPrev = this.StepPrev.bind(this);
    this.Login = this.Login.bind(this);
    this.KeyPressed = this.KeyPressed.bind(this);
    this.Validate = this.Validate.bind(this);
    this.Sync = this.Sync.bind(this);

    this.ComeFrom = '';

    this.state = {
      users:[],
      step:0,
      login:'',
      password:'',
      plate:'',
      plates:[],
      platesSelected:0,
      selectPlateOpen:false,
      vin:'',
      vins:[],
      vinsSelected:0,
      selectVinOpen:false,
    };

    if( window.location.hostname.indexOf('localhost')!=-1
        || window.location.hostname.indexOf('192.168.')!=-1 ) {
      this.state.login = '';
      this.state.password = '';
    }

    var tmp = window.location.pathname.split('/');
    if( !isNaN(tmp[tmp.length-1]) ) {
      localStorage.setItem('entityId',tmp[tmp.length-1]);
    }
    window.API.entityId = localStorage.getItem('entityId');

    window.notify = this.notify;
  }

  notify(msg,type,autoDismiss) {
    var options = { message: msg, type: type };
    if( StrDef(autoDismiss) ) options['autoDismiss'] = autoDismiss;
    return this.refs.notificationAlert.notificationAlert(options);
  }

  componentDidMount() {
    // on regarde si on ne vient pas de se faire "logouter", par consequent il faut rediriger vers d'ou on vient apres le login
    if( StrDef(this.props.location) && StrDef(this.props.location.state) && StrDef(this.props.location.state.from) ) {
      this.ComeFrom = this.props.location.state.from.pathname+this.props.location.state.from.search;
      this.setState({modalLogin:true});
    }
    else {
      this.ComeFrom = '';
    }
    document.querySelector('body').addEventListener('onKeyUp',this.KeyPressed,false);

    // on charge les data
    window.API.userGetLocal(this.LoadUserList.bind(this));

    window.addEventListener('syncStock',this.Sync,false);
  }

  componentWillUnmount() {
    document.querySelector('body').removeEventListener('onKeyUp',this.KeyPressed,false);
    window.removeEventListener('syncStock',this.Sync,false);
  }

  Sync(data) {
    this.setState({syncing:data.detail=='start'});
  }

  KeyPressed(event) {
    var self = this;
    if( this.state.step == 2 ) {
      if (event.key === "Enter") {
         this.Login();
      }
    }
    else if( this.state.step == 3 && this.focus == 'plate' ) {
      switch (event.key) {
        case 'ArrowRight':
        case 'ArrowDown':
          var tmp = this.state.platesSelected+1;
          tmp = Math.min(tmp,this.state.plates.length-1);
          this.setState({platesSelected:tmp});
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
          var tmp = this.state.platesSelected-1;
          tmp = Math.max(tmp,0);
          this.setState({platesSelected:tmp});
          break;
        case 'Enter':
          var plate = this.state.plate;
          if( StrDef(this.state.plates[this.state.platesSelected]) ) plate = this.state.plates[this.state.platesSelected].label;
          this.setState({selectPlateOpen:false,plate:plate},()=>{
            setTimeout(()=>{ this.Validate() },400);
          });
          break;
      }
    }
    else if( this.state.step == 3 && this.focus == 'vin' ) {
      switch (event.key) {
        case 'ArrowRight':
        case 'ArrowDown':
          var tmp = this.state.vinsSelected+1;
          tmp = Math.min(tmp,this.state.vins.length-1);
          this.setState({vinsSelected:tmp});
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
          var tmp = this.state.vinsSelected-1;
          tmp = Math.max(tmp,0);
          this.setState({vinsSelected:tmp});
          break;
        case 'Enter':
          var vin = this.state.vin;
          if( StrDef(this.state.vins[this.state.vinsSelected]) ) vin = this.state.vins[this.state.vinsSelected].label;
          this.setState({selectVinOpen:false,vin:vin},()=>{
            setTimeout(()=>{ this.Validate() },400);
          });
          break;
      }
    }

  }

  Change(what,t) {
    var tmp = {};
    tmp[what] = t.target.value;

    if( what == 'plate' ) {
      // var plate = t.target.value.replace(/-/g,'').toUpperCase();
      // var pre='', mid='', suf='';
      // if( plate.length > 0 ) pre = plate.substring(0,2);
      // if( plate.length > 2 ) mid = plate.substring(2,5);
      // if( plate.length > 5 ) suf = plate.substring(5,7);
      // if( isNaN(mid) ) {
      //   mid = ''; suf = '';
      // }
      // if( ! /^[A-Z]+$/.test(suf) ) suf = '';
      // tmp[what] = pre+(StrDef(mid)?'-'+mid:'')+(StrDef(suf)?'-'+suf:'');
      tmp[what] = t.target.value.toUpperCase();
      tmp.selectPlateOpen = true;

      var tab = [];
      if( tmp[what].length > 0 ) {
        var res = window.synchro.registration.filter((reg)=>reg.startsWith(tmp[what]));
        for(var i=0;i<res.length&&i<4;i++) {
          tab.push({value:window.synchro.registrationToVin[res[i]],label:res[i]});
        }
      }
      tmp.plates = tab;
    }

    if( what == 'vin' ) {
      var vin = t.target.value.replace(/-/g,'').toUpperCase();
      tmp[what] = vin;
      tmp.selectVinOpen = true;

      var tab = [];
      if( tmp[what].length > 0 ) {
        var res = window.synchro.vis.filter((reg)=>reg.startsWith(tmp[what]));
        for(var i=0;i<res.length&&i<4;i++) {
          tab.push({value:window.synchro.visToVin[res[i]],label:res[i]});
        }
      }
      tmp.vins = tab;
    }
    this.setState(tmp);
  }

  NotifClose() {
    this.refs.notificationAlert.onDismiss(this.notifRes.nNumber,this.notifRes.place);
  }

  AvatarChoose(data,i) {
    window.API.setCurrentUser( this.state.users[i] );
    this.setState({ login:this.state.users[i].user_name,
                    password:'00000000',
                    currentUser:this.state.users[i]
                  },
                  ()=>{
                    this.Login();
                  });

    // sessionStorage.setItem('isAuth','1');
    // sessionStorage.setItem('currentRole','TEC');
    // sessionStorage.setItem('account',JSON.stringify(this.state.users[i]));
    // this.setState({currentUser:this.state.users[i],step:3});
  }

  StepPrev() {
    if( this.state.step == 3 ) {
      var event = new CustomEvent('logout', {detail:''});
      window.dispatchEvent(event);
      this.setState({ step:1,
                      plate:'',
                      plates:[],
                      platesSelected:0,
                      selectPlateOpen:false,
                      vin:'',
                      vins:[],
                      vinsSelected:0,
                      selectVinOpen:false});
    }
    else {
      this.setState({step:this.state.step>0?this.state.step-1:0});
    }
  }

  Login() {
    this.Submitting(true);

    // lottie.loadAnimation({
    //   container: document.querySelector('#anim'), // the dom element that will contain the animation
    //   renderer: 'svg',
    //   loop: true,
    //   autoplay: true,
    //   path: LOTTIE_FILE // the path to the animation json
    // });


    // appel signin chez momo
    window.API.signin(this.state.login,this.state.password,(res)=>{
      if( StrDef(res.error) ) {
        if( StrDef(res.error.message) && res.error.message=="account_id (MISSING): Field is required" ) {
          window.notify(i18n.t('Login.errorEntity'),'danger');
        }
        else {
          window.notify(i18n.t('Login.error'),'danger');
        }
        this.Submitting(false);
      }
      else {
        sessionStorage.setItem('isAuth','1');
        sessionStorage.setItem('currentRole',res.user_permission==0?'ADM':'TEC');
        if( res.user_permission == 0 ) {
          this.props.history.push('/labo/admin');
        }
        else {
          this.setState({step:3});
        }
        this.Submitting(false);
      }
    });


  }

  LoadUserList(res) {
    if( res.length > 0 ) {
      this.setState({users:res,step:1});
    }
    else {
      this.setState({step:2});
    }
  }

  Log(data) {
    data.entityId = localStorage.getItem('entityId');
    var request = new XMLHttpRequest();
    request.open('POST', 'https://jsonbox.io/box_fcb1e4d9b84c118b5fe4', true);
    request.setRequestHeader('Content-Type','application/json');
    request.send(JSON.stringify(data));
  }

  APIPlate(plate,callback) {
    var self = this;
    var token="demoxM";
    var url = "https://api.apiplaqueimmatriculation.com/carte-grise?immatriculation="+plate+"&token="+token+"&format=json";
    var request = new XMLHttpRequest();
    request.open('POST', url, true);
    request.setRequestHeader('Accept', 'application/json');
    request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        try {
          var data = JSON.parse(request.responseText);
          callback(data);
          self.Log({plate:plate,foundByAPI:true});
        }
        catch(e){
          console.log(e);
          callback({error:'unknown'});
          self.Log({plate:plate,foundByAPI:'err1'});
        }
      } else {
        callback({error:'unknown'});
        self.Log({plate:plate,foundByAPI:'err2'});
      }
    };
    request.onerror = function() {
      callback({error:'network error'});
      self.Log({plate:plate,foundByAPI:'err3'});
    };
    request.send(null);
  }

  Validate() {
    var id = '';
    var car = window.synchro.BDD[id];

    if( StrDef(this.state.vin) ) {  // recherche par vis/vin
      id = this.state.vin;
      car = window.synchro.BDD[window.synchro.visToVin[id]];
    }
    else {
      id = this.state.plate;
      car = window.synchro.BDD[window.synchro.registrationToVin[id]];
    }

    if (!StrDef(car) && !StrDef(this.state.vin)) {
      if( localStorage.getItem('plateAPI') != null ) {
        // on va chercher sur une api voir si vehicule existe
        this.APIPlate(this.state.plate,(data)=>{
          if( StrDef(data) && StrDef(data.data) && StrDef(data.data.vin) ) {
            car = data.data;
            window.synchro.BDD[car.vin] = {company:'CLARO', // en dur car on s'en fiche
                                            vin:car.vin,
                                            brand:car.marque,
                                            model:car.modele,
                                            registration:this.state.plate};
            window.synchro.registrationToVin[this.state.plate] = car.vin;
            window.synchro.registration.push( this.state.plate );
            window.synchro.vis.push( car.vin.substring(9,17) );
            window.synchro.visToVin[car.vin.substring(9,17)] = car.vin;
            this.props.history.push('/labo/form/'+data.data.vin);
          }
          else {
            this.Log({plate:this.state.plate,foundByAPI:'err4'});
            window.notify(i18n.t('Véhicule inconnu, veuillez saisir le VIN'),'danger')
          }
        });
      }
      else {
        this.Log({plate:this.state.plate,foundByAPI:'noAPI'});
        window.notify(i18n.t('Véhicule inconnu, veuillez saisir le VIN'),'danger')
      }
      return;
    }

    if( !StrDef(car) ) {
      if( StrDef(this.state.vin) && this.state.vin.length != 17 ) {
        window.notify(i18n.t('Login.errorVIN'),'danger')
      }
      else {
        this.props.history.push('/labo/form/'+id);
      }
    }
    else {
      this.props.history.push('/labo/form/'+car.vin);
    }
  }

  Focus(what) {
    this.focus = what;
    if( what == 'vin' ) {
      this.setState({plates:[],selectPlateOpen:false,selectVinOpen:true});
    }
    if( what == 'plate' ) {
      this.setState({vins:[],selectPlateOpen:true,selectVinOpen:false});
    }
  }

  render() {

    return (
      <>
        <NotificationAlert ref="notificationAlert"/>
        <Container className="loginWindow" fluid={true}>
          { this.state.step == 1 &&
            <>
              <Row style={{height:120}}>
                <Col md="6">
                  <font style={{fontSize:'3em',color:'#FFF'}}>Connectez-vous</font><br/>
                  <span className="loginSubtitle">pour accéder au certificat de qualité</span>
                </Col>
                <Col md="6" className="text-right">
                  <img src={logo} style={{width:'130px',objectFit:'contain'}}/>
                </Col>
              </Row>
              <Row style={{height:'calc(100vh - 200px)',alignItems:'center'}}>
                <Col md="12">
                  <div style={{display:'flex',flexWrap:'wrap',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                    { !this.state._submitting && this.state.users.map((data,i)=>{
                        return AvatarRender(data,i,this.AvatarChoose);
                      })
                    }
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                      <div id="anim" style={{display:this.state._submitting?'':'none',width:'330px'}}></div>
                      { this.state._submitting &&
                        <div style={{color:'white'}}><b>Chargement...</b><br/><br/></div>
                      }
                    </div>
                  </div>
                </Col>
              </Row>
              { !this.state._submitting &&
                <Row>
                  <Col md="12" className="text-right">
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}} onClick={()=>this.setState({step:2,login:'',password:''})}>
                      <Icon name='warning' size={24} color='white' style={{marginRight:10}}/>
                      <p className="loginLabel" style={{fontSize:'1em',textDecoration:'underline',cursor:'pointer'}}>Je ne me trouve pas dans la liste</p>
                    </div>
                  </Col>
                </Row>
              }
            </>
          }
          { this.state.step == 2 &&
            <>
              <Row style={{height:120}}>
                <Col md="2">
                  { this.state.users.length > 0 &&
                    <Icon name="back" size={44} color='white' onClick={this.StepPrev}/>
                  }
                </Col>
                <Col md="8" className="text-center">
                  <font style={{fontSize:'3em',color:'#FFF',marginBottom:0}}>Connectez-vous</font><br/>
                  <span className="loginSubtitle">pour accéder au certificat de qualité</span>
                </Col>
                <Col md="2" className="text-right">
                  <img src={logo} style={{width:'130px',objectFit:'contain'}}/>
                </Col>
              </Row>

              { !this.state._submitting &&
                <>
                  <Row style={{marginTop:70}}>
                    <Col md="4"></Col>
                    <Col md="4">
                      <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',border:'solid 0px #FFF',borderBottomWidth:'1px'}}>
                        <div className="loginLabel">Identifiant :</div>
                        <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',marginBottom:10}}>
                          <Input
                            type="text"
                            autoFocus
                            autoComplete="off"
                            value={this.state.login}
                            style={{padding:0,textAlign:'right',backgroundColor:'transparent',fontSize:'2em',border:'none',color:'#FFF'}}
                            onKeyPress={this.KeyPressed}
                            onChange={this.Change.bind(this,'login')}/>
                        </div>
                      </div>
                    </Col>
                    <Col md="4"></Col>
                  </Row>

                  <Row style={{marginTop:70}}>
                    <Col md="4"></Col>
                    <Col md="4">
                      <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',border:'solid 0px #FFF',borderBottomWidth:'1px'}}>
                        <div className="loginLabel">Mot de passe :</div>
                        <div style={{display:'flex',flex:1,justifyContent:'center',alignItems:'center',marginBottom:10}}>
                          <Input
                            type="password"
                            autoComplete="off"
                            value={this.state.password}
                            style={{padding:0,textAlign:'right',backgroundColor:'transparent',fontSize:'2em',border:'none',color:'#FFF'}}
                            onKeyPress={this.KeyPressed}
                            onChange={this.Change.bind(this,'password')}/>
                        </div>

                      </div>
                    </Col>
                    <Col md="4"></Col>
                  </Row>

                  <Row style={{marginTop:70}}>
                    <Col md="12" className="text-center">
                      <button className="btnRound" disabled={this.state._submitting} onClick={this.Login}>
                        Se connecter
                      </button>
                    </Col>
                  </Row>
                </>
              }

              <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <div id="anim" style={{display:this.state._submitting?'':'none',width:'330px'}}></div>
                { this.state._submitting &&
                  <div style={{color:'white'}}><b>Chargement...</b><br/><br/></div>
                }
              </div>

            </>
          }
          { this.state.step == 3 &&
            <>
              <Row style={{height:80}}>
                <Col md="2">
                  <Icon name="back" size={44} color='white' onClick={this.StepPrev}/>
                </Col>
                <Col md="8" className="text-center">
                  <img src={logo} style={{width:'70px',objectFit:'contain'}}/>
                </Col>
                <Col md="2" className="text-right">
                  {false && this.AvatarRender(this.state.currentUser)}
                </Col>
              </Row>

              <Row style={{marginTop:20}}>
                <Col md="2"></Col>
                <Col md="8" className="text-center" style={{display:'flex',justifyContent:'center'}}>
                  <font style={{fontSize:'2em',color:'#FFF',marginBottom:0}}>Saisir la plaque d'immatriculation</font><br/>
                </Col>
                <Col md="2"></Col>
              </Row>

              <Row style={{marginTop:20}}>
                <Col md="3"></Col>
                <Col md="6" className="text-center">
                  <div style={{height:'80px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <img src={plate} style={{height:'80px',objectFit:'contain'}}/>
                    <Input
                      id="selectPlate"
                      autoFocus
                      autoComplete="off"
                      value={this.state.plate}
                      style={{position:'absolute',padding:0,textAlign:'center',backgroundColor:'transparent',fontSize:'3.5em',border:'none',color:'#000',textTransform:'uppercase'}}
                      onKeyUp={this.KeyPressed}
                      onFocus={this.Focus.bind(this,'plate')}
                      onChange={this.Change.bind(this,'plate')}/>
                    <Tooltip placement="bottom"
                      isOpen={this.state.plates.length>0}
                      style={{width:280}}
                      target="selectPlate">
                      <div className="selectPlateOption">
                        <ul>
                          { this.state.plates.map((data,i)=>{
                              return(
                                <li key={'li'+i}
                                    onClick={this.KeyPressed.bind(this,{key:'Enter'})}
                                    onMouseEnter={()=>{this.setState({platesSelected:i})}}
                                    className={this.state.platesSelected==i?'selected':''}>
                                  {data.label}
                                </li>
                              );
                            })
                          }
                        </ul>
                      </div>
                    </Tooltip>
                  </div>
                </Col>
                <Col md="3"></Col>
              </Row>

              <Row style={{marginTop:20}}>
                <Col md="2"></Col>
                <Col md="8" className="text-center" style={{display:'flex',justifyContent:'center'}}>
                  <font style={{fontSize:'2em',color:'#FFF',marginBottom:0}}>Saisir le VIS ou VIN</font><br/>
                </Col>
                <Col md="2"></Col>
              </Row>

              <Row style={{marginTop:20}}>
                <Col md="12" className="text-center">
                  <div style={{height:'80px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div style={{width:650,height:80,border:'solid 4px #000',borderRadius:6,backgroundColor:'white'}}/>
                    <Input
                      id="selectVin"
                      autoComplete="off"
                      value={this.state.vin}
                      style={{position:'absolute',padding:0,textAlign:'center',backgroundColor:'transparent',fontSize:'3.5em',border:'none',color:'#000',textTransform:'uppercase'}}
                      maxLength={17}
                      onFocus={this.Focus.bind(this,'vin')}
                      onKeyUp={this.KeyPressed}
                      onChange={this.Change.bind(this,'vin')}/>
                    <Tooltip placement="bottom"
                      isOpen={this.state.selectVinOpen}
                      style={{width:280}}
                      target="selectVin">
                      <div className="selectPlateOption">
                        <ul>
                          { this.state.vins.map((data,i)=>{
                              return(
                                <li key={'vli'+i}
                                    onClick={()=>this.KeyPressed({key:'Enter'})}
                                    onMouseEnter={()=>{this.setState({vinsSelected:i})}}
                                    className={this.state.vinsSelected==i?'selected':''}>
                                  {data.label}
                                </li>
                              );
                            })
                          }
                        </ul>
                      </div>
                    </Tooltip>
                  </div>
                </Col>
              </Row>

              <div style={{position:'fixed',bottom:40,right:40}}>
                <button className="btnRound" onClick={this.Validate}>
                  Continuer
                </button>
              </div>

            </>
          }
          { this.state.syncing &&
            <div style={{position:'fixed',top:5,left:5,width:10,height:10,borderRadius:5,backgroundColor:'orange'}}></div>
          }
        </Container>
      </>
    );
  }
}

export default Login;
