import Form from "views/Form.jsx";
import AdminPanel from "views/AdminPanel.jsx";


import i18n from "./assets/lib/i18n";

const routes = [
  {
    path: "/form/:vin",
    component: Form,
    layout: "/labo",
    hidden:true,
    role:["ADM","TEC"]
  },
  {
    path: "/form",
    component: Form,
    layout: "/labo",
    hidden:true,
    role:["ADM","TEC"]
  },
  {
    path: "/admin",
    component: AdminPanel,
    layout: "/labo",
    hidden:true,
    role:["ADM"]
  },
];

export default routes;
