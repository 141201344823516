import React from "react";

import {StrDef} from '../../assets/lib/utils';
import Icon from '../Icons/Icon.jsx';

export var Button = props => {
  return (
     <button className="btnRound" disabled={props.disabled} onClick={props.onClick} style={{...props.style,minHeight:'48px'}}>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
        {props.label}
        { StrDef(props.icon) && StrDef(props.iconColor) &&
          <Icon name={props.icon} size={32} style={{marginLeft:10}} color={props.iconColor}/>
        }
        { StrDef(props.icon) && !StrDef(props.iconColor) &&           // sylvain : pas trop compris pourquoi je fais ça, enlever et regarder pourquoi ?
          <Icon name={props.icon} size={32} style={{marginLeft:10}}/>
        }
      </div>
     </button>
   );
 };


export class Page extends React.Component {
   constructor(props) {
     super(props);
     this.state = {
       _submitting:false,
     }
   }

   Submitting(bool) {
     this.setState({_submitting:bool});
   }
}


export function AvatarRender(data,i,AvatarChoose) {
  var tmp = '';
  if( StrDef(data.local_data) && StrDef(data.local_data.name) ) {
    tmp = data.local_data.name.split(' ');
  }
  else {
    data.local_data.name = '';
  }
  var initiale = '';
  if( tmp.length > 1 ) {
    initiale = tmp[0].charAt(0)+tmp[1].charAt(0);
  }
  else if( tmp.length == 1 ) {
    initiale = tmp[0].charAt(0);
  }
  else {
    initiale = '';
  }

  initiale = initiale.toUpperCase();
  return (
    <div key={'av'+i} onClick={StrDef(i)?()=>AvatarChoose(data,i):null} style={{cursor:'pointer',width:'calc((100vw - 80px) / 8)',height:'calc((100vw - 80px) / 8 + 20px)',flexDirection:'column',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <div className="avatarInitiale" style={{backgroundColor:i%2?'#CCC':'#FFF'}}>
        {initiale}
      </div>
      <div className="avatarName">{data.local_data.name}</div>
    </div>
  )
}
