import axios from 'axios';
import {StrDef,GetJSON,PreimageSha256Condition,Ed25519Sha256Fulfillment,GetPrivateKey,json_hash} from './utils';
var jwtDecode = require('jwt-decode');
var ndjson = require('ndjson');
var moment = require('moment');
var stablejsonstringify = require('json-stable-stringify');
var sha3hash = require('js-sha3');
var uuid = require('uuid/v4');
var _ = require('underscore');
var jsonpipe = require('jsonpipe');
var Datastore = require('nedb');


let _api = null;
const DB_PREFIX = {code_calife:"S-CA-",
                    code_comm_depo:"S-CD-",
                    code_recette: "S-RE-",
                    compta_completed: "S-CC-",
                    current_owner: "S-C0-",
                    emplacement_id: "S-OE-",
                    imei: "S-SN-",
                    lniata: "S-LN-",
                    pdv_completed: "S-PC-",
                    reference: "S-RF-",
                    resarail: "S-RR-",
                    responsable_code_cp: "S-CP-",
                    responsable_completed: "S-RC-",
                    is_completed: "S-IC-",
                    role:"S-RO-",
                    uuid:"S-ID-",
                    deleted:"S-DE-",
                    comment:"S-CT-",
                    uic: "S-UI-",
                    pin_code: "S-PI-"};

var OCAR_API = 'ocarapi';
const LIMIT_API_PAGE = 20;

export function getHashFromJson(json_data) {
  json_data = stablejsonstringify(json_data, (a, b) => (a.key > b.key ? 1 : -1))
  return sha3hash.sha3_256.create().update(json_data).hex()
}

export default class Api {
  constructor() {
    // DEBUG A ENLEVER LUNDI 10 FEVRIER
    // DEBUG A ENLEVER LUNDI 10 FEVRIER
    // DEBUG A ENLEVER LUNDI 10 FEVRIER
    var build = localStorage.getItem('version.build');
    if( build == '5937' && window.location.hostname.indexOf('ocar')!=-1 ) {
      localStorage.clear();
      setTimeout( ()=>{
        var event = new CustomEvent('logout', {detail:''});
        window.dispatchEvent(event);
      }, 1000 );
    }
    // DEBUG A ENLEVER LUNDI 10 FEVRIER
    // DEBUG A ENLEVER LUNDI 10 FEVRIER
    // DEBUG A ENLEVER LUNDI 10 FEVRIER

    // NEDB : base de données document avec les regions, pdv etc....
    this.db = new Datastore({ filename: 'ocar_labo', autoload: true });
    this.db.ensureIndex({fieldName:'user_id',unique:true,sparse:true},()=>{});
    this.db.ensureIndex({fieldName:'vin',unique:true,sparse:true},()=>{});

    var url = 'https://dev.ocode.team/';

    if( process.env.REACT_APP_MODE == 'production' ) {
      url = '/';
    }

    if( process.env.REACT_APP_MODE == 'test' ) {
      OCAR_API = 'ocarapitest';
    }

    this.api = axios.create({
      baseURL: url,
      timeout: 10000
    });

    this.API_BASE_URL = url;
    this.API_TIMEOUT_GET = 30000;

    if(_api != null) {
        throw 'There is already an instance of api alive';
    }

    if( sessionStorage.getItem('user') !== null ) {
      var user = JSON.parse(sessionStorage.getItem('user'));
      this.accessToken = user.accessToken;
      this.refreshToken = user.refreshToken;
      this.userId = user.userId;
    }

    _api = this;
    //this.token = '';

    this.CredentialLoad();
  }

  isTokenExpired(token) {
    var decoded = null;
    try {
      decoded = jwtDecode(token);
    }
    catch(e) {
      console.log(">>>> isTokenExpired",e);
      return true;
    }
    return decoded.exp < parseInt(moment.utc().valueOf()/1000);
  }

  async get_new_accessToken(force) {
    var res = null;
    // si la version n'est déjà pas bonne ne pas faire cette opération
    var user = JSON.parse(sessionStorage.getItem('user'));
    if( user == null ) {
      var event = new CustomEvent('logout', {detail:''});
      window.dispatchEvent(event);
      return;
    }
    var refreshToken = user.refreshToken;

    if( this.isTokenExpired(refreshToken) && !Defined(force) ) {
      //this.navigator.handleDeepLink({link: "login/required"});
      return '';
    }
    else {
      try {
        res = await this.api.get('authenticationapi/entity/signin',{ headers: {'accept-version': '1.0.0',
                                                                        /*'Content-Encoding': 'gzip',*/
                                                                        'Content-Type': 'application/json',
                                                                        'Authorization': 'Bearer '+refreshToken} })
      }
      catch(e) {
        var event = new CustomEvent('logout', {detail:''});
        window.dispatchEvent(event);
      }

      if( Defined(res) && Defined(res.data) && Defined(res.data.access_token) ) {
        //console.log("token res",res);
        user = JSON.parse(sessionStorage.getItem('user'));
        user.accessToken = res.data.access_token;
        console.log( 'get_new_accessToken = ', user );
        sessionStorage.setItem('user',JSON.stringify(user));
        //this.accessToken = res.data.access_token;
        //var decoded = null;
        return res.data.access_token;
      }
      else {
        return '';
      }
    }
  }

  async GetAccessToken() {
    var user = JSON.parse(sessionStorage.getItem('user'));
    if( user != null ) {
      var accessToken = user.accessToken;

      if( this.isTokenExpired(accessToken) ) {
        console.log("isTokenExpiredisTokenExpiredisTokenExpired");
        return await this.get_new_accessToken();
      }
      else {
        return accessToken;
      }
    }
    else {
      return await this.get_new_accessToken();
    }
  }

  async GET(query,config,force) {
    //var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      //if( accessToken == '' ) return null;
      if( accessToken === '' ) return ({error:true});
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }
    if( Defined(config) && Defined(config.headers) && Defined(config.headers.Authorization) ) {
      conf.headers['Authorization'] = config.headers.Authorization;
    }
    if( StrDef(force) ) {
      conf = config;
    }
    return this.api.get(query,conf)
        .then(function (response) {
          if( response.status === 200 ) {
            return response.data;
          }
          else {
            return ({error:''});
          }
        })
        .catch(async function (error) {
          if( error.response ) {
            if( error.response.status == 400 ) {
              if( error.response.data.code == 'VinNotFoundException' && query.substr(0,12) != OCAR_API+'/vin' ) {
                return {error:error.response.data};
              }
            }
          }
          return ({error:error});
        });
  }

  async GETSTREAM(query,progress,complete,error) {
    var accessToken = await this.GetAccessToken();

    var url = this.API_BASE_URL;
    if( url == '/' ) {
      url = 'https://'+window.location.hostname;
    }
    if( query.charAt(0) != '/' ) url += '/';
    jsonpipe.flow(url+query, {
    	delimiter: "\n",
      success: function(data) {
        if( StrDef(progress) ) progress(data);
      },
      error: function(errorMsg) {
        console.log( "jsonpipe error ", url+query, errorMsg );
        if( StrDef(error) ) error(errorMsg);
      },
      complete: function(statusText) {
        if( StrDef(complete) ) complete(statusText);
      },
      timeout: 3000000,
      method: "GET",
      headers: {"Authorization": 'Bearer '+accessToken},
      data: "",
      disableContentType: false,
      withCredentials: false
    });
  }

  async POST(query,data,config) {
    var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      //if( accessToken == '' ) return null;
      if( accessToken === '' ) return ({error:true});
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          /*'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          /*'Accept-Encoding': 'gzip',
                          'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
      if( Defined(config) && Defined(config.headers) && Defined(config.headers.Authorization) ) {
        conf.headers['Authorization'] = config.headers.Authorization;
      }
    }
    return this.api.post(query, data, conf)
      .then(function (response) {
        if( response.status === 200 ) {
          return(response.data);
        }
        else {
          return {error:''};
        }
      })
      .catch(async function (error) {
        if (error.response) {
          if( error.response.status === 400 ) {
            if( error.response.data.code === 'NotAuthorizedException'
                && query !== 'authenticationapi/entity/signin') {
              var newToken = await self.get_new_accessToken(true);
              if( newToken !== '' ) {
                return await self.POST(query,data,config);
              } else {
                return ({error:true});
              }
            }
            else {
              console.log( '((((((((((((((((((((((()))))))))))))))))))))))' );
              return {error:error.response.data};
            }
          }
          return( {error:error.response.data} );
        } else {

        }
        return {error:'unknown'};
      });
  }

  async PUT(query,data,config) {
    var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      //if( accessToken == '' ) return null;
      if( accessToken === '' ) return ({error:true});
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          /*'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          /*'Content-Encoding': 'gzip',*/
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }
    if( Defined(config) && Defined(config.headers) && Defined(config.headers.Authorization) ) {
      conf.headers['Authorization'] = config.headers.Authorization;
    }

    return this.api.put(query, data, conf)
      .then(function (response) {
        if( response.status === 200 ) {
          return(response.data);
        }
        else {
          return {error:''};
        }
      })
      .catch(async function (error) {
        if (error.response) {
          if( error.response.status === 400 ) {
            if( error.response.data.code === 'NotAuthorizedException' && query !== 'authenticationapi/password' && query !== 'authenticationapi/kpis' ) {
              var newToken = await self.get_new_accessToken(true);
              if( newToken !== '' ) {
                //return await self.PUT(query,data,config);
              } else {
                return ({error:true});
              }
            }
          }
          return( {error:error.response.data.code} );
        } else {
        }
        return {error:''};
      });
  }

  async DELETE(query,config) {
    var self = this;
    var conf = config;
    if( typeof(config) == 'undefined' ) {
      var accessToken = await this.GetAccessToken();
      if( accessToken === '' ) return null;
      conf = { headers: {'Authorization': 'Bearer '+accessToken,
                          'accept-version': '1.0.0',
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_POST };
    }
    else {
      conf = { headers: { 'accept-version': '1.0.0',
                          'Content-Encoding': 'gzip',
                          'Content-Type': 'application/json',
                          },
                          timeout: this.API_TIMEOUT_GET };
    }

    return this.api.delete(query,conf)
        .then(function (response) {
          if( response.status === 200 ) {
            return response.data;
          }
          return {error:response.status};
        })
        .catch(async function (error) {
          if( error.response.status === 400 ) {
            if( error.response.data.code === 'NotAuthorizedException' ) {
              var newToken = await self.get_new_accessToken(true);
              if( newToken !== '' ) {
                return await self.DELETE(query,config);
              }else {
                console.log("logoutlogoutlogoutlogoutlogoutlogoutlogout");
                /*var event = new Event('logout');
                document.dispatchEvent(event);*/
                return ({error:true});
              }
            }
          }
          return {error:error.response.status};
        });
  }

  //------------------------------------------------ API CALLS
  async init_register_device(callback,pass) {
    this.token = uuid();

    var data = await this.POST('authenticationapi/init_register_device',
      {
      app: "FollowMeBackEnd",
      device_token: this.token,
      device_platform: "browser"
      },
      {});

    if( Defined(data) && Defined(data.error) ) {
      console.log( data.error );
      return data.error;
    }
    else {
      console.log( 'data.register_token = ', data.register_token );
      this.register_device(data.register_token,callback,pass);
      return true;
    }
  }

  async register_device(registerToken,callback,pass) {
    var password = '12345678';
    if( StrDef(pass) ) password = pass;

    var res = await this.POST('authenticationapi/register_device',
      {
      country_id: 'fr',
      locale: 'fr',
      password: password
      },
      { headers: {'Authorization': 'Bearer '+registerToken} });

    if( Defined(res.access_token) ) {
      this.accessToken  = res.access_token;
      this.refreshToken = res.refresh_token;
      this.userId       = res.user_id;
      sessionStorage.setItem('user',JSON.stringify({accessToken:this.accessToken,
                                                    refreshToken:this.refreshToken,
                                                    userId:this.userId}));
      callback({});
    }
    else {
      callback({error:'register_device'});
    }
  }

  async login_set(login,name,type,callback) {  // type : EMAIL ou LOGIN
    var res = await this.PUT('authenticationapi/login',
      {
      login_gender: type,
      login: login,
      description: { name: name }
      });
    callback(res);
  }

  async signin(login,password,callback) {
    var self = this;

    var apiObj = {
      "app": "OcarBackEnd",
      "device_platform": "browser",
      "account_id": this.entityId,
      "user_name": login,
      "password": password
    };

    var data = await this.POST( 'authenticationapi/entity/signin', apiObj, {} );

    if( Defined(data.access_token) ) {
      this.accessToken = data.access_token;
      this.refreshToken = data.refresh_token;
      this.userId = data.user_id;
      sessionStorage.setItem('user',JSON.stringify({accessToken:this.accessToken,
                                                    refreshToken:this.refreshToken,
                                                    userId:this.userId}));
      // on recupere la liste des users
      this.user_sync(async (res)=>{
        // on charge les tampons et private key
        if( sessionStorage.getItem('version.hash') == null ) {
          var entity = await this.GET('ochain/entity/'+this.entityId);
          if( !StrDef(entity.error) ) {
            var stamps = entity.long_description.stamps;
            sessionStorage.setItem('entityStamp',JSON.stringify(stamps));
            var concession = '';
            self.entityStamp = {};
            for(var s in stamps) {
              if( s == 'labo01' ) concession = 'CLARO';
              if( s == 'labo02' ) concession = 'CLARA';
              self.entityStamp[concession] = new Image();
              self.entityStamp[concession].concession = concession;
              self.get_media(stamps[s],self.entityStamp[concession],(url,img)=>{
                img.crossOrigin="anonymous";
                img.onload = function() {
                  var canvas = document.createElement('CANVAS');
                  var ctx = canvas.getContext('2d');
                  canvas.height = this.naturalHeight;
                  canvas.width = this.naturalWidth;
                  ctx.drawImage(this, 0, 0);
                  sessionStorage.setItem('entityStamp'+img.concession,canvas.toDataURL('image/png'));
                };
                img.src = url;
              });
            }
            this.entityPermission = entity.long_description.ocode_permissions;
            sessionStorage.setItem('entityPermission',this.entityPermission);
            // private key
            var p = localStorage.getItem('version.build');
            if( p == null ) {
              if( data.user_permission != 0 ) {
                var event = new CustomEvent('needCode', {detail:entity.encrypted_data.key});
                window.dispatchEvent(event);
              }
              else {  // on stocke en memoire la cle car dans l'ecran d'admin on en aura besoin
                self.encryptedDataKey = entity.encrypted_data.key;
              }
            }
            else {
              if( StrDef(entity.encrypted_data) ) {
                this.entityKey = GetPrivateKey(entity.encrypted_data.key,p);
                sessionStorage.setItem('version.hash',this.entityKey);
              }
            }
          }
        }
      });
      this.Synchro(()=>callback(data));
    }
    else {
      callback(data);
    }
  }

  //======================================================= USER ===============
  async CreateUpdateUser(data,callback) {
    var res = null;

    if( StrDef(data._id) ) {
      //Modifier un user
      var obj = { new_user_name: data.user_name,
                	permission: data.permission,
                	local_data: {
                		name: data.local_data.name,
                		signature: data.local_data.signature
                	}
                };
      if( data.permission == 0 && StrDef(data.password) ) {
        obj.new_password = data.password;
      }

      if( StrDef(data.status) && parseInt(data.status) == 9 ) {
        obj.status = parseInt(data.status);
      }

      if( this.userId == data.user_id ) {
        delete(obj.permission);
        delete(obj.status);
      }

      if( data.permission == 1 ) {
        obj.new_password = '00000000';
      }

      res = await this.PUT('authenticationapi/entity/'+this.entityId+'/user/'+data.initial_user_name,obj);
    }
    else {
      // creation de user
      var obj = { user_name: data.user_name,
                	password: StrDef(data.password)?data.password:'00000000',
                	permission: data.permission,
                	local_data: {
                		name: data.local_data.name,
                		signature: data.local_data.signature
                	}
                };
      res = await this.POST('authenticationapi/entity/'+this.entityId+'/user',obj);
    }

    if( StrDef(res.error) ) {
      console.log( res );
      if( StrDef(callback) ) callback(res);
      else return res;
    }
    else {
      var doc = { user_id: data.user_id,
                  user_name: data.user_name,
                  password: StrDef(data.password)?data.password:'00000000',
                  permission: data.permission,
                  local_data: {
                  	name: data.local_data.name,
                  	signature: data.local_data.signature
                  },
                  _type:'USER',
                };
      var cond = {_type:'USER',login:doc.user_name};
      if( StrDef(data._id) ) {
        cond = {_type:'USER',_id:data._id};
        doc._id = data._id;
      }
      if( StrDef(data.status) && parseInt(data.status) == 9 ) {
        doc.status = parseInt(data.status);
      }
      this.db.update(cond,
                      doc,
                      {upsert:true},
                      (err,newDoc)=>{
                        if( StrDef(callback) ) callback(res);
                        else return res;
                      });
    }
  }

  async users_get(after,callback) {
    var when = '2000-01-01%2023:59:59.000';
    if( StrDef(after) ) when = after;
    var res = await this.GET('authenticationapi/entity/'+this.entityId+'/users?updated_after='+when);
    // on controle que les users sont bien formes
    for(var i=0;i<res.length;i++) {
      if( !StrDef(res[i].local_data) ) {
        res[i].local_data = {name:'',signature:''};
      }
    }
    if( StrDef(callback) ) callback(res);
    else return res;
  }

  user_sync(callback) {
    var usersList = [];

    var loadUsers = (after)=>{
      this.users_get(after,(res)=>{
        for(var i=0;i<res.length;i++) {
          usersList.push( res[i] );
        }
        if( res.length == LIMIT_API_PAGE ) {
          loadUsers(res[res.length-1].modify_date);
        }
        else {
          var self = this;
          // on a tous les users on synchronise avec le local, on supprime tout et on insert
          this.db.remove({_type:'USER'}, { multi: true }, function (err, numRemoved) {
            var doc = null;
            for(var i=0;i<usersList.length;i++) {
              if( usersList[i].status != 9 ) {
                self.db.insert( {...usersList[i],_type:'USER'}, (err,newDoc)=>{});
              }
            }
            callback(usersList);
          });
        }
      })
    };

    loadUsers(null);
  }

  async userGetLocal(callback,permission) {
    if( !StrDef(permission) ) permission = 1;
    this.db.find({_type:'USER',$not:{status:9},permission:permission}).sort({user_name:1}).exec((e,docs)=>{
      callback(docs);
    });
  }

  async setCurrentUser(data) {
    this.currentUser = {};
    this.currentUser.name = data.local_data.name;

    var sign = data.local_data.signature;
    if( !StrDef(data.local_data.signature) ) sign = '';
    var data = "data:image/svg+xml;base64," + btoa(sign);
    var img  = new Image();
    img.crossOrigin="anonymous"
    img.src = data;
    this.currentUser.signature = img;

    sessionStorage.setItem('currentUser',JSON.stringify({ name:this.currentUser.name,
                                                          signature:sign }));
  }

  CredentialLoad() {
    var self = this;

    // on charge le user
    var user = sessionStorage.getItem('currentUser');
    if( user != null ) {
      var res = JSON.parse(user);

      this.currentUser = {};
      this.currentUser.name = res.name;

      var data = "data:image/svg+xml;base64,"+btoa(res.signature);
      var img  = new Image();
      img.crossOrigin="anonymous"
      img.src = data;
      this.currentUser.signature = img;
    }
    // on charge l'entity depuis le localstorage
    this.entityId = localStorage.getItem('entityId');

    // on charge les tampons
    var stamps = sessionStorage.getItem('entityStamp');
    if( stamps != null ) {
      stamps = JSON.parse(stamps);
      var concession = '';
      self.entityStamp = {};
      for(var s in stamps) {
        if( s == 'labo01' ) concession = 'CLARO';
        if( s == 'labo02' ) concession = 'CLARA';
        self.entityStamp[concession] = new Image();
        setTimeout((url,img,concession)=>{
          img.crossOrigin="anonymous"
          img.src = sessionStorage.getItem('entityStamp'+concession);
        },1,stamps[s],self.entityStamp[concession],concession);
      }
    }

    // les permissions
    this.entityPermission = sessionStorage.getItem('entityPermission');

    //la entityKey
    this.entityKey = sessionStorage.getItem('version.hash');
  }

  //===================================================== SYNCHRO ==============
  async Synchro(callback) {
    var self = this;
    var sync = localStorage.getItem('sync');
    if( sync == null ) sync = moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');
    var syncError = localStorage.getItem('syncError');
    if( syncError == null ) syncError = moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');
    var hrs = moment().diff(sync, 'hours');
    var hrsError = moment().diff(syncError, 'hours');

    this.db.count({_type:'CAR'}, function (err, count) {
      if( hrs > 12 || count == 0 ) {
        if( hrsError > 1 ) {  // si la derniere erreur est ancienne, on re-essaie
          self.SyncLocal();
          self.SyncDL(callback);
        }
        else {
          callback();
          self.SyncLocal();
        }
      }
      else {
        callback();
        self.SyncLocal();
      }
    });
  }

  async SyncDL(callback) {
    var self = this;

    var arrayCreate = (r,arr,company) => {
      for(var i=0;i<arr.length;i++) {
        r[arr[i].details.vin] = { _type:'CAR',
                                  company:company,
                                  vin:arr[i].details.vin,
                                  brand:arr[i].details.brand,
                                  model:arr[i].details.model,
                                  registration:arr[i].details.registration };
      }
    };
    var queryString = (status,labo) => {
      return new Buffer("/owners/"+labo+"/products?state="+status+"&workflow=PHOTO").toString('base64');
    };

    var event = new CustomEvent('syncStock', {detail:'start'});
    window.dispatchEvent(event);

    var r = {}; // on va mettre les data dans r

    var progress = (data,company)=>{
      if( StrDef(data) && StrDef(data.details) ) {
        r[data.details.vin] = { _type:'CAR',
                                company:company,
                                vin:data.details.vin,
                                brand:data.details.brand,
                                model:data.details.model,
                                registration:data.details.registration };
      }
    };

    var nbError = 0;
    var onError = (e) =>{
      nbError++;
    };

    var nbCompleted = 4;
    var complete = (data,company)=>{
      nbCompleted--;
      if( nbCompleted == 0 ) {
        var tab = [];
        for(var k in r) {
          tab.push(r[k]);
        }
        r = tab;

        event = new CustomEvent('syncStock', {detail:'end'});
        window.dispatchEvent(event);

        if( nbError == 0 ) {
          this.db.remove({_type:'CAR'}, { multi: true }, function (err, numRemoved) {
            self.db.insert( r, (err,newDoc)=>{
              if( err ) console.log('DB ERROR !!!!!!!', err );
              console.log( 'db inserted : ', newDoc.length );
            });
          });

          if( !StrDef(window.synchro) ) window.synchro = {}
          window.synchro.BDD = {};
          window.synchro.registrationToVin = {};
          window.synchro.registration = [];
          window.synchro.vis = [];
          window.synchro.visToVin = {};
          for(var i=0;i<r.length;i++) {
            window.synchro.BDD[r[i].vin] = {company:r[i].company,
                                            vin:r[i].vin,
                                            brand:r[i].brand,
                                            model:r[i].model,
                                            registration:r[i].registration};
            window.synchro.registrationToVin[r[i].registration] = r[i].vin;
            window.synchro.registration.push( r[i].registration );
            window.synchro.vis.push( r[i].vin.substring(9,17) );
            window.synchro.visToVin[r[i].vin.substring(9,17)] = r[i].vin;
          }
          localStorage.setItem('sync',moment().format('YYYY-MM-DD HH:mm:ss'));
        }
        else {  // il y a eut une erreur on enregistre pour attendre quelques heures avant de re-essayer
          localStorage.setItem('syncError',moment().format('YYYY-MM-DD HH:mm:ss'));
        }
        var event = new CustomEvent('loadBdd', {detail:'bdd'});
        window.dispatchEvent(event);
        callback();
      }
    };


    this.GETSTREAM(OCAR_API+'/stampyt/ocars_stream?query='+queryString('TODO','labo02'),
                    (d)=>progress(d,'CLARA'),
                    (d)=>complete(d,'CLARA'),
                    (d)=>onError(d) );

    this.GETSTREAM(OCAR_API+'/stampyt/ocars_stream?query='+queryString('DONE','labo02'),
                    (d)=>progress(d,'CLARA'),
                    (d)=>complete(d,'CLARA'),
                    (d)=>onError(d) );

    this.GETSTREAM(OCAR_API+'/stampyt/ocars_stream?query='+queryString('TODO','labo01'),
                    (d)=>progress(d,'CLARO'),
                    (d)=>complete(d,'CLARO'),
                    (d)=>onError(d) );

    this.GETSTREAM(OCAR_API+'/stampyt/ocars_stream?query='+queryString('DONE','labo01'),
                    (d)=>progress(d,'CLARO'),
                    (d)=>complete(d,'CLARO'),
                    (d)=>onError(d) );
  }

  async SyncDLOLD(callback) {
    var self = this;

    var arrayCreate = (r,arr,company) => {
      for(var i=0;i<arr.length;i++) {
        r[arr[i].details.vin] = { _type:'CAR',
                                  company:company,
                                  vin:arr[i].details.vin,
                                  brand:arr[i].details.brand,
                                  model:arr[i].details.model,
                                  registration:arr[i].details.registration };
      }
    };
    var queryString = (status,labo) => {
      return new Buffer("/rec/owners/"+labo+"/products?state="+status+"&workflow=PHOTO").toString('base64');
    };

    var event = new CustomEvent('syncStock', {detail:'start'});
    window.dispatchEvent(event);

    var r = {}, res=null;
    res = await this.GET(OCAR_API+'/stampyt/ocars?query='+queryString('TODO','labo02'));
    arrayCreate(r,res,'CLARA');

    res = await this.GET(OCAR_API+'/stampyt/ocars?query='+queryString('DONE','labo02'));
    arrayCreate(r,res,'CLARA');

    res = await this.GET(OCAR_API+'/stampyt/ocars?query='+queryString('TODO','labo01'));
    arrayCreate(r,res,'CLARO');

    res = await this.GET(OCAR_API+'/stampyt/ocars?query='+queryString('DONE','labo01'));
    arrayCreate(r,res,'CLARO');

    var tab = [];
    for(var k in r) {
      tab.push(r[k]);
    }
    r = tab;

    event = new CustomEvent('syncStock', {detail:'end'});
    window.dispatchEvent(event);

    this.db.remove({_type:'CAR'}, { multi: true }, function (err, numRemoved) {
      self.db.insert( r, (err,newDoc)=>{
        if( err ) console.log('DB ERROR !!!!!!!', err );
        console.log( 'db inserted : ', newDoc.length );
      });
    });

    if( !StrDef(window.synchro) ) window.synchro = {}
    window.synchro.BDD = {};
    window.synchro.registrationToVin = {};
    window.synchro.registration = [];
    window.synchro.vis = [];
    window.synchro.visToVin = {};
    for(var i=0;i<r.length;i++) {
      window.synchro.BDD[r[i].vin] = {company:r[i].company,
                                      vin:r[i].vin,
                                      brand:r[i].brand,
                                      model:r[i].model,
                                      registration:r[i].registration};
      window.synchro.registrationToVin[r[i].registration] = r[i].vin;
      window.synchro.registration.push( r[i].registration );
      window.synchro.vis.push( r[i].vin.substring(9,17) );
      window.synchro.visToVin[r[i].vin.substring(9,17)] = r[i].vin;
    }
    var event = new CustomEvent('loadBdd', {detail:'bdd'});
    window.dispatchEvent(event);
    localStorage.setItem('sync',moment().format('YYYY-MM-DD HH:mm:ss'));
    callback();
  }

  SyncLocal() {
    // initialisation des variables
    if( !StrDef(window.synchro) ) window.synchro = {}
    window.synchro.BDD = {};
    window.synchro.registrationToVin = {};
    window.synchro.registration = [];
    window.synchro.vis = [];
    window.synchro.visToVin = {};

    this.db.find({_type:'CAR'}, function (err, r) {
      if( r.length == 0 ) {
        console.log( 'loaded from local ', r.length );
        return;
      }
      for(var i=0;i<r.length;i++) {
        window.synchro.BDD[r[i].vin] = {company:r[i].company,
                                        vin:r[i].vin,
                                        brand:r[i].brand,
                                        model:r[i].model,
                                        registration:r[i].registration};
        window.synchro.registrationToVin[r[i].registration] = r[i].vin;
        window.synchro.registration.push( r[i].registration );
        window.synchro.vis.push( r[i].vin.substring(9,17) );
        window.synchro.visToVin[r[i].vin.substring(9,17)] = r[i].vin;
      }
      var event = new CustomEvent('loadBdd', {detail:'bdd'});
      window.dispatchEvent(event);
      console.log( 'loaded from local ', window.synchro.registration.length );
    });
  }

  //===================================================== MEDIA ================
  async upload_media(name,blob,callback) {
    console.log("name",name);
    console.log("blob",blob);
    var res = await this.GET(OCAR_API+'/mediaputurl?name='+name);
    console.log("res",res);
    if( res == null ) {
      callback(false);
    } else {
      //var header = {'Content-Type' : ''};
      var accessToken = await this.GetAccessToken();
      //console.log("accessToken",accessToken);
      //if( accessToken == '' ) return null;
      var header = {'Content-Type' : ''};
      fetch(res.url, {  method: 'PUT',
                        headers: header,
                        body: blob,
                      } )
      .then((resp) => { console.log( resp ); callback(res.url) })
      .catch((err) => { console.log(err) });
    }
  }

  async get_media(name,img,callback) {              // le param img est juste passé en param : on en a besoin dans le callback
    var res = await this.GET(OCAR_API+'/media/'+name);
    if( StrDef(res) && StrDef(res.url) ) {
      callback(res.url,img);
    }
    else {
      callback('',{});
    }
  }

  //===================================================== SAFE =================
  async get_safe_data(vin,callback) {
    var res = await this.GET(OCAR_API+'/vin/'+vin);
    console.log("res",res);
    if (Defined(callback)) {
      callback(res)
    } else {
      return res;
    }
  }

  async create_safe(vin,ocode_data,callback) {
    var accessToken = await this.GetAccessToken();
    let decoded = jwtDecode(accessToken);
    let ocode_ownership = PreimageSha256Condition(vin+'@'+decoded.entity_id+'@'+decoded.user_id)
    let ocode_permissions = this.entityPermission;
    let safe_data = {}
    safe_data.ocode_data = ocode_data
    safe_data.ocode_ownership = ocode_ownership
    safe_data.ocode_permissions = ocode_permissions
    let res = await this.POST(OCAR_API+'/ocode',safe_data);
    if (Defined(callback)) {
      callback(res)
    } else {
      return res;
    }
  }

  async create_safe_cq(cq_data,cq_date,callback) {
    cq_data = JSON.parse(JSON.stringify(cq_data))
    let row_hash = json_hash(cq_data)

    let objTxId = {} // obj pour obtenir le hash tx_id
    objTxId.ocode_id = cq_data.ocode_id
    objTxId.last_row_hash = cq_data.last_row_hash
    objTxId.row_hash = row_hash
    objTxId.operation = 'OCODEUPDATE';
    objTxId.tx_date = cq_date;
    let tx_id = json_hash(objTxId)

    cq_data.row_hash = row_hash;
    cq_data.operation = 'OCODEUPDATE';
    cq_data.tx_date = cq_date;
    cq_data.tx_id = tx_id
    //let privateKey = GetPrivateKey("U2FsdGVkX1+xijQgPsL5MTSuEGgk0jyS1tEQB6aatWBsX4yr1gohlRrzcMVn/ib08xxVZdKWUlXFTNNPJcWflw==",'5937')
    let privateKey = this.entityKey
    let allowed_entity_condition_fulfillment = Ed25519Sha256Fulfillment(tx_id, privateKey);//Signer la transaction
    cq_data.allowed_entity_condition_fulfillment = allowed_entity_condition_fulfillment
    //return;
    let res = await this.PUT(OCAR_API+'/ocode',cq_data);
    if (Defined(callback)) {
      callback(res)
    } else {
      return res;
    }
  }

  async get_cq(vin,callback) {
    let res = await this.GET(OCAR_API+'/vin/'+vin+'/history?operation_codes=CQ');
    if (Defined(callback)) {
      callback(res)
    } else {
      return res;
    }
  }

  async get_ocode_cq(ocar_ocode_id,callback) {
    let res = await this.POST(OCAR_API+'/operation_ocode',ocar_ocode_id);
    if (Defined(callback)) {
      callback(res)
    } else {
      return res;
    }
  }
}


function Defined(obj) {
 return typeof(obj) != 'undefined' && obj != null;
}
/*function StrDef(obj) {
  return typeof(obj) != 'undefined' && obj != null && obj != '';
}*/
export function sharedAPI() {
  return _api;
}
