import React from "react";
// react plugin used to create charts
import KeyboardEventHandler from 'react-keyboard-event-handler';
import i18n from "../assets/lib/i18n";
import {StrDef,Phone,Clone,file_hash,DateDisplay} from '../assets/lib/utils';
import {sharedAPI} from "../assets/lib/api";
import Icon from '../components/Icons/Icon.jsx';
import jsPDF from 'jspdf';

// reactstrap components
import {
  Badge,
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
} from "reactstrap";

import {Button,Page} from '../components/UI/UI.jsx';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import dataPeugeot from '../data/peugeot.js';
import logoPeugeot from '../data/logoPeugeot.png';
import dataOpel from '../data/opel.js';
import logoOpel from '../data/logoOpel.png';
import logoClara from '../data/logoClara.png';
import logoClaro from '../data/logoClaro.png';

import keyLeft from '../assets/img/left.png';
import keyEnter from '../assets/img/enter.png';

var moment = require('moment');
var innerHeight = require('ios-inner-height');

function getPointA(x,y) {
  let point = {}
  point.x = x-30
  point.y = y-100
  return point;
}
function getPointB(x,y) {
  let point = {}
  point.x = x+25-30
  point.y = y+25-100
  return point;
}
function getPointC(x,y) {
  let point = {}
  point.x = x+75-30
  point.y = y-25-100
  return point;
}
function getPointAPrime(x,y) {
  let point = {}
  point.x = x-30
  point.y = y-100
  return point;
}
function getPointBPrime(x,y) {
  let point = {}
  point.x = x+15-30
  point.y = y+15-100
  return point;
}
function getPointCPrime(x,y) {
  let point = {}
  point.x = x+45-30
  point.y = y-15-100
  return point;
}

class Form extends React.Component {

  constructor(props) {
    super(props);

    this.KeyboardHook = this.KeyboardHook.bind(this);
    this.KeyboardHookModal = this.KeyboardHookModal.bind(this);
    this.UpdateWindowDimensions = this.UpdateWindowDimensions.bind(this);
    this.Check = this.Check.bind(this);
    this.Preload = this.Preload.bind(this);
    this.Load = this.Load.bind(this);
    this.ChangeInfo = this.ChangeInfo.bind(this);
    this.FormMouseEnter = this.FormMouseEnter.bind(this);
    this.Menu = this.Menu.bind(this);
    this.temp = this.temp.bind(this);
    this.ToggleModal = this.ToggleModal.bind(this);
    this.Sign = this.Sign.bind(this);
    this.state = {
      selected:0,
      lines:[],
      footer:{},
      width: 4676,
      height : 3307,
      x: 0, y: 0,
      existingCQ : {}, // dans le cas où un CQ existe,
      company : '', // pour gérer les checkbox parentes (type subtitle pour peugeot, type title pour opel)
    };

    window.addEventListener('loadBdd',this.Preload,false);
  }

  componentDidMount() {
    if( StrDef(this.props.match.params.vin) && StrDef(window.synchro) && StrDef(window.synchro.BDD) ) {
      this.Preload();
    }
  }

  componentWillUnmount() {
    if( document.querySelector('#form') != null ) document.querySelector('#form').removeEventListener("mouseenter", this.FormMouseEnter, false);
    window.removeEventListener('loadBdd',this.Preload,false);
  }

  UpdateWindowDimensions() {
    this.setState({ winW: window.innerWidth, winH: window.innerHeight });
  }

  Preload() {
    /*var car = window.synchro.BDD[this.props.match.params.vin];
    this.setState({chooseStamp:true});
    if( !StrDef(car) ) {
      //this.setState({chooseStamp:true});
    }
    else {
      this.Load();
    }*/
    this.setState({chooseStamp:true})

  }

  temp() {
    let opel = true;
    if (!opel) {
      let footer = this.state.footer
      let doc_height = 210;  // A4 au format landscap
      let doc_width = 297;
      var canvas = document.getElementById("canvas");
      var ctx = canvas.getContext("2d");
      var imgSrc = new Image;
      imgSrc.addEventListener('load', function() {
  //test()
        canvas.width = imgSrc.width
        canvas.height = imgSrc.height
        console.log("width",imgSrc.width);
    console.log("height",imgSrc.height);
        ctx.drawImage(imgSrc,0,0,canvas.width,canvas.height); // Or at whatever offset you like
        ctx.beginPath();

        //ctx.moveTo(125,150);
        //ctx.lineTo(150,175);
        //ctx.lineTo(200,125);
        // x = 1179, y = 656



        ctx.font = "40px Arial";
        ctx.fillText("R", 4250,2375);


        let immatriculation = footer.registration
        let km = '111111'
        let real = true
        let guaranty = true
        let vin = footer.vin
        let date = moment().format('DD/MM/YYYY');
        let user = 'Sylvain Peerridi'

        let pointA = getPointA(1280,2840)
        let pointB = getPointB(1280,2840)
        let pointC = getPointC(1280,2840)
        ctx.moveTo(pointA.x,pointA.y);
        ctx.lineTo(pointB.x,pointB.y);
        ctx.lineTo(pointC.x,pointC.y);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#000';
        ctx.stroke();

        pointA = getPointA(4525,1800)
        pointB = getPointB(4525,1800)
        pointC = getPointC(4525,1800)
        ctx.moveTo(pointA.x,pointA.y);
        ctx.lineTo(pointB.x,pointB.y);
        ctx.lineTo(pointC.x,pointC.y);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#000';
        ctx.stroke();

        ctx.font = "60px Arial";
        ctx.fillText(immatriculation, 1150,2620);
        ctx.fillText(km, 800,2775);
        ctx.fillText(vin, 850,2970);
        ctx.fillText(date, 2300,2700);
        ctx.fillText(user, 2120,2920);

        let signature = '/sign1.png'
        let cachet = '/peugeotStamp.png'
        console.log("dfdfdfdfdfdffdfdfdf");


        var imgSignature = new Image;
        imgSignature.addEventListener('load', function() {
          ctx.drawImage(imgSignature,2500,2750,400,300);
          console.log("imgSignatureimgSignatureimgSignature");
          var imgCachet = new Image;
          imgCachet.addEventListener('load', function() {
            ctx.drawImage(imgCachet,3300,2650,800,300);
            console.log("imgCachetimgCachet");
            let jsPDFOpts = {
              orientation: 'landscape',
              //putOnlyUsedFonts : true,
              //unit: 'mm',
              //format: [doc_width, doc_height],
              compress: true
            };
            const img = canvas.toDataURL('image/jpeg',0.9)
            const pdf = new jsPDF(jsPDFOpts)
            pdf.addImage(img, 'JPEG', 0, 0, doc_width+.16, doc_height)
            var blob = pdf.output('blob');
            console.log('blob',blob);
            var stream = blob.stream();
            console.log("stream",stream);
            //pdf.save('your-filename.pdf')
          })
          imgCachet.src = cachet
        })
        imgSignature.src = '/signature.svg'
      });
      imgSrc.src = this.data.getImgCQ();
    } else {
      console.log('this.state.footer',this.state.footer);
      let footer = this.state.footer
      let doc_height = 210;  // A4 au format landscap
      let doc_width = 297;
      var canvas = document.getElementById("canvas");
      var ctx = canvas.getContext("2d");
      var imgSrc = new Image;
      imgSrc.addEventListener('load', function() {
  //test()
        canvas.width = imgSrc.width
        canvas.height = imgSrc.height
        console.log("width",imgSrc.width);
    console.log("height",imgSrc.height);
        ctx.drawImage(imgSrc,0,0,canvas.width,canvas.height); // Or at whatever offset you like
        ctx.beginPath();
        let pointA = getPointA( 4525,3193)
        let pointB = getPointB(4525,3193)
        let pointC = getPointC(4525,3193)
        ctx.moveTo(pointA.x,pointA.y);
        ctx.lineTo(pointB.x,pointB.y);
        ctx.lineTo(pointC.x,pointC.y);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#000';
        ctx.stroke();
        //ctx.moveTo(125,150);
        //ctx.lineTo(150,175);
        //ctx.lineTo(200,125);
        // x = 1179, y = 656
        //x:1180,y:656
        pointA = getPointA(1180,606)
        pointB = getPointB(1180,606)
        pointC = getPointC(1180,606)
        ctx.moveTo(pointA.x,pointA.y);
        ctx.lineTo(pointB.x,pointB.y);
        ctx.lineTo(pointC.x,pointC.y);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#000';
        ctx.stroke();

        pointA = getPointAPrime(1180,656)
        pointB = getPointBPrime(1180,656)
        pointC = getPointCPrime(1180,656)
        ctx.moveTo(pointA.x,pointA.y);
        ctx.lineTo(pointB.x,pointB.y);
        ctx.lineTo(pointC.x,pointC.y);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#000';
        ctx.stroke();
        //ctx.font = "40px Arial";
        //ctx.fillText("R", 4250,2375);


        /*let immatriculation = footer.registration
        let km = '111111'
        let real = true
        let guaranty = true
        let vin = footer.vin
        let date = moment().format('DD/MM/YYYY');
        let user = 'Sylvain Peerridi'

        let pointA = getPointA(1280,2840)
        let pointB = getPointB(1280,2840)
        let pointC = getPointC(1280,2840)
        ctx.moveTo(pointA.x,pointA.y);
        ctx.lineTo(pointB.x,pointB.y);
        ctx.lineTo(pointC.x,pointC.y);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#000';
        ctx.stroke();

        pointA = getPointA(1525,2840)
        pointB = getPointB(1525,2840)
        pointC = getPointC(1525,2840)
        ctx.moveTo(pointA.x,pointA.y);
        ctx.lineTo(pointB.x,pointB.y);
        ctx.lineTo(pointC.x,pointC.y);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#000';
        ctx.stroke();

        ctx.font = "60px Arial";
        ctx.fillText(immatriculation, 1150,2620);
        ctx.fillText(km, 800,2775);
        ctx.fillText(vin, 850,2970);
        ctx.fillText(date, 2300,2700);
        ctx.fillText(user, 2120,2920);

        let signature = '/sign1.png'
        let cachet = '/peugeotStamp.png'
        console.log("dfdfdfdfdfdffdfdfdf");*/
        ctx.font = "30px Arial";
        ctx.fillText('AV-AR-R', 1080,990);
        ctx.fillText('Adam Rocks 1.4 Twinport 87ch Start/Stop', 400,610);
        ctx.fillText('EV-065-MV', 400,780);
        ctx.fillText("W0V0MAP08J6034350", 1465,285);
        ctx.fillText("stock", 1465,460);
        ctx.fillText("EV-065-MV", 1465,615);
        ctx.fillText("18/02/1999", 1465,780);
        ctx.fillText("Sylvain Perridi", 2500,285);
        var imgSignature = new Image;
        imgSignature.addEventListener('load', function() {
          ctx.drawImage(imgSignature,2700,400,400,300);
          //console.log("imgSignatureimgSignatureimgSignature");
          var imgCachet = new Image;
          imgCachet.addEventListener('load', async function() {
            ctx.drawImage(imgCachet,3600,360,800,300);
            //console.log("imgCachetimgCachet");
            let jsPDFOpts = {
              orientation: 'landscape',
              //putOnlyUsedFonts : true,
              //unit: 'mm',
              //format: [doc_width, doc_height],
              compress: true
            };
            const img = canvas.toDataURL('image/jpeg',0.9)
            const pdf = new jsPDF(jsPDFOpts)
            pdf.addImage(img, 'JPEG', 0, 0, doc_width+.16, doc_height)
            pdf.setFileId(1);
            pdf.setCreationDate(new Date('2010-12-24T00:00:00'));
            var blob = pdf.output('blob');//datauristring
            console.log('blob',blob);
            //var url = URL.createObjectURL(blob);
            let hash_pdf = await file_hash(blob)//Générer le hash de la photo
            console.log(">>>>hash_pdfhash_pdfhash_pdfhash_pdf",hash_pdf);
            //blobToTest = blob
            //saveByteArray([sampleBytes], 'testo.pdf');

            //pdf.save('your-filename.pdf')
          })
          imgCachet.src = '/opelStamp.png'
        })
        imgSignature.src = '/signature.svg'
      });
      imgSrc.src = this.data.getImgCQ();
    }

  }

  showCoords(event) {
    console.log("event",event);
    var x = event.clientX;
    var y = event.clientY;
    var coor = "X coords: " + x + ", Y coords: " + y;
    console.log("coor",coor);
  }

  async Load() {
    // tester si deja un CQ
    let backend = sharedAPI();
    let listCQ = await backend.get_cq(this.props.match.params.vin)
    let state = {}
    if (listCQ.length > 0) {
      let existingCQ = listCQ[0].ocode_data.operation_data.media_url
      state.existingCQ = existingCQ
      state.existingCQDate = listCQ[0].ocode_data.operation_data.operation_date
      state.checkQC = true
      this.setState(state);
    } else {
      this.CreateQC()
    }
  }

  PostLoad() {
    if( StrDef(this.props.match.params.vin) ) {
      var car = window.synchro.BDD[this.props.match.params.vin];
      if( !StrDef(car) ) {
        car = {};
        car.company = 'CLARA';
        car.vin = this.props.match.params.vin;
        car.registration = '';
        car.brand = '';
        car.model = '';
        car.color = '';
      }

      switch (car.company) {
        case 'CLARA':
          this.data = new dataPeugeot();
          break;
        case 'CLARO':
          this.data = new dataOpel();
          break;
        default:
          break;
      }
      //this.temp()

      var footer = {};
      footer.registration = car.registration;
      footer.vin = car.vin;
      footer.brand = car.brand;
      footer.model = car.model;
      footer.color = car.color;
      footer.real  = true;
      this.setState({footer:footer});
    }

    var lines = this.data.lines();
    var len = 0;
    for(var i=0;i<lines.length;i++) {
      if( lines[i].type == 'query' ) {
        len++;
      }
    }

    this.setState({lines:lines,queryLength:len},()=>{
      document.querySelector('#form').removeEventListener("mouseenter", this.FormMouseEnter, false);
      document.querySelector('#form').addEventListener("mouseenter", this.FormMouseEnter, false);
    });

    if( StrDef(window.LayoutChangeTitle) ) {
      setTimeout(()=>{    // tout pourri : à voir plus tard.....
        window.LayoutChangeTitle( <div className="layoutBarTitle">{i18n.t('Form.layoutBarTitle')}</div>,
                                  <img src={car.company=='CLARO'?logoOpel:logoPeugeot} style={{padding:5,width:44,height:44,objectFit:'contain'}}/>);
      },500);
    }
  }

  CompanyChoose(company) {
    var car = {};
    car.company = company;
    car.registration = this.props.match.params.vin.length<17?this.props.match.params.vin:'';
    car.vin = this.props.match.params.vin.length==17?this.props.match.params.vin:'';
    car.brand = '';
    car.model = '';


    if( !StrDef(window.synchro.BDD[car.vin.length==17?car.vin:car.registration]) ) {
      window.synchro.BDD[car.vin.length==17?car.vin:car.registration] = car;
    } else {
      window.synchro.BDD[car.vin.length==17?car.vin:car.registration].company = company;
    }

    this.setState({chooseStamp:false,company:car.company});
    this.Load();
  }

  //==================================== query
  Check(id,checked) {
    var answer = 0, query = 0;
    var tab = Clone(this.state.lines);
    for(var i=0;i<tab.length;i++) {
      if( tab[i].id == id ) {
        if( parseInt(tab[i].checked) == 1 && checked == 1 ) tab[i].checked = 0;
        else if( parseInt(tab[i].checked) == 2 && checked == 2 ) tab[i].checked = 0;
        else {
          tab[i].checked = checked;
        }
      }
      if( tab[i].type == 'query' ) {
        query++;
        answer += (tab[i].checked==1||tab[i].checked==2)?1:0;
      }
    }
    var footer = Clone(this.state.footer);
    footer.finished = answer==query;

    //console.log( tab );

    this.setState({lines:tab,selected:id,footer:footer});
  }

  //==================================== footer
  ChangeInfo(data) {
    this.setState({footer:data})
  }

  //==================================== keyboard
  FormMouseEnter() {
    this.setState({selected:-1});
  }

  SelectedNext(checked,direction) {
    var tmp = this.state.selected;
    if( StrDef(checked) ) {
      var tab = Clone(this.state.lines);
      for(var i=0;i<tab.length;i++) {
        if( tab[i].id == tmp ) {
          if( [3,4,5].indexOf(tab[i].checked) != -1 ) {
            checked = -parseInt(tab[i].checked);
          }
        }
      }
      this.Check(tmp,parseInt(checked));
    }
    switch (direction) {
      case 'down':
        tmp++;
        if( tmp > this.state.queryLength-1 ) tmp = this.state.queryLength-1;
        break;
      case 'up' :
        tmp--;
        if( tmp < 0 ) tmp = 0;
        break;
      case 'left' :
      case 'right' :
        tmp = this.data.getNextCol(tmp,direction);
        break;
    }
    this.setState({selected:tmp});
  }

  KeyboardHookModal(e) {
    if( this.state.modal ) {
      switch (e.code) {
        case 'ArrowRight': this.ModalAVRClick('ar'); break;
        case 'ArrowLeft': this.ModalAVRClick('av'); break;
        case 'Enter': this.ModalAVRClick('ok'); break;
        default: console.log( e.code );
      }
    }
  }

  KeyboardHook(key,e) {
    switch (key) {
      case 'space':
        this.SelectedNext('1','down');
        break;
      case 'up':
      case 'right':
      case 'down':
      case 'left':
        this.SelectedNext(null,key);
        break;
      case 'enter':
        this.SelectedNext('2','down');
        break;
      default:
    }
  }

  Menu() {
    this.setState({displayMenu:!this.state.displayMenu});
  }

  Sign(callback) {
    /*if (!StrDef(this.state.footer.km)) {
      window.notify('Veuillez renseigner le kilométrage','danger');
      return
    } else if (!StrDef(this.state.footer.registration)) {
      window.notify('Veuillez renseigner l\'immatriculation','danger');
      return
    }*/
    //setTimeout(()=>{ window.routerHistory.push('/home') },3000);
    this.data.generatePdf(this.state.lines,this.state.footer,(retour)=>{
      console.log("retourretourretour",retour);
      if (!StrDef(retour.error)) {
        setTimeout(()=>{ window.routerHistory.push('/home') },3000);
      } else {
        callback (retour)
        //return retour;
      }
    });

  }

  NightMode() {
    var mode = sessionStorage.getItem('nightMode');
    if( mode === null ) mode = '0';
    if( mode == '0' ) {
      sessionStorage.setItem('nightMode','1');
    }
    else {
      sessionStorage.setItem('nightMode','0');
    }
    document.location.reload();
    this.Menu();
  }

  ViewQC() {
    //console.log("state CQ",this.state.existingCQ);
    window.open(this.state.existingCQ);
  }

  CreateQC() {
    this.setState({checkQC:false});
    this.PostLoad();
  }

  ToggleModal(id) {
    var modalAV=false, modalAR=false, label='';
    if( StrDef(id) ) {
      var tab = Clone(this.state.lines);
      for(var i=0;i<tab.length;i++) {
        if( tab[i].id == id ) {
          if( tab[i].checked == -3 ) { modalAV=true; modalAR=false; }
          if( tab[i].checked == -4 ) { modalAV=false; modalAR=true; }
          if( tab[i].checked == -5 ) { modalAV=true; modalAR=true; }
          label = tab[i].label;
        }
      }
    }
    this.setState({modalLabel:label,modalId:id,modalAV:modalAV,modalAR:modalAR,modal:!this.state.modal});
  }

  ModalAVRClick(what) {
    if( what == 'av' ) {
      this.setState({modalAV:!this.state.modalAV});
    }
    if( what == 'ar' ) {
      this.setState({modalAR:!this.state.modalAR});
    }
    if( what == 'ok' ) {
      var checked = 1;
      if( this.state.modalAV && !this.state.modalAR ) checked = 3;
      if( !this.state.modalAV && this.state.modalAR ) checked = 4;
      if( this.state.modalAV && this.state.modalAR ) checked = 5;
      this.Check(this.state.modalId,checked);
      this.ToggleModal();
    }
  }

  CheckAll(id,category) {
    let lines = JSON.parse(JSON.stringify(this.state.lines))
    let index = lines.findIndex(oneLine=>oneLine.id == id)
    lines[index].checked = (!StrDef(lines[index].checked) || lines[index].checked == 0 )? 1:0
    for (var i = 0; i < lines.filter(oneLine=>oneLine.category == category).length; i++) {
      lines.filter(oneLine=>oneLine.category == category)[i].checked = lines[index].checked;
    }
    this.setState({lines:lines})
  }

  render() {
    var style = {height:'100vh',overflow:'auto'};
    var mode = sessionStorage.getItem('nightMode');
    if( mode == '1' ) {
      style = {height:'100vh',overflow:'auto',color:'white',backgroundColor:'#222'};
    }

    var checkIcon =
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-20 40 512 512" style={{width:'30px',height:'30px'}}>
        <g fill={'#000'}>
          <path d=" M 453.886 138.696 L 421.194 105.074 C 419.893 103.588 417.85 102.845 415.993 102.845 C 413.949 102.845 412.092 103.588 410.791 105.074 L 184.171 333.367 L 101.696 250.892 C 100.21 249.406 98.352 248.663 96.495 248.663 C 94.637 248.663 92.779 249.406 91.293 250.892 L 58.229 283.956 C 55.257 286.928 55.257 291.572 58.229 294.544 L 162.252 398.567 C 168.939 405.254 177.112 409.155 183.985 409.155 C 193.83 409.155 202.375 401.91 205.532 398.938 L 205.718 398.938 L 454.072 149.284 C 456.673 146.126 456.673 141.482 453.886 138.696 L 453.886 138.696 Z "/>
        </g>
      </svg>;

//console.log("this.state.lines",this.state.lines);
    return (
      <>
        <KeyboardEventHandler
          handleKeys={['a', 'b', 'c','enter','space','left','up','right','down']}
          onKeyEvent={this.KeyboardHook} />

        <div className="content" onMouseMove={/*this.showCoords.bind(this)*/null} style={style}>
        <div style={{display:'none'}}>

          <canvas id="canvas" width={0} height={0} style={{backgroundColor:'red'}}></canvas>
        </div>

          { this.state.chooseStamp &&
            <Container className="loginWindow" fluid={true} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <Row>
                <Col md="3"></Col>
                <Col md="6" className="text-center">
                  <Card style={{padding:20}}>
                    <CardHeader>
                      <Icon name='info' size={90} style={{marginBottom:30}}/>
                      <p className="layoutBarTitle"><b>Veuillez choisir le type de Contrôle Qualité</b></p>
                      <div className="underscore"></div>
                      <br/>
                    </CardHeader>
                    <CardBody>
                      <Row style={{marginTop:30,marginBottom:30}}>
                        <Col md="6" className="text-center">
                          <div onClick={this.CompanyChoose.bind(this,'CLARA')} style={{height:'100%',cursor:'pointer',backgroundColor:'black',borderRadius:15}}>
                            <img src={logoClara} className='carCompanySelect'/>
                          </div>
                        </Col>
                        <Col md="6" className="text-center">
                          <div onClick={this.CompanyChoose.bind(this,'CLARO')} style={{height:'100%',cursor:'pointer',backgroundColor:'black',borderRadius:15}}>
                            <img src={logoClaro} className='carCompanySelect'/>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3"></Col>
              </Row>

            </Container>
          }

          { this.state.checkQC &&
            <Container className="loginWindow" fluid={true} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <Row>

                <Col md="12" className="text-center">
                  <Card style={{padding:20}}>
                    <CardHeader>
                      <Icon name='info' size={90} style={{marginBottom:30}}/>
                      <p className="layoutBarTitle"><b>Un Contrôle Qualité existe déjà</b></p>
                      <div className="underscore"></div>
                      <p className="layoutBarTitle" style={{color:'black',marginTop:30}}><b>Que désirez-vous faire ?</b></p>
                      <br/>
                    </CardHeader>
                    <CardBody>

                      <Button label={<div><div>Visualiser le dernier contrôle du</div>{DateDisplay(this.state.existingCQDate)}</div>} icon="view" onClick={this.ViewQC.bind(this)} style={{width:400}}/>

                      <br/><br/>

                      <Button label={'Effectuer un nouveau contrôle'} icon="create" onClick={this.CreateQC.bind(this)} style={{width:400}}/>

                    </CardBody>
                  </Card>
                </Col>

              </Row>

            </Container>
          }

          { StrDef(this.data)  &&
            <>
              <div id='form' style={this.data.getColContainerStyle()}>
                { this.data.cols().map((data,i)=>{
                    var begin = data[0];
                    var end = data[1];
                    var res = [];
                    return(
                      <div key={'cc'+i} style={this.data.getColStyle(i)}>
                        <div key={'col'+i} style={this.data.getColStyle(i)}>
                        { this.state.lines.map((line,l)=>{

                            if( l >= begin && l<=end ) {
                              if( line.id == this.state.selected ) line.selected = true;
                              else line.selected = false;
                              if (this.state.company == 'CLARA' && ((line.type == 'subtitle' && StrDef(line.category)) || (line.type == 'title' && StrDef(line.category) && (line.category == 'dynamique' || line.category == 'moteur')))) {
                                return (
                                  <div key={"line"+line.id} className={'checkLine'+( line.selected?' '+'selected':'')}>
                                    <div className="checkLineLabel" onClick={this.CheckAll.bind(this,line.id,line.category)} style={{marginLeft:-18}}>
                                      <label htmlFor={"query1"+line.id} style={{fontFamily:'Condense',margin:0,width:20,cursor:'pointer'}}>{line.num}</label>
                                      <label htmlFor={"query1"+line.id} style={{margin:0,cursor:'pointer',width:'100%',textTransform:'capitalize',fontWeight:'bold'}}>{line.label}</label>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'row',margin:0,padding:0,marginRight:60}}>
                                      <div style={{display:'flex',width:18,height:18,border:'1px solid rgb(31, 54, 123)',cursor:'pointer',backgroundColor:'transparent',alignItems:'center',justifyContent:'center'}} onClick={this.CheckAll.bind(this,line.id,line.category)}>
                                        <div style={{display:'flex',width:14,height:14,border:'1px solid rgb(31, 54, 123)',cursor:'pointer',backgroundColor:'#ffea66',alignItems:'center',justifyContent:'center'}} onClick={this.CheckAll.bind(this,line.id,line.category)}>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              } else if (this.state.company == 'CLARO' && line.type == 'title'  && StrDef(line.category)) {
                                return (
                                  <div key={"line"+line.id} className={'checkLine'+( line.selected?' '+'selected':'')}>
                                    <div className="checkLineLabel" onClick={this.CheckAll.bind(this,line.id,line.category)} style={{marginLeft:-18}}>
                                      <label htmlFor={"query"+line.id} style={{fontFamily:'Condense',margin:0,width:20,cursor:'pointer'}}>{line.num}</label>
                                      <label htmlFor={"query"+line.id} style={{margin:0,cursor:'pointer',width:'100%',textTransform:'capitalize',fontWeight:'bold'}}>{line.label}</label>
                                    </div>
                                    <div style={{display:'flex',marginRight:60}}>
                                      <div style={{display:'flex',width:18,height:18,border:'1px solid rgb(31, 54, 123)',cursor:'pointer',backgroundColor:'transparent',alignItems:'center',justifyContent:'center'}} onClick={this.CheckAll.bind(this,line.id,line.category)}>
                                        <div style={{display:'flex',width:14,height:14,border:'1px solid rgb(31, 54, 123)',cursor:'pointer',backgroundColor:'#ffea66',alignItems:'center',justifyContent:'center'}} onClick={this.CheckAll.bind(this,line.id,line.category)}>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )


                              } else if (line.type == 'query') {
                                return this.data.renderLine(line,this.Check,this.ToggleModal);
                              } else if (line.type == 'title') {
                                return (
                                  <div key={"line"+line.id} style={{}}>
                                    <b>{line.label}</b>
                                  </div>
                                )

                              } else if (line.type == 'subtitle') {
                                return (
                                  <div key={"line"+line.id} style={{}}>
                                    {line.label}
                                  </div>
                                )

                              }

                            }
                          })
                        }
                        </div>
                        {this.data.getColSeparator(i)}
                      </div>
                    );
                  })
                }
              </div>
              {this.data.renderFooter(this.state.footer,this.ChangeInfo,this.Sign)}
              {this.data.renderCanvas()}
            </>
          }
        </div>

        <Modal
          keyboard={false}
          onOpened={()=>window.addEventListener('keydown',this.KeyboardHookModal,false)}
          onClosed={()=>{window.removeEventListener('keydown',this.KeyboardHookModal,false);this.SelectedNext(null,'down')}}
          isOpen={this.state.modal}
          toggle={this.ToggleModal}
          backdrop='static'>
          <Row>
            <Col md="12" className="text-center" style={{padding:40}}>
              <div className="textOnWhite" style={{marginTop:10,marginBottom:50}}>
                {this.state.modalLabel}
              </div>

              <div className="textOnWhite" style={{fontSize:'1em',textAlign:'left',marginTop:10,marginBottom:10}}>
                Remplacement :
              </div>
              <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',cursor:'pointer',marginBottom:50}}>

                <div style={{display:'flex',flex:1,flexDirection:'column',cursor:'pointer'}} onClick={this.ModalAVRClick.bind(this,'av')}>
                  <div style={{paddingLeft:13,display:'flex',flex:1,justifyContent:'flex-start',alignItems:'center',flexDirection:'row',cursor:'pointer'}}>
                    <div style={{width:34,height:34,margin:0,padding:0,border:'solid 2px #1f367b'}}>
                      { this.state.modalAV &&
                        checkIcon
                      }
                    </div>
                    <div style={{display:'flex',alignItems:'center',flex:1,marginLeft:20,color:'#1f367b',fontSize:'1.4em'}}>AVANT</div>
                  </div>
                  <div style={{display:'flex',alignItems:'center'}}>
                    <img src={keyLeft} style={{width:60}}/>Flèche GAUCHE
                  </div>
                </div>

                <div style={{display:'flex',flex:1,flexDirection:'column',cursor:'pointer'}} onClick={this.ModalAVRClick.bind(this,'ar')}>
                  <div style={{paddingRight:13,display:'flex',flex:1,justifyContent:'flex-end',alignItems:'center',flexDirection:'row',cursor:'pointer'}}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',flex:1,marginRight:20,color:'#1f367b',fontSize:'1.4em'}}>ARRIERE</div>
                    <div style={{width:34,height:34,margin:0,padding:0,border:'solid 2px #1f367b'}}>
                      { this.state.modalAR &&
                        checkIcon
                      }
                    </div>
                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                    Flèche DROITE<img src={keyLeft} style={{width:60,transform:'scaleX(-1)'}}/>
                  </div>
                </div>

              </div>

              <Button label={i18n.t('all.save')} onClick={this.ModalAVRClick.bind(this,'ok')}/>
              <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                Bouton ENTREE<img src={keyEnter} style={{width:80}}/>
              </div>

            </Col>
          </Row>
        </Modal>

      </>
    );
  }
}

export default Form;
