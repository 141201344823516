import React from "react";

import { Switch, Redirect } from "react-router-dom";

import AuthRoute from "components/AuthRoute/AuthRoute.jsx";
import Icon from '../../components/Icons/Icon.jsx';
import {StrDef,Clone} from '../../assets/lib/utils';
import NotificationAlert from "../../components/Notification/Notification.jsx";

import routes from "routes.js";

class Certif extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
    };

    window.notify = this.notify.bind(this);
  }

  notify(msg,type) {
    this.refs.notificationAlert.notificationAlert({ message: msg, type: type });
  }

  componentDidMount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   document.documentElement.className += " perfect-scrollbar-on";
    //   document.documentElement.classList.remove("perfect-scrollbar-off");
    //   ps = new PerfectScrollbar(this.refs.mainPanel);
    // }
    this._mounted = true;
    window.LayoutChangeTitle = this.ChangeTitle.bind(this);
  }
  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    //   document.documentElement.className += " perfect-scrollbar-off";
    //   document.documentElement.classList.remove("perfect-scrollbar-on");
    // }
    this._mounted = false;
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      if( StrDef(this.refs.mainPanel) )
        this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      return (
        <AuthRoute
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };

  Home() {
    var event = new CustomEvent('logout', {detail:''});
    window.dispatchEvent(event);
  }

  ChangeTitle(center,right) {
    if( this._mounted ) {
      if( StrDef(center) ) {
        this.setState({titleCenter:center});
      }
      if( StrDef(right) ) {
        this.setState({titleRight:right});
      }
    }
  }

  render() {
    //var gender = sessionStorage.getItem('gender');

    return (
      <div className="wrapper">
        <NotificationAlert ref="notificationAlert"/>
        <div style={{wdith:'100vw',height:32,backgroundColor:'#FFF',display:'flex',alignItems:'center',flexDirection:'row'}}>
          <Icon name="home" size={30} style={{marginLeft:10}} onClick={this.Home.bind(this)}/>
          <div style={{height:32,backgroundColor:'#FFF',display:'flex',flex:1,alignItems:'center',flexDirection:'row'}}>
            <div style={{display:'flex',flex:'1',justifyContent:'center',alignItems:'center'}}>
            { StrDef(this.state.titleCenter) &&
              this.state.titleCenter
            }
            </div>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            { StrDef(this.state.titleRight) &&
              this.state.titleRight
            }
            </div>
          </div>
        </div>
        <Switch>
          {this.getRoutes(routes)}
          <Redirect to={'/home'}/>
        </Switch>
      </div>
    );
  }
}

export default Certif;
